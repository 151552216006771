.backToTop {
  .aaaem-back-to-top-button {
    background-image: url('../../assets/images/back-to-top-icon.png'); // image in backToTop accepts only svg. But SVG is not available for this site
    background-repeat: no-repeat;
    background-size: 28px 23px;
    background-position: top center;
    text-decoration: none;
    color: var(--emu-semantic-colors-primary-pink-100);
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    font-family: var(--emu-semantic-font-families-mono);
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s linear;
    max-width: 32px;

    @include mq('medium') {
      max-width: 70px;
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: 16px;
      background-size: 47px 40px;
      right: 20px;
      transform: translateY(-8px);
    }

    &.show {
      opacity: 1;
      pointer-events: initial;
    }

    span {
      margin-top: 27px;

      @include mq('medium') {
        margin-top: 47px;
      }
    }
  }
}
