#syringe-design-section {
  .main-title {
    width: 80%;
    margin: auto;

    @include mq('medium') {
      width: 100%;
    }

    h2 {
      @include mq('medium') {
        font-size: 40px;
      }

      @include mq('x-large') {
        font-size: 48px;
      }
    }

    br {
      display: none;

      @include mq('medium') {
        display: block;
      }
    }

    i {
      font-style: normal;
      vertical-align: super;
      font-size: 32%;
      display: inline-block;
      top: -5px;
      left: 2px;
      line-height: 0;
      position: relative;

      @include mq('medium') {
        top: -3px;
        left: 2px;
      }

      @include mq('medium') {
        top: -3px;
        left: 2px;
      }

      @include mq('tablet') {
        top: -10px;
        left: 5px;
      }
    }
  }

  .sub-title {
    margin-bottom: 10px;

    &--key-areas {
      br {
        display: none;

        @include mq('medium') {
          display: block;
        }
      }
    }

    &--color-code {
      h3 {
        margin-bottom: 15px;
        line-height: 1.3;

        @include mq('medium') {
          margin-bottom: 55px;
          font-size: 30px;
          line-height: 1.6;
        }
      }
    }
  }

  .disclaimer {
    p {
      padding-left: 30px;

      @include mq('medium') {
        padding-left: 60px;
      }

      @include mq('tablet') {
        padding-left: var(--emu-common-spacing-none);
      }

      sup {
        font-size: 60%;
        top: -4px;
        vertical-align: middle;
      }
    }

    br {
      @include mq('small') {
        display: none;
      }
    }

    &--syringe-design-rating {
      p {
        margin-left: 20px;
        padding: var(--emu-common-spacing-none);
        margin-top: 10px;

        @include mq('medium') {
          margin-left: var(--emu-common-spacing-none);
        }

        sup {
          font-size: 100%;
          display: inline-block;
          vertical-align: middle;
          top: 1px;
          left: 1px;

          @include mq('medium') {
            top: -1px;
          }
        }

        .emphasis {
          sup {
            font-size: 60%;
            display: inline-block;
            vertical-align: middle;
            top: -1px;
            left: 0;
          }
        }
      }
    }
  }

  .offset-container {
    &--syringe-design-bottom {
      padding-top: 30px;
      padding-bottom: 30px;

      @include mq('medium') {
        padding-top: 90px;
        padding-bottom: 50px;
      }
    }
  }

  .skewed-container {
    &__wrapper {
      &--syringe-scale {
        margin-top: 25px;

        @include mq('medium') {
          margin-top: 120px;
          max-width: 100%;
        }

        .skewed-container {
          &__main {
            @include mq('medium') {
              max-width: 86%;
            }

            @include mq('tablet') {
              margin-left: 50px;
            }

            @include mq('x-large') {
              margin-left: 35px;
            }

            @include mq('x-large') {
              max-width: 890px; // value from live site
            }

            @include mq('x-large-plus') {
              max-width: 950px; // value from live site
              margin-right: initial;
            }

            &-inner {
              position: relative;
              display: flex;
              justify-content: end;

              @include mq('medium') {
                justify-content: flex-start;
                transform: skew(19deg);
              }

              @include mq('tablet') {
                justify-content: center;
                transform: skew(24deg);
              }

              @include mq('x-large-plus') {
                justify-content: initial;
              }

              > .container {
                @include mq('medium') {
                  width: 100%;
                  max-width: 1000px;
                }
              }
            }
          }
        }
      }

      &--syringe-rating {
        max-width: 100%;
        .skewed-container {
          &__main {
            padding-top: 15px;
            padding-right: 20px;
            padding-bottom: 15px;
            padding-left: 35px;
            margin: auto;

            @include mq('medium') {
              max-width: 90%;
              padding-top: 18px;
              padding-left: 80px;
              padding-bottom: 18px;
              margin-right: auto;
              transform: skew(-24deg);
            }

            @include mq('tablet') {
              max-width: 82%;
              margin-left: 40px;
            }

            @include mq('x-large') {
              max-width: 92%;
              margin-left: auto;
              padding-left: 130px; // value from live site
            }

            @include mq('x-large-plus') {
              max-width: 950px; // value from live site
            }

            &-inner {
              @include mq('medium') {
                transform: skew(24deg);
              }
            }
          }
        }
      }
    }
  }

  .syringe-design {
    &__main-container {
      margin-bottom: 30px;
      margin-top: 30px;

      @include mq('medium') {
        margin-bottom: 130px;
        margin-top: 130px;
      }
    }

    &__sub-section {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 80%;
      margin-top: 15px;
      margin-right: auto;
      margin-bottom: 15px;
      margin-left: auto;

      @include mq('medium') {
        margin-top: 22px;
        margin-bottom: 45px;
        max-width: 100%;
      }

      .image {
        .cmp-image {
          width: 65px; // value from live site
          margin-left: 15px;

          @include mq('medium') {
            width: 108px; // value from live site
            max-width: 100%;
            margin-left: 40px;
          }
        }
      }
    }

    &__collection-text {
      max-width: 85%;
      margin-top: 20px;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;

      @include mq('medium') {
        margin-top: 60px;
        margin-bottom: 60px;
      }

      p {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        line-height: 1.4;
        font-style: 14px;
        color: var(--emu-semantic-colors-secondary-dark-grey-600);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
        }
      }

      b {
        font-family: var(--emu-semantic-font-families-poppins-bold);
        color: var(--emu-semantic-colors-primary-purple-500);

        sup {
          font-size: 100%;
          line-height: 0;
          top: -1px;
          left: 1px;
          vertical-align: middle;

          @include mq('medium') {
            left: 0;
          }
        }
      }

      .emphasis {
        @include mq('x-large') {
          display: block;
        }

        b {
          color: var(--emu-semantic-colors-secondary-dark-grey-600);
        }

        sup {
          font-size: 50%;
          top: -1px;
          vertical-align: middle;
        }
      }
    }

    &__scale {
      &-image {
        position: absolute;
        max-width: 110px; // value from live site
        left: 5px;
        bottom: 0;
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('small') {
          max-width: 150px; // value from live site
          left: 10px;
        }

        @include mq('medium') {
          left: -40px;
        }

        @include mq('x-large') {
          max-width: 195px; // value from live site
        }
      }

      &-text-container {
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 5px;
        padding-bottom: 20px;

        @include mq('medium') {
          padding-bottom: 50px;
          display: flex;
          margin-left: 14%;
          align-items: flex-end;
        }

        @include mq('tablet') {
          padding-right: 24px;
        }

        @include mq('large') {
          padding-right: 30px;
        }

        @include mq('x-large') {
          margin-left: 16%;
          padding-left: 30px;
          display: inline-flex;
        }

        > .text {
          @include mq('medium') {
            flex-basis: 63%;
          }

          @include mq('x-large') {
            flex-basis: 71%;
          }
        }
      }

      &-text {
        display: flex;
        margin-left: var(--emu-common-spacing-none);
        margin-bottom: 10px;
        align-items: flex-end;
        justify-content: flex-end;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          justify-content: flex-start;
        }

        h3 {
          color: var(--emu-semantic-colors-primary-purple-500);
          font-family: var(--emu-semantic-font-families-heading);
          line-height: 1.25;
          font-size: var(--emu-common-font-sizes-narrow-medium);
          text-align: center;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('medium') {
            text-align: right;
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
          }

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }

          @include mq('x-large') {
            font-size: 24px;
          }
        }

        h4 {
          font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          line-height: 1;
          margin-top: var(--emu-common-spacing-none);
          margin-right: 12px;
          margin-bottom: var(--emu-common-spacing-none);
          margin-left: 12px;
          color: var(--emu-semantic-colors-primary-purple-500);
          font-family: var(--emu-semantic-font-families-heading);
          flex-basis: 15%;

          @include mq('medium') {
            font-size: 55px;
          }

          @include mq('tablet') {
            font-size: 90px;
          }

          @include mq('x-large') {
            margin-right: 20px;
            margin-left: 20px;
          }

          @include mq('xx-large') {
            margin-right: 27px;
          }
        }

        .emphasis {
          display: block;

          @include mq('medium') {
            display: contents;
          }

          @include mq('x-large-plus') {
            display: block;
            position: relative;
          }
        }
      }

      &-stars {
        ul {
          list-style: none;
          padding-left: var(--emu-common-spacing-none);
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: var(--emu-common-spacing-xs);
          margin-top: -5px;
          margin-bottom: -5px;

          @include mq('medium') {
            margin-bottom: -4px;
            gap: 4.5px; // decimal needed to match design
          }
        }

        .animate-star:not(:last-child) {
          color: var(--emu-semantic-colors-primary-pink-100);
        }

        li {
          position: relative;
          display: inline-block;
          margin: var(--emu-common-spacing-none);
          color: var(--emu-common-colors-white);
          font-size: 17px;
          line-height: normal;

          @keyframes stretch-bounce {
            0% {
              transform: scale(1);
            }
            25% {
              transform: scale(1.5);
            }
            50% {
              transform: scale(0.9);
            }
            75% {
              transform: scale(1.2);
            }
            100% {
              transform: scale(1);
            }
          }

          // 10 stars with a delayed animation
          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              $time: ' ' + (1 + (0.2 * $i)) + 's';
              transition: #{color 0s ease} + $time;

              &.animate-star {
                animation: #{stretch-bounce 0.5s ease-in-out} + $time;
              }
            }
          }

          &::before {
            font-family: fontAwesome;
            display: inline-block;
            content: '\f005';
            position: relative;
            z-index: var(--emu-common-other-z-index-layer);
          }

          // last but one child needs half star
          &:nth-child(9) {
            &::before {
              content: '\f123';
            }

            &::after {
              position: absolute;
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              left: 3px;
              border-radius: 50%;
              top: 0;
              margin: auto;
              bottom: 0;
              background-color: var(--emu-common-colors-white);
            }
          }
        }

        // hiding the text present in li elements
        .emphasis {
          display: none;
        }

        &-section {
          margin-left: 30px;

          @include mq('medium') {
            margin-left: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-xxs);
          }

          @include mq('x-large') {
            margin-top: var(--emu-common-spacing-none);
            width: max-content;
          }

          h5 {
            font-size: var(--emu-common-font-sizes-narrow-medium);
            font-family: var(--emu-semantic-font-families-heading);
            color: var(--emu-semantic-colors-primary-purple-500);
            line-height: 1.25;
            margin-top: 7px;
            margin-bottom: var(--emu-common-spacing-none);
            font-weight: var(--emu-semantic-font-weight-500);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-narrow-xl);
              text-align: left;
            }

            @include mq('tablet') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
            }

            @include mq('x-large') {
              font-size: 24px;
              margin-top: 5px;
            }

            sup {
              font-size: 48%;
              display: inline-block;
              vertical-align: super;
              top: -5px;

              @include mq('medium') {
                top: 0;
              }
            }
          }
        }
      }
    }

    &__key-areas {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;

      @include mq('medium') {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      &-item-grid {
        margin-top: 20px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;

        @include mq('medium') {
          margin-top: 50px;
          flex-direction: initial;
          align-items: initial;
        }

        > .container {
          flex: 0 0 33%;
          margin-bottom: 30px;

          @include mq('medium') {
            flex: 0 0 auto;
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &-item {
        .image {
          max-width: 165px; // value from live site
          margin-left: auto;
          margin-right: auto;
        }

        .emphasis {
          display: block;
        }

        p {
          color: var(--emu-common-colors-black);
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: 1.4;
          margin-top: 25px;
          margin-bottom: var(--emu-common-spacing-none);
          text-align: center;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
          }

          sup {
            vertical-align: super;
            display: inline-block;
            top: 0;

            @include mq('medium') {
              top: 1px;
            }
          }
        }
      }
    }

    &__rating {
      &-content-wrapper {
        display: flex;
        align-items: center;
        gap: 15px;

        @include mq('medium') {
          gap: 25px;
        }
      }

      &-title {
        margin-top: 5px;
        width: 110px; // value from live site
        height: 110px; // value from live site
        position: relative;
        padding: 5px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq('medium') {
          width: 120px; // value from live site
          height: 120px; // value from live site
        }

        @include mq('x-large') {
          width: 145px; // value from live site
          height: 145px; // value from live site
        }

        &::before {
          content: '';
          position: absolute;
          width: 92%;
          height: 92%;
          background-color: #f2e8e3; // no var, used only once
          border-radius: 50%;
        }

        h3 {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          font-family: var(--emu-semantic-font-families-heading);
          color: var(--emu-common-colors-white);
          width: 85px;
          height: 85px;
          font-size: 35px;
          line-height: 1.1;
          background-color: var(--emu-semantic-colors-primary-purple-500);

          @include mq('medium') {
            width: 95px;
            height: 95px;
            font-size: 42px;
            background-image: linear-gradient(
              to right,
              var(--emu-semantic-colors-primary-purple-500),
              var(--emu-semantic-colors-primary-pink-100)
            );
          }

          @include mq('x-large') {
            font-size: 48px;
            width: 110px; // value from live site
            height: 110px; // value from live site
          }

          sup {
            font-size: 50%;
            vertical-align: super;
            top: 0;
          }
        }
      }

      &-content {
        h5 {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 1.25;
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-semantic-font-weight-500);
          margin-top: 10px;
          margin-bottom: 10px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }

          @include mq('tablet') {
            font-size: 22px;
          }

          @include mq('x-large') {
            font-size: 26px;
          }

          br {
            display: none;

            @include mq('medium') {
              display: block;
            }
          }

          sup {
            font-size: 48%;
            display: inline-block;
            vertical-align: super;
            top: 0;

            @include mq('medium') {
              top: -1px;
            }
          }
        }
      }
    }

    &__color-code {
      padding-top: 50px;
      padding-bottom: 20px;

      @include mq('medium') {
        padding-top: 85px;
        padding-left: 15px;
        padding-bottom: 40px;
        padding-right: 15px;
      }

      &-carousel {
        .aaaem-carousel {
          &__action {
            padding: var(--emu-common-spacing-none);
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);

            svg {
              width: 52px; // value needed, to match live site
              height: 52px; // value needed, to match live site
            }

            &-next {
              right: 2px;
            }

            &-prev {
              left: 2px;
            }
          }

          &__content {
            @include mq('medium') {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
            }
          }

          &__item {
            @include mq('medium') {
              margin-left: 25px;
              margin-right: 10px;
              margin-bottom: 40px;
              flex: 0 0 27%;
            }

            @include mq('x-large') {
              flex: 0 0 30%;
              margin-left: var(--emu-common-spacing-none);
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: 50px;
            }

            .emu-image {
              @include mq('medium') {
                display: flex;
                justify-content: center;
              }
            }

            img {
              @include mq('medium') {
                max-width: 250px; // value from live site
              }
            }
          }
        }
      }
    }
  }
}

// author styles
.syringe-design__scale-image {
  @include aem-editor-view {
    position: static !important; // making sure that the image is authorable
  }
}
