// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--root {
  margin-left: auto;
  margin-right: auto;
}

.container-large {
  max-width: 1015px; // value as per live site
}

.container-with-separator {
  border-bottom: var(--emu-common-border-width-thin) solid
    var(--emu-semantic-colors-secondary-dark-grey-100);
}

.container--extra-large {
  max-width: 1440px; // value from live site
  margin-left: auto;
  margin-right: auto;
}

// content related util classes. Used to center buttons mostly.
.content--centered {
  text-align: center;
}

.bg--purple-500 {
  background-color: var(--emu-semantic-colors-primary-purple-500);
}

.bg--grey-200 {
  background-color: var(--emu-semantic-colors-secondary-grey-200);
}

.bg--pink-100 {
  background-color: var(--emu-semantic-colors-primary-pink-100);
}

// text related sizes and colors
[data-component='text'],
[data-component='title'] {
  &[class*='text--color-'] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }

  &[class*='font-'] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      font-family: inherit;
    }
  }

  &.text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.text--color-purple-500 {
    color: var(--emu-semantic-colors-primary-purple-500);
  }

  &.text--color-pink-100 {
    color: var(--emu-semantic-colors-primary-pink-100);
  }

  &.text--color-dark-grey-500 {
    color: var(--emu-semantic-colors-secondary-dark-grey-500);
  }

  &.text--color--dark-grey-600 {
    color: var(--emu-semantic-colors-secondary-dark-grey-600);
  }

  &.text--color-green-100 {
    color: var(--emu-semantic-colors-primary-green-100);
  }

  // font families
  &.font-oswald-medium-italic {
    font-family: var(--emu-semantic-font-families-heading);
  }

  &.font-oswald-regular {
    font-family: var(--emu-semantic-font-families-mono);
  }

  &.font-poppins-regular {
    font-family: var(--emu-semantic-font-families-body);
  }

  &.font-poppins-Light {
    font-family: var(--emu-semantic-font-families-poppins-light);
  }

  &.font-poppins-semi-bold {
    font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  }

  &.font-poppins-bold {
    font-family: var(--emu-semantic-font-families-poppins-bold);
  }
}

.main-container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 25px;

  &.main-container--small {
    max-width: 90%;
  }

  @include mq('tablet') {
    &,
    &.main-container--small {
      margin-left: 220px; // value from live site
      max-width: 75%;
      padding-top: 70px;
      padding-bottom: 65px;
    }
  }

  // media query needed to match design
  @include mq('1192px') {
    &,
    &.main-container--small {
      padding-top: 75px;
    }
  }

  @include mq('x-large') {
    &,
    &.main-container--small {
      margin-left: 230px; // value from live site
    }
  }

  @include mq('x-large-plus') {
    &,
    &.main-container--small {
      max-width: 1015px; // value from live site
    }
  }
}

.offset-container {
  max-width: 100%;

  @include mq('tablet') {
    margin-left: 220px;
    max-width: 75%;
  }

  @include mq('x-large') {
    margin-left: 230px;
  }

  @include mq('x-large-plus') {
    max-width: 1015px; // value from live site
  }

  &--variant-x {
    @include mq('medium') {
      max-width: 85%;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('tablet') {
      margin-left: 220px;
    }

    @include mq('x-large') {
      margin-left: 230px;
      max-width: 75%;
    }

    @include mq('x-large-plus') {
      max-width: 1015px;
    }
  }
}

.hide {
  display: none;
}

.main-title[data-component='title'] {
  h1,
  h2,
  h3,
  h4,
  h5 {
    &.emu-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
      line-height: 1.2;
      font-family: var(--emu-semantic-font-families-heading);
      color: var(--emu-semantic-colors-primary-purple-500);
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: 12px;

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xxxl);
      }

      @include mq('tablet') {
        font-size: 48px;
      }
    }
  }

  br {
    // media query needed. From live site
    @include mq('small') {
      display: none;
    }
  }

  sup {
    font-size: 48%;
    vertical-align: super;
    display: inline-block;
    top: -2px;
    left: 1px;

    @include mq('medium') {
      left: 0;
    }
  }
}

.sub-title[data-component='title'],
.sub-title[data-component='text'] {
  h1,
  h2,
  h3,
  h4,
  h5 {
    &,
    &.emu-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xxl);
      line-height: 1.4;
      font-family: var(--emu-semantic-font-families-mono);
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: 10px;

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
      }

      @include mq('tablet') {
        font-size: 30px;
      }
    }
  }

  br {
    // media query needed. From live site
    @include mq('small') {
      display: none;
    }
  }

  sup {
    font-size: 48%;
    display: inline-block;
    vertical-align: super;
    top: -1px;
    left: 1px;

    @include mq('medium') {
      left: 0;
    }
  }
}

.hcpj-video {
  &.aaaem-embed .aaaem-embed__oembed:not(.no-ratio),
  .aaaem-embed__embeddable:not(.no-ratio) {
    padding-bottom: 52%;
  }
}

.cmp-text.hcpj-video-transcript {
  width: 93%;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  background-color: var(--emu-semantic-colors-secondary-grey-200);

  h3 {
    font-family: var(--emu-semantic-font-families-poppins-semi-bold);
    margin-bottom: 6px;
    color: var(--emu-semantic-colors-secondary-dark-grey-500);
    font-size: 15px;
    line-height: 1.1;
    margin-top: 10px;
  }

  p {
    margin-bottom: 10px;
    margin-top: var(--emu-common-spacing-none);
    font-size: 15px;
    line-height: 1.4;
    color: var(--emu-semantic-colors-secondary-dark-grey-200);
  }
}

.skewed-container {
  &__main {
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    background-image: linear-gradient(
      340deg,
      var(--emu-semantic-colors-secondary-grey-300),
      var(--emu-semantic-colors-secondary-grey-200)
    );

    @include mq('medium') {
      transform: skew(-19deg);
    }

    @include mq('tablet') {
      transform: skew(-24deg);
      max-width: 87%;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('x-large') {
      max-width: 90%;
    }

    @include mq('x-large-plus') {
      max-width: 950px; // value from live site
    }

    &-inner {
      @include mq('medium') {
        transform: skew(24deg);
      }
    }
  }

  &__wrapper {
    @include mq('medium') {
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;
    }

    @include mq('tablet') {
      max-width: 100%;
    }
  }
}

.disclaimer.cmp-text {
  p {
    font-family: var(--emu-semantic-font-families-poppins-light);
    font-size: 8px;
    line-height: 1.4;
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 3px;
    padding-left: 15px;

    @include mq('medium') {
      padding-left: var(--emu-common-spacing-none);
      font-size: var(--emu-common-font-sizes-narrow-medium);
    }

    b {
      font-family: var(--emu-semantic-font-families-poppins-semi-bold);
    }

    sup {
      font-size: 100%;
      top: 0;
      left: 0;
      vertical-align: middle;

      @include mq('medium') {
        top: 1px;
      }

      .emphasis {
        font-size: 60%;
        top: -2px;
        position: relative;

        @include mq('medium') {
          top: -4px;
        }
      }
    }
  }
}

// author styles
.mobile-view,
.tablet-desktop-view {
  @include aem-editor-view {
    display: initial !important; // authoring styles to make sure that the elements stay on the screen all the time
  }
}

.skewed-container__wrapper {
  @include aem-editor-view {
    img,
    .image {
      position: static !important; // authoring styles to make sure images are authorable
      display: initial !important; // authoring styles to make sure images are authorable
    }
  }
}
