#innovation-section {
  .main-title {
    h3.emu-title__text {
      color: var(--emu-common-colors-white);
      margin-bottom: 10px;

      @include mq('medium') {
        font-size: 48px;
        margin-bottom: var(--emu-common-spacing-xs);
      }
    }
  }

  .innovation-container {
    padding-top: 30px;
    position: relative;
    background-image: radial-gradient(
      var(--emu-semantic-colors-primary-purple-300),
      var(--emu-semantic-colors-primary-purple-500)
    );

    @include mq('medium') {
      padding-top: 80px;
      margin-top: 60px;
    }

    // shadow on the left side of the carousel
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 120px; // value from live site
      height: 100%;
      z-index: var(--emu-common-other-z-index-layer);
      background-image: linear-gradient(
        to right,
        var(--emu-semantic-colors-primary-purple-500),
        transparent
      );

      @include mq('medium') {
        width: 320px; // value from live site
      }

      @include mq('tablet') {
        width: 350px; // value from live site
      }
    }

    &__title-section {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 1.4;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 20px;

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
        }

        sup {
          display: inline-block;
          vertical-align: middle;
          font-size: 100%;
          top: -1px;
          left: 1px;

          @include mq('medium') {
            left: 0;
          }
        }

        .emphasis {
          display: block;

          sup {
            font-size: 50%;
            top: -1px;
            left: 0;
            vertical-align: middle;
          }

          @include mq('small') {
            display: inline;
          }
        }
      }
    }

    &__view-more {
      text-align: center;

      p {
        margin: var(--emu-common-spacing-none);
        display: inline-block;
        padding-top: 12px;
        padding-right: 35px;
        padding-bottom: 12px;
        padding-left: 35px;
        background-color: var(--emu-semantic-colors-primary-pink-100);
        color: var(--emu-common-colors-white);
        font-family: var(--emu-semantic-font-families-poppins-semi-bold);
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 1.4;
      }
    }

    &__carousel-section {
      h3 {
        color: var(--emu-common-colors-white);
        font-family: var(--emu-semantic-font-families-mono);
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: 1.4;
        margin-bottom: 10px;
        margin-top: var(--emu-common-spacing-none);

        sup {
          font-size: 48%;
          vertical-align: super;
          top: 0;
        }
      }

      .image {
        img {
          margin-left: auto;
          margin-right: auto;
        }
      }

      p {
        font-size: var(--emu-common-font-sizes-wide-medium);
        margin-top: 15px;
        margin-bottom: var(--emu-common-spacing-none);
        color: var(--emu-common-colors-white);
        line-height: 1.4;
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 100%;
        }

        b {
          font-family: var(--emu-semantic-font-families-poppins-bold);
          font-weight: var(--emu-semantic-font-weight-700);
        }

        .emphasis {
          @include mq('x-large-plus') {
            display: block;
          }

          b {
            font-family: var(--emu-semantic-font-families-body);
          }
        }
      }
    }

    .aaaem-carousel {
      &__action {
        position: static;

        svg {
          fill: var(--emu-common-colors-white);
          width: 23px; // value to match live site
          height: 23px; // value to match live site
          stroke: var(--emu-common-colors-white);
        }
      }

      &__actions {
        justify-content: center;
        align-items: center;
        gap: 90px; // value to match live site
        display: flex;
        position: absolute;
        left: 0;
        top: -45px;
        width: 100%;
      }

      &__content {
        position: relative;
        padding-bottom: 50px;
        padding-top: 60px;
        background-color: var(--emu-semantic-colors-primary-purple-500);
        user-select: none;

        // the horizontal pink band above the carousel
        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 30px;
          background: var(--emu-semantic-colors-primary-pink-100);
        }
      }

      &__item {
        position: relative;

        // dot before the carousel item
        &::before {
          content: '';
          position: absolute;
          top: -52px;
          left: 50%;
          transform: translateX(-50%);
          width: var(--emu-common-sizing-small);
          height: var(--emu-common-sizing-small);
          border-radius: 50%;
          background-color: var(--emu-common-colors-white);
        }

        // line between the dot and item header
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -30px;
          height: 20px; // value from live site
          border-left: var(--emu-common-border-width-medium) solid
            var(--emu-semantic-colors-primary-pink-100);
        }

        &-container {
          @include mq('large') {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }
  }
}
