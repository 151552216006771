#error-page {
  .error-page--404 {
    .error-text {
      h1 {
        margin: var(--emu-common-spacing-none);
        text-align: center;
        margin-bottom: 15px;
        color: var(--emu-semantic-colors-primary-purple-500);

        @include mq('medium') {
          text-align: left;
          font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          line-height: var(--emu-semantic-line-heights-wide-xxxl);
        }

        @include mq('tablet') {
          font-size: 48px;
          line-height: 57.6px; // decimals needed. Major difference when made whole
        }
      }

      p {
        margin: var(--emu-common-spacing-none);
        text-align: center;
        line-height: 19.6px; // decimals needed. Major difference when made whole
        margin-bottom: 10px;
        color: var(--emu-semantic-colors-secondary-dark-grey-600);

        @include mq('medium') {
          text-align: left;
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }

        @include mq('tablet') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }
      }
    }

    .error-cta {
      line-height: 19.6px; // decimals needed. Major difference when made whole
      text-decoration: none;
      display: flex;
      justify-content: center;
      padding-left: 9px;
      padding-top: var(--emu-common-spacing-xxs);

      @include mq('medium') {
        justify-content: flex-start;
        padding-left: 12px;
      }

      .chevron {
        transform: rotate(90deg) translate(-3px, 10px);
        display: inline-block;

        svg {
          stroke: var(--emu-semantic-colors-primary-pink-100);
          fill: var(--emu-semantic-colors-primary-pink-100);
          stroke-width: 0.5px; // decimals needed. Major difference when made whole
          display: block;
          width: 18px; // to match live
          font-family: var(--emu-semantic-font-families-poppins-semi-bold);

          @include mq('tablet') {
            stroke-width: 1px;
          }
        }
      }

      .cmp-button__text {
        color: var(--emu-semantic-colors-primary-pink-100);
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        font-family: var(--emu-semantic-font-families-poppins-semi-bold);
        transform: translateX(2px) translateY(-1px);
        display: block;

        @include mq('medium') {
          transform: translateX(2px) translateY(0);
        }

        @include mq('tablet') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-xl);
          transform: translateX(2px) translateY(-1px);
        }
      }
    }

    .js-toggle-on {
      .chevron {
        svg {
          transform: none;
        }
      }
    }
  }
}
