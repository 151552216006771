#home {
  position: relative;
  padding-top: 70px;
  margin-top: -70px; // to adjust spacings and text to derive output similar to live site

  @include mq('tablet') {
    padding-top: 108px;
    margin-top: -118px; // to adjust spacings and text to derive output similar to live site
  }

  // media query needed to match live design
  @include mq('x-large-plus') {
    padding-top: 120px;
    margin-top: -120px; // to adjust spacings and text to derive output similar to live site
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 100%;
  }

  // this class was added to aaaem-container and the util styles were being overridden by common. Re-initiating here to make sure that the styles get applied
  .tablet-desktop-view {
    display: none;

    @include mq('medium') {
      display: block;
    }
  }

  .home-section {
    &__banner-text {
      position: absolute;
      top: 40%;
      left: 8.5%;
      bottom: 0;
      transform: translateY(-30%);
      text-align: center;
      font-weight: var(--emu-semantic-font-weight-500);
      line-height: var(--emu-semantic-line-heights-narrow-large);

      @include mq('small') {
        top: 48%;
      }

      @include mq('medium') {
        top: 40%;
      }

      @include mq('tablet') {
        left: 20.5%;
        top: 45%;
        transform: translateY(-30%) translateY(-5px);
      }

      @include mq('x-large') {
        transform: translateY(-30%) translateY(1px);
      }

      p {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 1.07;
        font-weight: var(--emu-semantic-font-weight-500);
        font-family: var(--emu-semantic-font-families-mono);
        margin-bottom: 10px;
        margin-top: 10px;

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-large);
        }

        @include mq('tablet') {
          font-size: 28px;
        }

        @include mq('x-large') {
          font-size: 30px;
        }

        sup {
          top: -1px;
          left: 1px;
          font-size: 48%;
          vertical-align: super;

          @include mq('medium') {
            left: 0;
          }
        }
      }

      h1 {
        margin-top: var(--emu-common-spacing-none);
        font-size: 36px;
        line-height: 1.07;
        margin-bottom: 12px;

        @include mq('medium') {
          font-size: 60px;
        }

        @include mq('tablet') {
          font-size: 74px;
        }

        @include mq('x-large') {
          font-size: 90px;
        }
      }

      h3 {
        font-family: var(--emu-semantic-font-families-heading);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 10px;
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 1.1;

        @include mq('medium') {
          font-size: 22px;
        }

        @include mq('tablet') {
          font-size: 30px;
        }

        @include mq('x-large') {
          font-size: 35px;
        }
      }
    }

    &__tagline {
      background-image: linear-gradient(
        to right,
        var(--emu-semantic-colors-primary-purple-400) 5%,
        var(--emu-semantic-colors-primary-purple-200) 100%
      );
      padding-top: 15px;
      padding-right: 15px;
      padding-bottom: 14px;
      padding-left: 15px;

      @include mq('medium') {
        padding-top: 35px;
        padding-bottom: 22px;
        padding-left: 15px;
        padding-right: 15px;
        background-image: linear-gradient(
          to right,
          var(--emu-semantic-colors-primary-purple-500) 60%,
          var(--emu-semantic-colors-primary-purple-100) 100%
        );
      }

      @include mq('large') {
        padding-bottom: 23px;
      }

      &-inner {
        background-color: var(--emu-common-colors-transparent);
        margin-left: 10px;

        @include mq('medium') {
          margin-right: 10px;
          max-width: 800px; // value from live site
        }

        @include mq('tablet') {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-text {
        color: var(--emu-common-colors-white);
        line-height: 1.2;
        font-family: var(--emu-semantic-font-families-body);
        font-size: var(--emu-semantic-font-sizes-narrow-medium);

        @include mq('medium') {
          font-size: 21px;
          margin-bottom: 10px;
        }

        p {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);

          sup {
            font-size: 100%;
            vertical-align: middle;
            top: -1px;
            left: 1px;

            @include mq('medium') {
              left: 0;
            }
          }

          br {
            display: none;

            @include mq('medium') {
              display: block;
            }
          }
        }
      }
    }

    &__cta {
      border: var(--emu-common-border-width-none);
      padding: var(--emu-common-spacing-none);
      width: 37px; // value needed to match live site. Live site uses image. We use bg image. width of the image is given here
      height: 18px; // value needed to match live site. Live site uses image. We use bg image. width of the image is given here
      background-image: url('./assets/images/down-arrow.png');
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 15px;
      text-align: center;

      @include mq('medium') {
        display: inline-block;
        transform: translateY(6px);
      }

      &,
      &:active,
      &:focus,
      &:hover {
        background-color: var(--emu-common-colors-transparent);
        outline: none;
      }
    }
  }
}

// author styles
.banner-text {
  @include aem-editor-view {
    position: static !important; // making sure that the content is authorable
    transform: none !important; // making sure that the content is authorable
  }
}
