#error-page {
  .error-page--500 {
    max-width: 65%;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(235, 235, 235, 0.5); //used once to match live site
    margin-left: auto;
    margin-right: auto;

    @include mq('medium') {
      max-width: 100%;
    }

    .error-text {
      h1 {
        color: var(--emu-common-colors-black);
        font-family: var(--emu-semantic-font-families-body);
        font-size: var(--emu-common-sizing-medium);
        line-height: var(--emu-semantic-line-heights-wide-xxl);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 20px;
      }

      h2 {
        color: var(--emu-common-colors-black);
        font-family: var(--emu-semantic-font-families-body);
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        line-height: var(--emu-semantic-line-heights-wide-medium);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 10px;
      }

      p {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 10px;

        a {
          color: #337ab7; // No var. used only in one place across the site
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          text-decoration: none;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            line-height: var(--emu-semantic-line-heights-wide-large);
          }
        }
      }
    }
  }
}
