.hcpj-footer {
  &__bottom-wrapper {
    @include mq('tablet') {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;

      > .image {
        flex: 1;
      }

      > .container {
        flex: 2.3;
      }
    }

    > .container {
      // media query needed to match the design
      @include mq('1410px') {
        flex: 2;
      }
    }

    .aaaem-image {
      margin-right: var(--emu-common-spacing-small);

      @include mq('tablet') {
        margin-right: var(--emu-common-spacing-none);
      }

      img {
        width: 358px; // value from live site
        margin-left: auto;
        margin-right: auto;

        // media query needed to match the design
        @include mq('1295px') {
          max-width: max-content;
          margin-right: var(--emu-common-spacing-small);
        }
      }
    }
  }

  &__content {
    a,
    p {
      font-family: var(--emu-semantic-font-families-poppins-light);
    }

    ul {
      padding-left: var(--emu-common-spacing-none);
      margin-top: 12px;
      margin-bottom: var(--emu-common-spacing-none);
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      text-align: center;

      // media query needed to match the design
      @include mq('460px') {
        text-align: unset;
      }

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-narrow-xxl);
        line-height: 23px;
      }

      @include mq('tablet') {
        margin-bottom: var(--emu-common-spacing-none);
        white-space: nowrap;
      }

      li {
        list-style: none;
        display: inline-block;

        &:first-child {
          a {
            padding-left: var(--emu-common-spacing-none);
          }
        }

        &:last-child {
          a {
            padding-right: var(--emu-common-spacing-none);

            &::after {
              display: none;
            }
          }
        }

        a {
          color: var(--emu-common-colors-black);
          text-decoration: none;
          position: relative;
          padding-left: var(--emu-common-spacing-small);
          padding-right: var(--emu-common-spacing-small);
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            max-width: 40px;
          }

          @include mq('tablet') {
            padding-left: var(--emu-common-spacing-xs);
          }

          &::after {
            content: '';
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 0px;
            width: var(--emu-common-border-width-thin);
            height: 14px;
            margin: auto;
            background-color: var(--emu-common-colors-black);
          }

          &:focus {
            text-decoration: underline;
          }
        }
      }
    }

    .hcpj-footer__bottom-section {
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: 17px;
      color: var(--emu-common-colors-black);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-narrow-xxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxl);
      }

      p {
        margin-top: 25px;
        margin-bottom: 10px;

        @include mq('medium') {
          margin-top: 42px;
        }

        @include mq('tablet') {
          margin-top: 22px;
        }
      }
    }
  }

  &__top-container {
    .aaaem-text {
      font-size: 10px;
      line-height: 14px;
      color: var(--emu-common-colors-black);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: var(--emu-semantic-line-heights-wide-medium);
      }

      p {
        margin-top: 5px;
        margin-bottom: 10px;

        @include mq('medium') {
          margin-top: 22px;
        }

        sup {
          font-size: 100%;
          top: -1px;
          left: 1px;
        }

        a {
          color: inherit;
          text-decoration: none;

          &:focus {
            text-decoration: underline;
          }
        }

        b {
          font-family: var(--emu-semantic-font-families-poppins-semi-bold);
          font-weight: var(--emu-common-font-weight-bold);
        }
      }
    }
  }
}

.cmp-experiencefragment--footer {
  footer {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    margin-top: 20px;
    border-top-width: var(--emu-common-border-width-thin);
    border-top-style: solid;
    border-top-color: var(--emu-semantic-colors-primary-pink-100);
    background-color: var(--emu-semantic-colors-secondary-grey-200);
    position: relative;
    z-index: var(--emu-common-other-z-index-isi);
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    @include mq('medium') {
      padding-top: 18px;
      padding-right: 50px;
      padding-bottom: 18px;
      padding-left: 50px;
      margin-top: 40px;
    }
  }
}
