[data-component='modal'] {
  [data-component='text'] sup {
    font-size: 92%;
    top: var(--emu-common-spacing-none);
  }

  .aaaem-container,
  .modal-content {
    max-width: 100%;
    background-color: var(--emu-common-colors-transparent);
  }
}

.page-load-modal {
  .modal {
    &__section {
      margin-top: 15px;
      background-image: -webkit-linear-gradient(130deg,
          var(--emu-semantic-colors-secondary-grey-300),
          var(--emu-semantic-colors-secondary-grey-200)); // to match the live.
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); // to match the live.

      @include mq('medium') {
        margin-top: 30px;
        width: 78%;
        height: 350px; // to match the live
        transform: skew(-14deg);
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
      }

      @include mq('tablet') {
        width: 85%;
        height: 475px; // to match the live
      }

      @include mq('xx-large') {
        max-width: 100%;
      }
    }

    &__inner-section {
      padding-top: 35px;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        display: flex;
        flex-direction: row;
        transform: skew(15deg);
      }

      @include mq('xx-large') {
        max-width: 100%;
      }

      >.container {
        &:first-child {
          flex: 0 0 50%;

          // media query needed to match the design
          @include mq('811px') {
            flex: 0 0 55%;
          }

          // media query needed to match the design
          @include mq('1025px') {
            flex: 0 0 60%;
          }
        }
      }

      .modal__image-desktop {
        img {
          @include mq('medium') {
            position: absolute;
            top: var(--emu-common-spacing-none);
            width: 64%; // to match the live
            right: -25px;
          }

          // media query needed to match the design
          @include mq('811px') {
            width: 61%;
            right: -31px;
          }

          // media query needed to match the design
          @include mq('840px') {
            width: 58%;
            right: -16px;
          }

          @include mq('tablet') {
            width: 520px; // value from live site
            right: -60px;
          }

          @include mq('large') {
            right: -46px;
          }

          @include mq('x-large') {
            width: 535px; // value from live site
            right: 3px;
          }
        }
      }
    }

    &-content {
      margin-top: var(--emu-common-spacing-none);
      margin-right: auto;
      margin-bottom: var(--emu-common-spacing-none);
      margin-left: auto;

      @include mq('medium') {
        max-width: 100%;
        width: 100%;
      }
    }

    &__content-section {
      @include mq('medium') {
        padding-top: 40px;
        padding-bottom: var(--emu-common-spacing-xxs);
        position: relative;
        left: -5px;
      }

      @include mq('820px') {
        padding-bottom: 20px;
      }

      @include mq('tablet') {
        padding-bottom: 51px;
        left: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        padding-bottom: 55px;
      }

      @include mq('x-large') {
        padding-top: 38px;
        padding-bottom: 30px;
      }

      // media query needed to match the design
      @include mq('1340px') {
        padding-top: 40px;
        padding-bottom: 48px;
      }

      h2 {
        font-size: 42px;
        line-height: 46px;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 10px;
        color: var(--emu-semantic-colors-primary-green-200);

        @include mq('tablet') {
          font-size: 90px;
          line-height: 99px;
        }

        @include mq('x-large') {
          font-size: 98px;
          line-height: 108px;
        }

        // media query needed to match the design
        @include mq('1340px') {
          font-size: 100px;
          line-height: 110px;
        }

        br {
          display: none;

          @include mq('medium') {
            display: block;
          }
        }
      }

      .aaaem-text {
        width: 73%; // to match the live site
        margin-top: 12px;
        margin-right: auto;
        margin-bottom: 12px;
        margin-left: auto;

        @include mq('medium') {
          max-width: 73%;
          width: 100%;
          margin-top: 15px;
          margin-bottom: 15px;

          br {
            display: none;
          }
        }

        // media query needed to match the design
        @include mq('810px') {
          max-width: 75%;
        }

        @include mq('tablet') {
          max-width: 85%;
          margin-left: 5px;
        }

        // media query needed to match the design
        @include mq('1340px') {
          margin-top: 15px;
          margin-bottom: 21px;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('xx-large') {
          max-width: 86%;
          margin-top: 12px;
          text-align: center;
        }

        p {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          font-weight: var(--emu-semantic-font-weight-500);
          line-height: 20px;
          color: var(--emu-common-colors-black);
          letter-spacing: -0.7px; // to match the live site
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('medium') {
            letter-spacing: normal;
            font-size: 12px;
            line-height: 17px;
          }

          @include mq('tablet') {
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 22px;
          }

          // media query needed to match the design
          @include mq('xx-large') {
            text-align: center;
          }

          .emphasis {
            sup {
              font-size: 65%;
              top: -2px;
            }

            @include mq('medium') {
              font-size: var(--emu-common-font-sizes-wide-medium);
              line-height: 22px;
              font-style: italic;
            }

            // media query needed to match the design
            @include mq('1340px') {
              font-style: normal;
            }

            @include mq('xx-large') {
              display: block;
            }
          }
        }
      }

      .aaaem-image {
        max-width: 75%;
        margin: auto;

        @include mq('490px') {
          max-width: 338px; // to match the live
        }

        @include mq('medium') {
          max-width: 75%;
          padding-top: 5px;
          padding-bottom: 3px;
        }

        @include mq('tablet') {
          max-width: 338px;
          padding-top: 2px;
          padding-bottom: 2px;
        }

        @include mq('large') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
        }

        img {
          width: 100%;
        }
      }

      .aaaem-button {
        padding-top: var(--emu-common-spacing-small);
        padding-right: 20px;
        padding-bottom: var(--emu-common-spacing-small);
        padding-left: 20px;
        border-width: 2px;
        max-width: 220px;
        width: 100%;
        margin-top: 6px;
        margin-right: auto;
        margin-bottom: 6px;
        margin-left: auto;
        display: flex;
        justify-content: center;

        @include mq('medium') {
          margin-left: 15px;
        }

        // media query needed to match the design
        @include mq('811px') {
          margin-left: auto;
        }

        @include mq('tablet') {
          max-width: 295px;
          margin-left: 100px;
        }

        @include mq('large') {
          margin-left: 105px;
        }

        @include mq('x-large') {
          margin-left: 95px;
        }

        // media query needed to match the design
        @include mq('1340px') {
          margin-left: auto;
        }

        .cmp-button__text {
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          font-family: var(--emu-semantic-font-families-poppins-semi-bold);
        }
      }
    }

    &__image {
      &-section {
        padding-bottom: 5px;

        @include mq('medium') {
          padding: var(--emu-common-spacing-none);
        }
      }

      &-mobile {
        max-width: 342px; // to match the live site
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      &-caption {
        position: absolute;
        bottom: var(--emu-common-spacing-small);
        left: 48%;
        font-size: 10px;
        line-height: 14px;
        color: var(--emu-common-colors-black);

        @include mq('medium') {
          color: var(--emu-common-colors-white);
          bottom: 7px;
          left: 67%;
        }

        // media query needed to match the design
        @include mq('840px') {
          bottom: 15px;
          left: 69%;
        }

        @include mq('tablet') {
          bottom: 12px;
          left: initial;
          right: 75px;
        }

        @include mq('x-large') {
          bottom: 7px;
          right: 90px;
        }

        p {
          margin: var(--emu-common-spacing-none);

          &:first-child {
            letter-spacing: -1px;
            transform: skew(10deg);
            text-shadow: 0px 1px 2px #f0dddd; // as per live site, color used only once

            @include mq('medium') {
              letter-spacing: normal;
              text-shadow: 2px 2px 8px #f5f3f3; // as per live site, color used only once
            }
          }

          sup {
            top: var(--emu-common-spacing-none);
          }
        }
      }
    }

    &__small-text {
      position: absolute;
      font-size: 8px;
      line-height: 11px;
      left: -3px;

      // media query needed to match the design
      @include mq('600px') {
        left: -7px;
      }

      @include mq('medium') {
        padding-top: 10px;
        position: relative;
        left: initial;
        margin: auto;
        max-width: 94%;
      }

      // media query needed to match the design
      @include mq('810px') {
        padding-top: 10px;
      }

      @include mq('tablet') {
        font-size: 12px;
        line-height: 16px;
      }

      p {
        font-family: var(--emu-semantic-font-families-poppins-light);
        color: var(--emu-common-colors-white);
        margin-top: 10px;
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
        }

        sup {
          top: -1px;
        }

        .emphasis sup {
          font-size: 60%;
          top: -2px;
        }
      }
    }

    &-wrapper {
      display: block;
      visibility: visible;
      opacity: 1;
      transition-property: visibility, opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      transition-delay: 0.1s;

      &:not(.is-open) {
        .modal-content {
          transform: translate(0%, -25%);
          transition-duration: 0.3s;
        }

        visibility: hidden;
        opacity: 0;
      }
    }
  }
  
  .close {
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
    font-family: var(--emu-semantic-font-families-poppins-light);
    font-weight: 100;
    border: 2px solid rgba(56, 56, 56, 0.88); // no var. used only for this element
    color: rgba(56, 56, 56, 0.88); // no var, used only for this element
    border-radius: 50%;
    width: 27px; // to match live site
    height: 27px; // to match live site

    @include mq('medium') {
      font-size: 47px;
      top: 16px;
      z-index: 100;
      right: initial;
      left: 88%;
      width: 38px; // to match live site
      height: 38px; // to match live site
    }

    @include mq('tablet') {
      left: 93%;
    }

    @include mq('x-large') {
      left: 92%;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }

    span {
      display: block;
      line-height: 23px;

      @include mq('medium') {
        line-height: 36px;
      }
    }
  }
}

.temple-popup {
  .modal {
    &__container {
      margin-top: 30px;
      background-image: -webkit-linear-gradient(130deg,
          var(--emu-semantic-colors-secondary-grey-300),
          var(--emu-semantic-colors-secondary-grey-200)); // to match the live.
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); // to match the live.

      @include mq('medium') {
        margin-top: 30px;
        width: 78%;
        height: 350px; // to match the live
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
      }

      @include mq('tablet') {
        width: 760px;
        height: 468px; // to match the live
      }
    }

    &__inner-container {
      padding-top: 17px;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        display: flex;
        flex-direction: row;
      }

      @include mq('large') {
        max-width: 100%;
      }

      >.container {
        &:first-child {
          flex: 0 0 50%;
        }
      }

      .modal__image-desktop {
        img {
          @include mq('medium') {
            position: absolute;
            width: 270px;
            right: 90px;
            top: 35px;
          }

          @include mq('tablet') {
            margin-top: 55px;
            width: 350px;
            position: static;
          }

          @include mq('large') {
            width: 368px; // value from live site
          }
        }
      }
    }

    &-content {
      margin-top: var(--emu-common-spacing-none);
      margin-right: 22px;
      margin-bottom: var(--emu-common-spacing-none);
      margin-left: 22px;
      width: auto;

      @include mq('medium') {
        max-width: 100%;
        width: 100%;
      }

      @include mq('large') {
        margin-left: auto;
        margin-right: auto;
        max-width: 760px;
      }
    }

    &__content-container {
      @include mq('medium') {
        padding-top: 22px;
        padding-left: 55px;
        padding-bottom: var(--emu-common-spacing-xxs);
        position: relative;
      }

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-none);
        width: 392px;
      }

      h2 {
        font-family: 'Oswald-ExtraLight';
        font-size: 40px;
        font-style: italic;
        font-weight: var(--emu-common-font-weight-light);
        line-height: 51.88px;
        text-align: center;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        color: #FF5653;

        b {
          font-family: 'Oswald-Regular';
        }

        @include mq('large') {
          font-size: 54px;
          line-height: 70px;
        }

        br {
          display: none;

          @include mq('medium') {
            display: block;
          }
        }
      }

      .aaaem-text {
        margin-right: 30px;
        margin-bottom: 12px;
        margin-left: 30px;

        @include mq('medium') {
          width: 100%;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 20px;
          margin-right: var(--emu-common-spacing-none);
          margin-left: var(--emu-common-spacing-none);

          br {
            display: none;
          }
        }

        @include mq('large') {
          margin-right: auto;
          margin-top: var(--emu-common-spacing-none);
          margin-left: auto;
          width: 300px;
        }

        p {
          font-family: 'Oswald-ExtraLight';
          font-size: 18px;
          font-weight: var(--emu-common-font-weight-light);
          line-height: 22px;
          text-align: center;
          color: #201B1B;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            font-size: 26px;
            line-height: 32px;
          }

          .emphasis {
            font-family: 'Oswald-Regular';

            sup {
              font-size: 65%;
              top: -2px;
            }

            @include mq('large') {
              font-size: 26px;
              line-height: 32px;
            }
          }
        }
      }

      .aaaem-image {
        max-width: 338px;
        margin-left: 85px;
        margin-right: 85px;

        @include mq('medium') {
          margin-right: 65px;
          margin-left: 65px;
          max-width: 100%;
        }

        @include mq('large') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
        }

        img {
          width: 100%;
        }
      }

      .aaaem-button {
        padding-top: var(--emu-common-spacing-small);
        padding-right: 30px;
        padding-bottom: var(--emu-common-spacing-small);
        padding-left: 30px;
        border-width: 2px;
        max-width: 162px;
        width: 100%;
        margin-top: var(--emu-common-spacing-none);
        margin-right: auto;
        margin-bottom: 20px;
        margin-left: auto;
        display: flex;
        justify-content: center;
        background-color: #FF5653;

        @include mq('medium') {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
          margin-bottom: 40px;
        }

        @include mq('large') {
          max-width: 193px;
        }

        .cmp-button__text {
          font-family: var(--emu-semantic-font-families-mono),
            sans-sarif;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;

          @include mq('large') {
            font-size: 26px;
            line-height: 32px;
          }
        }
      }
    }

    &__image {
      &-section {
        padding-bottom: 5px;

        @include mq('medium') {
          padding: var(--emu-common-spacing-none);
        }
      }

      &-mobile {
        max-width: 342px; // to match the live site
        width: 100%;
        margin-left: 20px;
        padding-bottom: 15px;
        margin-right: auto;
      }

      &-caption {
        position: absolute;
        bottom: var(--emu-common-spacing-small);
        left: 48%;
        font-size: 10px;
        line-height: 14px;
        color: var(--emu-common-colors-black);

        @include mq('medium') {
          color: var(--emu-common-colors-white);
          bottom: 7px;
          left: 67%;
        }

        // media query needed to match the design
        @include mq('840px') {
          bottom: 15px;
          left: 69%;
        }

        @include mq('tablet') {
          bottom: 12px;
          left: initial;
          right: 75px;
        }

        @include mq('x-large') {
          bottom: 7px;
          right: 90px;
        }

        p {
          margin: var(--emu-common-spacing-none);

          &:first-child {
            letter-spacing: -1px;
            text-shadow: 0px 1px 2px #f0dddd; // as per live site, color used only once

            @include mq('medium') {
              letter-spacing: normal;
              text-shadow: 2px 2px 8px #f5f3f3; // as per live site, color used only once
            }
          }

          sup {
            top: var(--emu-common-spacing-none);
          }
        }
      }
    }

    &-wrapper {
      display: block;
      visibility: visible;
      opacity: 1;
      transition-property: visibility, opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      transition-delay: 0.1s;
      background-color: rgba(0, 0, 0, 0.4); // no var, used only for this element

      &:not(.is-open) {
        .modal-content {
          transform: translate(0%, -25%);
          transition-duration: 0.3s;
        }

        visibility: hidden;
        opacity: 0;
      }
    }
  }
   
  .modal-wrapper {
    .modal-content {
      .close {
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        font-family: var(--emu-semantic-font-families-poppins-light);
        font-weight: 100;
        border: 2px solid rgba(56, 56, 56, 0.88); // no var. used only for this element
        color: rgba(56, 56, 56, 0.88); // no var, used only for this element
        border-radius: 50%;
        width: 24px; // to match live site
        height: 24px; // to match live site
        display: grid;
        align-items: center;

        @include mq('medium') {
          font-size: 28px;
          top: 8px;
          z-index: 100;
          right: initial;
          left: 85%;
        }

        @include mq('large') {
          left: auto;
          top: 16px;
          right: 16px;
        }

        &:hover,
        &:focus {
          opacity: 1;
        }

        span {
          display: block;
          line-height: 22px;
        }
      }
    }
  }

  .separator {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 10px;

    @include mq('medium') {
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
      padding-top: 27px;
      padding-bottom: 18px;
    }
  }

  .image {
    display: grid;
    justify-content: center;
  }

  .button {
    @include mq('medium') {
      display: grid;
      justify-content: center;
    }
  }
}

body.js-modal-opened {
  height: 100vh;
  overflow: hidden;
}

// author styles
.modal-wrapper {
  @include aem-editor-view {
    opacity: 1 !important; // making sure that the modal content is authorable
    visibility: visible !important; // making sure that the modal content is authorable
  }

  img,
  .modal__image-caption {
    @include aem-editor-view {
      position: static !important; // making sure that the modal content is authorable
    }
  }

  .modal__section, 
  .modal__container {
    @include aem-editor-view {
      height: auto !important;
    }
  }

  .modal-content {
    @include aem-editor-view {
      transform: none !important;
    }
  }
}
