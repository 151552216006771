@import url('https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css');

@font-face {
  font-family: 'Oswald-MediumItalic';
  src: url('./assets/fonts/Oswald/Oswald-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald-ExtraLight';
  src: url('./assets/fonts/Oswald/Oswald-ExtraLight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald-Light';
  src: url('./assets/fonts/Oswald/Oswald-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald-Regular';
  src: url('./assets/fonts/Oswald/Oswald-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
    url('./assets/fonts/Poppins/Poppins-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
    url('./assets/fonts/Poppins/Poppins-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins/Poppins-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Poppins/Poppins-SemiBold.woff') format('woff');
  font-display: swap;
}
