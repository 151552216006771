#sitemap {
  .main-container {
    padding-bottom: 20px;

    @include mq('tablet') {
      padding-bottom: 60px;
    }
  }

  .sitemap__text {
    h1 {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: 10px;
      font-family: var(--emu-semantic-font-families-mono);
      color: var(--emu-semantic-colors-secondary-dark-grey-500);
      font-size: var(--emu-semantic-font-sizes-wide-medium);
      font-weight: var(--emu-semantic-font-weight-500);
      line-height: var(--emu-semantic-line-heights-narrow-xxl);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        line-height: var(--emu-semantic-line-heights-wide-xxl);
      }

      @include mq('tablet') {
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: var(--emu-semantic-line-heights-wide-xxxl);
      }
    }

    p {
      color: var(--emu-semantic-colors-secondary-dark-grey-600);
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      margin-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-xxs);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        padding-bottom: 5px;
      }

      @include mq('tablet') {
        padding-bottom: var(--emu-common-spacing-none);
      }
    }

    a {
      color: var(--emu-semantic-colors-primary-pink-100);
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      text-decoration: none;
      margin-bottom: 5px;
      display: inline-block;
      line-height: 19.6px; // decimals needed to match design
      letter-spacing: normal;

      &:focus {
        text-decoration: underline;
      }

      @include mq('tablet') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        line-height: 25.2px; // decimals needed to match design
        margin-bottom: 7px;
      }

      sup {
        font-size: 13px;
        left: -1px;

        @include mq('tablet') {
          font-size: var(--emu-common-font-sizes-wide-medium);
        }
      }
    }

    ul {
      list-style: none;
      margin-top: 22px;
      margin-bottom: var(--emu-common-spacing-large);
      padding-left: var(--emu-common-spacing-none);
      line-height: 19px;
    }

    li {
      padding-bottom: var(--emu-common-spacing-xxs);

      @include mq('medium') {
        padding-bottom: 5px;
      }

      @include mq('tablet') {
        padding-bottom: var(--emu-common-spacing-none);
      }
    }
  }
}
