[data-component='container-isi-v2'].isi-scrollable .isi-container {
  z-index: var(--emu-component-containers-isi-z-index);
}

[data-component='container-isi-v2'] {
  p {
    sup {
      font-size: 100%;
      top: -1px;
      vertical-align: middle;
    }
  }

  [data-ref='content-footer'] {
    padding-top: 40px;
  }

  .aaaem-isi-banner {
    margin-top: 10px;
    padding-top: var(--emu-common-spacing-none);
    position: relative;

    @include mq('medium') {
      margin-top: 15px;
    }

    .aaaem-button {
      padding: var(--emu-common-spacing-none);
      font-size: 11px;
      line-height: 17px;
      font-family: var(--emu-semantic-font-families-poppins-bold);
      color: var(--emu-semantic-colors-primary-pink-100);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 21px;
      }

      @include mq('tablet') {
        font-size: 13px;
        line-height: var(--emu-semantic-line-heights-wide-medium);
      }

      &:focus {
        text-decoration: underline;
        color: var(--emu-semantic-colors-secondary-blue-200);

        .cmp-button__text::after {
          background-color: var(--emu-semantic-colors-secondary-blue-200);
        }
      }

      &:hover {
        text-decoration: none;
        color: var(--emu-semantic-colors-primary-pink-100);

        .cmp-button__text::after {
          background-color: var(--emu-semantic-colors-primary-pink-100);
        }
      }

      .cmp-button__text {
        margin-left: 5px;
        padding-left: var(--emu-common-spacing-medium);
        white-space: nowrap;
        border-left: 1px solid
          var(--emu-semantic-colors-secondary-dark-grey-500);

        // minus icon
        &::after {
          content: '';
          width: 11px; // To match the live site.
          height: 2px;
          background-color: var(--emu-semantic-colors-primary-pink-100);
          margin-left: 3px;
          display: inline-block;
          vertical-align: middle;
        }
      }

      &.js-toggle-on {
        .cmp-button__text {
          padding-right: var(--emu-common-spacing-none);

          &::after {
            display: none;
          }
        }
      }
    }
  }

  .aaaem-container,
  .aaaem-isi-container__isi-content {
    background-color: var(--emu-common-colors-transparent);
  }

  > .aaaem-isi-container__isi-content {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;

    @include mq('tablet') {
      max-width: 68%;
      margin-right: 20px;
      margin-left: 210px;
    }

    // media query needed to match live site
    @include mq('1025px') {
      max-width: 100%;
    }

    @include mq('x-large') {
      max-width: 73%;
      margin-right: 120px;
      margin-left: auto;
    }

    @include mq('x-large-plus') {
      max-width: 76%;
    }
  }

  .aaaem-isi-container {
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    max-height: 170px; // As per live site.
    overflow-y: auto;

    @include mq('medium') {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include mq('tablet') {
      padding-right: 45px;
    }

    &__banner-content {
      padding-top: 18px;
      display: block !important; // This is hidden by AAAEM Common with inline styles. Making sure that this is visible all the time
      max-width: 80%;

      @include mq('small') {
        padding-top: var(--emu-common-spacing-none);
        flex: 1;
        max-width: none;
      }

      p {
        margin: var(--emu-common-spacing-none);
        margin-bottom: 10px;
        color: var(--emu-semantic-colors-primary-purple-600);
        font-size: 13px;
        line-height: 17px;
        font-weight: var(--emu-semantic-font-weight-500);
        font-family: var(--emu-semantic-font-families-poppins-bold);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: 23px;
        }

        br {
          @include mq('small') {
            display: none;
          }
        }
      }
    }

    &__button-wrapper {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
      top: 0;
      right: 0;

      @include mq('small') {
        position: static;
      }

      > .read-more-button {
        text-decoration: none;
        white-space: nowrap;
        padding-right: var(--emu-common-spacing-medium);
        margin-right: 5px;

        @include mq('tablet') {
          padding-left: 10px;
          padding-right: 10px;
        }

        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .isi-container {
    @include mq('tablet') {
      z-index: var(--emu-common-other-z-index-header);
    }

    .aaaem-isi-container__isi-content {
      transition-duration: 0s;
      overflow: hidden;
      height: 100%;

      .hcpj-isi__title {
        display: none;
      }

      p,
      li,
      .emphasis {
        font-size: 13px;
        line-height: var(--emu-semantic-line-heights-narrow-large);
        margin-bottom: 10px;

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 22px;
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      h4 {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: 21px;
        font-family: var(--emu-semantic-font-families-poppins-bold);
        font-weight: var(--emu-semantic-font-weight-700);
        margin-bottom: 10px;
      }

      .hcpj-isi__inner-container {
        max-width: 100%;
      }

      &.js-expand {
        height: 20px; // height adjusted to show one line of isi banner on screen all the time

        @include mq('medium') {
          height: 25px; // height adjusted to show one line of isi banner on screen all the time
        }
      }
    }
  }
}

.hcpj-isi {
  &__inner-container {
    .aaaem-text {
      font-size: 13px;
      line-height: var(--emu-semantic-line-heights-narrow-large);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        line-height: var(--emu-semantic-line-heights-wide-large);
      }

      p {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 10px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }
        @include mq('tablet') {
          margin-bottom: 15px;
        }
      }

      h4 {
        font-size: 13px;
        line-height: var(--emu-semantic-line-heights-wide-medium);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-xs);
        font-family: var(--emu-semantic-font-families-poppins-bold);
        color: var(--emu-common-colors-black);
        font-weight: var(--emu-semantic-font-weight-400);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-large);
          line-height: 30px;
        }
      }

      ul {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 15px;
        padding-left: 15px;

        @include mq('tablet') {
          padding-left: var(--emu-common-spacing-large);
        }

        li {
          margin-bottom: 6px;
        }
      }

      a {
        text-decoration: none;
      }
    }

    .hcpj-isi__title {
      font-size: 13px;
      line-height: 16px;
      font-weight: var(--emu-semantic-font-weight-700);
      color: var(--emu-semantic-colors-primary-purple-500);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-large);
        line-height: 24px;
      }

      p {
        font-family: var(--emu-semantic-font-families-poppins-bold);
        margin-bottom: var(--emu-common-spacing-xs);
      }
    }

    > .text {
      &:nth-child(3) {
        b {
          a {
            text-decoration: underline;
            color: var(--emu-semantic-colors-primary-purple-300);

            &:active,
            &:hover {
              text-decoration: none;
            }

            &:focus,
            &:hover {
              color: var(--emu-semantic-colors-secondary-blue-200);
            }
          }
        }
      }

      &:last-child {
        p {
          margin-bottom: 18px;

          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-small);
          }

          @include mq('tablet') {
            margin-bottom: 23px;
          }

          &:last-child {
            margin-bottom: var(--emu-common-spacing-small);
          }

          a {
            color: var(--emu-semantic-colors-secondary-blue-100);
            text-decoration: underline;

            &:hover {
              color: var(--emu-semantic-colors-secondary-blue-200);
            }
          }
        }
      }
    }
  }

  &__primary-text {
    .emphasis {
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: 16px;

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        line-height: var(--emu-semantic-line-heights-wide-large);
      }
    }
  }

  &__secondary-text {
    padding-top: var(--emu-common-spacing-small);
    padding-right: 5px;

    p {
      b {
        font-family: var(--emu-semantic-font-families-poppins-bold);
        font-weight: var(--emu-semantic-font-weight-700);

        > .emphasis {
          display: inline-block;
        }
      }
      a {
        > .emphasis {
          color: var(--emu-semantic-colors-secondary-dark-grey-400);

          @include mq('x-large-plus') {
            font-family: var(--emu-semantic-font-families-poppins-bold);
            font-weight: var(--emu-semantic-font-weight-700);
          }
        }
      }
    }
  }
}

// Update isi banner properties when footer is visible on the screen.
.footer-in-viewport {
  .aaaem-isi-container {
    visibility: hidden;
  }
}
