#new-customers-section {
  margin-top: 50px;
  margin-bottom: 10px;

  @include mq('medium') {
    margin-top: 100px;
    margin-bottom: 80px;
  }

  .new-customer {
    &__contact {
      &-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-items: stretch;
        margin-top: 30px;

        @include mq('medium') {
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
        }

        a {
          &,
          &:hover,
          &:active,
          &:focus {
            color: inherit;
            text-decoration: none;
          }
        }
      }

      &-text {
        p {
          margin-top: 5px;
          margin-bottom: 30px;
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: 30px;
          text-align: center;
          font-family: var(--emu-semantic-font-families-poppins-light);
          color: var(--emu-semantic-colors-primary-purple-500);
          font-weight: var(--emu-semantic-font-weight-500);
          text-align: center;

          @include mq('medium') {
            line-height: 45px;
            margin-bottom: 5px;
            text-align: left;
          }

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            text-align: left;
          }

          b {
            font-family: var(--emu-semantic-font-families-poppins-semi-bold);
            line-height: 1.3;
            font-weight: var(--emu-semantic-font-weight-700);
          }

          .emphasis {
            display: block;

            @include mq('small') {
              display: inline;
            }

            @include mq('medium') {
              display: block;
            }
          }

          br {
            @include mq('small') {
              display: none;
            }
          }

          sup {
            font-size: 90%;
            vertical-align: super;
            top: 5px;

            @include mq('medium') {
              top: 6px;
            }
          }
        }
      }

      &-num {
        margin-bottom: 15px;
        background-image: linear-gradient(
          to left,
          var(--emu-semantic-colors-secondary-grey-300),
          var(--emu-semantic-colors-secondary-grey-200)
        );

        @include mq('medium') {
          width: 300px; // value from live site
          transform: skew(-24deg);
          margin-left: 20px;
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          width: auto;
          max-width: 400px;
        }

        h5 {
          padding-top: 25px;
          padding-bottom: 25px;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          font-size: 22px;
          line-height: 1.1;
          color: var(--emu-semantic-colors-primary-purple-500);
          font-family: var(--emu-semantic-font-families-mono);
          font-weight: var(--emu-semantic-font-weight-700);

          @include mq('medium') {
            transform: skew(24deg);
            padding-top: 22px;
            padding-right: 50px;
            padding-bottom: 22px;
            padding-left: 50px;
          }

          @include mq('x-large') {
            padding-right: 80px;
            padding-left: 80px;
            font-size: 24px;
          }
        }
      }
    }

    &__services-wrapper {
      display: flex;
      justify-content: center;
      max-width: 905px;
      flex-wrap: wrap;

      @include mq('medium') {
        flex-wrap: nowrap;
        margin: auto;
      }

      a {
        &,
        &:hover,
        &:active,
        &:focus {
          color: inherit;
          text-decoration: none;
        }
      }

      > .container {
        position: relative;
        flex: 0 0 100%;
        padding-top: 15px;
        padding-bottom: 5px;
        margin-top: var(--emu-common-spacing-xxs);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include mq('medium') {
          flex: 0 0 34%;
          padding-top: 30px;
          padding-bottom: 55px;
        }

        &:not(:last-child) {
          margin-bottom: 30px;

          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-none);
          }

          &::after {
            content: '';
            width: 36%; // value from live site
            height: var(--emu-semantic-border-width-medium);
            top: 0;
            bottom: -166px; // value from live site
            left: 0;
            right: 0;
            background-color: var(--emu-semantic-colors-primary-pink-100);
            display: block;
            margin: auto;
            position: absolute;

            @include mq('medium') {
              margin: 0;
              top: 28px;
              width: var(--emu-semantic-border-width-medium);
              height: 102px; // value from live site
              bottom: 0;
              left: auto;
            }

            @include mq('tablet') {
              top: 42px;
            }
          }
        }

        &:nth-child(2) {
          .new-customer__service-phone {
            p {
              font-family: var(--emu-semantic-font-families-poppins-light);

              @include mq('x-large-plus') {
                font-family: var(
                  --emu-semantic-font-families-poppins-semi-bold
                );
              }
            }
          }
        }

        &:last-child {
          margin-top: var(--emu-common-spacing-none);

          .new-customer__service-title {
            h5 {
              margin-top: 6px;
              margin-bottom: 5px;
              line-height: 1.3;
            }
          }
        }
      }
    }

    &__service {
      margin-top: 5px;
      margin-bottom: 5px;

      h5 {
        color: var(--emu-semantic-colors-primary-purple-500);
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        font-family: var(--emu-semantic-font-families-poppins-semi-bold);
        line-height: 1.405; // needed to match design
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
        font-weight: var(--emu-semantic-font-weight-700);

        @include mq('tablet') {
          font-size: var(--emu-semantic-font-sizes-wide-large);
        }
      }

      &-copy {
        &--desk {
          display: none;
          @include mq('x-large-plus') {
            display: block;
          }
        }

        &--mob {
          @include mq('x-large-plus') {
            display: none;
          }
        }
      }

      &-phone {
        p {
          color: var(--emu-semantic-colors-primary-pink-100);
          margin-bottom: 6px;
          margin-top: 3px;
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          font-weight: var(--emu-semantic-font-weight-700);
          font-family: var(--emu-semantic-font-families-poppins-semi-bold);

          @include mq('tablet') {
            margin-bottom: var(--emu-common-spacing-xs);
          }

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: 1.5;
          }
        }
      }

      &-text {
        p {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          color: var(--emu-semantic-colors-primary-purple-500);
          font-family: var(--emu-semantic-font-families-poppins-light);
          line-height: 1.5;

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }
        }

        .emphasis {
          display: block;
        }
      }
    }

    &__insta {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      max-width: 700px;
      margin: auto;
      justify-content: center;
      align-items: center;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        flex-direction: row;
      }

      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
        }

        img {
          width: 40px;
          height: auto;
          margin-right: 20px;
          display: inline-block;
          vertical-align: middle;

          @include mq('medium') {
            width: auto;
            margin-right: var(--emu-common-spacing-none);
          }
        }

        h3 {
          margin-top: 15px;
          margin-bottom: 15px;
          line-height: 1.4;
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          color: var(--emu-semantic-colors-secondary-dark-grey-500);
          font-family: var(--emu-semantic-font-families-mono);

          @include mq('medium') {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            margin-left: 50px;
            margin-right: 50px;
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
          }

          @include mq('tablet') {
            font-size: 30px;
          }

          sup {
            font-size: 48%;
            vertical-align: super;
            top: -1px;
            left: 1px;

            @include mq('medium') {
              left: 0;
            }
          }
        }
      }

      &-cta {
        font-family: var(--emu-semantic-font-families-poppins-semi-bold);
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        padding-top: 10px;
        padding-right: 40px;
        padding-bottom: 10px;
        padding-left: 22px;
        line-height: 1.4;

        &::after {
          position: absolute;
          content: '';
          top: 0;
          right: 3px;
          bottom: 0;
          margin: auto;
          width: 20px;
          height: 20px;
          background: url('./assets/images/arrow-icon.png') no-repeat;
        }
      }
    }
  }
}

.new-customer__service-copy--mob,
.new-customer__service-copy--desk {
  @include aem-editor-view {
    display: block !important;
  }
}
