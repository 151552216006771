#treatment-section {
  > .container {
    padding-top: 20px;
    padding-bottom: 50px;

    @include mq('medium') {
      padding-top: 95px;
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-secondary-dark-grey-100);
    }
  }

  .main-title {
    &--treatment-areas {
      margin-bottom: 20px;

      @include mq('medium') {
        margin-bottom: 40px;
      }
    }
  }

  .emu-mapping-marker-1.active {
    &::after {
      @include mq('small') {
        content: '';
        width: var(--emu-common-border-width-medium);
        height: 7vh;
        background-color: var(
          --emu-component-ingredients-image-map-buttons-color-icon-light
        );
        position: absolute;
        top: 50%;
        left: calc(-65vw + 17px);
        transform: translate(50%, -7vh);
      }
    }
  }

  .skewed-container {
    &__wrapper {
      margin-top: 30px;

      @include mq('medium') {
        width: 85%;
        max-width: 885px; // value from live site
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
      }

      @include mq('x-large') {
        width: 100%;
      }

      .skewed-container {
        &__main {
          background: linear-gradient(
            to left,
            var(--emu-semantic-colors-secondary-grey-300),
            var(--emu-semantic-colors-secondary-grey-200)
          );

          @include mq('medium') {
            transform: skew(-24deg);
          }

          @include mq('tablet') {
            max-width: 100%;
          }

          &-inner {
            display: flex;
            align-items: center;

            @include mq('medium') {
              transform: skew(24deg);
            }

            > .image {
              flex: 0 0 45%;

              @include mq('medium') {
                display: none;
              }

              @include mq('x-large') {
                flex: initial;
              }

              // desktop image
              &:nth-child(2) {
                display: none;

                @include mq('medium') {
                  display: block;
                }
              }

              img {
                @include mq('medium') {
                  margin-left: auto;
                  margin-right: auto;
                }

                @include mq('x-large') {
                  margin-left: var(--emu-common-spacing-none);
                }
              }
            }

            > .text {
              padding-top: 25px;
              padding-right: 25px;
              padding-bottom: 15px;
              padding-left: var(--emu-common-spacing-none);

              @include mq('x-large') {
                padding-right: var(--emu-common-spacing-none);
                padding-bottom: 25px;
              }
            }

            h3 {
              font-size: var(--emu-semantic-font-sizes-narrow-xxl);
              margin-bottom: 15px;
              margin-top: var(--emu-common-spacing-none);
              line-height: 1.1;
              font-weight: var(--emu-semantic-font-weight-500);
              font-family: var(--emu-semantic-font-families-heading);
              color: var(--emu-semantic-colors-primary-purple-500);

              @include mq('medium') {
                font-size: 28px;
              }

              @include mq('x-large') {
                font-size: 32px;
              }

              sup {
                font-size: 48%;
                display: inline-block;
                vertical-align: super;
                top: -1px;
                left: 1px;

                @include mq('medium') {
                  left: 0;
                }
              }
            }

            h5 {
              margin: 5px;
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
              line-height: 1.32;
              font-weight: var(--emu-semantic-font-weight-500);
              font-family: var(--emu-semantic-font-families-heading);
              color: var(--emu-semantic-colors-primary-purple-500);

              @include mq('medium') {
                font-size: var(--emu-semantic-font-sizes-wide-xl);
              }

              @include mq('x-large') {
                font-size: 24px;
                margin: var(--emu-common-spacing-none);
              }

              .emphasis {
                @include mq('x-large') {
                  display: block;
                }
              }

              sup {
                font-size: 48%;
                display: inline-block;
                vertical-align: super;
                top: 0;

                @include mq('medium') {
                  top: 1px;
                }
              }
            }
          }
        }
      }
    }
  }

  .disclaimer {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;

    p {
      padding-left: 20px;
      margin-top: 10px;
      color: var(--emu-common-colors-black);
      margin-bottom: 30px;

      @include mq('medium') {
        margin-bottom: 40px;
        padding-left: 35px;
      }

      @include mq('x-large') {
        padding-left: var(--emu-common-spacing-none);
      }
    }

    &--treatment-areas-jawline {
      max-width: none;

      @include mq('x-large') {
        margin-left: -15px;
        margin-right: -15px;
      }

      p {
        @include mq('medium') {
          margin-left: 20px;
          padding-left: 35px;
        }

        @include mq('tablet') {
          margin-left: 50px;
        }

        @include mq('x-large') {
          padding-left: var(--emu-common-spacing-none);
        }
      }

      sup {
        font-size: 60%;
        vertical-align: middle;
        top: -2px;
      }

      br {
        @include mq('small') {
          display: none;
        }
      }
    }

    &--treatment-areas-jawlines-satisfaction {
      max-width: 855px;

      br {
        @include mq('small') {
          display: none;
        }

        @include mq('x-large') {
          display: block;
        }
      }

      p {
        padding-left: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 60px;

        @include mq('medium') {
          padding-left: 15px;
        }

        @include mq('x-large') {
          padding-left: var(--emu-common-spacing-none);
        }
      }

      sup {
        font-size: 60%;
        top: -2px;
        vertical-align: middle;
      }
    }

    &--treatment-areas-jawlines-tabs {
      p {
        padding-left: var(--emu-common-spacing-none);

        @include mq('medium') {
          padding-left: 15px;
        }

        @include mq('x-large') {
          padding-left: var(--emu-common-spacing-none);
        }
      }
    }

    &--jawline-skewed-container {
      p {
        margin-left: 20px;
        padding-left: var(--emu-common-spacing-none);

        @include mq('medium') {
          padding-left: 35px;
        }

        @include mq('tablet') {
          margin-left: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          padding-left: var(--emu-common-spacing-none);
        }
      }
    }
  }

  .treatment-areas {
    &__img-map {
      &-container {
        position: relative;
      }

      &-disclaimer {
        p {
          position: absolute;
          color: var(--emu-common-colors-white);
          right: 15px;
          bottom: 8px;
          font-size: 8px;
          margin-top: 5px;
          margin-bottom: var(--emu-common-spacing-none);
          line-height: 1.3;
          text-align: center;

          @include mq('medium') {
            right: 25px;
            color: var(--emu-common-colors-black);
            bottom: 12px;
            font-size: 10px;
          }

          @include mq('tablet') {
            right: 75px;
          }

          sup {
            font-size: 80%;
            display: inline-block;
            vertical-align: super;
            top: 2px;
            left: 1px;

            @include mq('medium') {
              top: 3px;
              left: 0;
            }
          }

          .emphasis {
            display: block;

            sup {
              left: 0;

              @include mq('medium') {
                top: 4px;
              }
            }
          }
        }
      }

      &-tabs {
        .aaaem-tabs {
          &__tablist {
            display: none;
          }

          &__tabpanels {
            width: 160px; // value from live site
            height: 140px; // value from live site
            background-color: var(--emu-semantic-colors-secondary-grey-200);
            top: 0;
            bottom: 0;
            left: 30px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            padding-top: 30px;
            padding-right: 20px;
            padding-bottom: 30px;
            padding-left: 20px;

            @include mq('small') {
              width: 240px; // value from live site
              height: 205px; // value from live site
            }

            @include mq('medium') {
              width: 350px; // value from live site
              height: 230px; // value from live site
              padding-top: 25px;
              padding-right: 40px;
              padding-bottom: 30px;
              padding-left: 40px;
              left: 80px;
            }
          }

          &__tabpanel {
            padding-top: 10px;

            @include mq('small') {
              padding-top: 15px;
            }

            img {
              display: inline-block;
              width: 100%;
              margin-left: auto;
              margin-right: auto;
              max-width: 80px;

              @include mq('small') {
                max-width: 140px; // value from live site
              }
            }

            .cmp-container {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;

              & > .image + div {
                margin-top: 5px;

                @include mq('small') {
                  margin-top: 10px;
                }

                &.image {
                  @include mq('small') {
                    margin-top: 5px;
                  }

                  @include mq('medium') {
                    margin-top: 15px;
                  }
                }
              }
            }

            .sub-title {
              h3 {
                font-size: 20px;

                @include mq('medium') {
                  font-size: var(--emu-semantic-font-sizes-wide-xxl);
                }

                @include mq('tablet') {
                  font-size: 30px;
                }
              }
            }

            p {
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
              line-height: 1.4;
              font-family: var(--emu-semantic-font-families-mono);
            }
          }
        }
      }
    }

    &__content-tabs {
      &.aaaem-tabs {
        padding-top: 10px;

        @include mq('medium') {
          padding: var(--emu-common-spacing-none);
          max-width: 100%;
        }

        > .aaaem-tabs {
          &__tablist {
            margin-bottom: var(--emu-common-spacing-none);

            @include mq('medium') {
              background-color: var(--emu-semantic-colors-secondary-grey-300);
            }

            li {
              flex-basis: 32%;
              margin-bottom: 6px;

              @include mq('medium') {
                margin-bottom: var(--emu-common-spacing-none);
                flex: 1;
              }

              &:first-child {
                @include mq('medium') {
                  padding-right: 15px;
                }

                a {
                  @include mq('medium') {
                    position: relative;
                    clip-path: polygon(
                      0 0,
                      100% 0%,
                      calc(100% - 25px) 100%,
                      0% 100%
                    );
                    width: calc(100% + 15px);
                    transform: none;
                  }

                  span {
                    @include mq('medium') {
                      transform: translateX(-7.5px);
                    }
                  }
                }
              }

              &:last-child {
                @include mq('medium') {
                  padding-left: 15px;
                }

                a {
                  @include mq('medium') {
                    transform: translateX(-15px);
                    width: calc(100% + 15px);
                    clip-path: polygon(12% 0, 100% 0, 100% 100%, 0 100%);
                  }

                  span {
                    @include mq('medium') {
                      padding-left: var(--emu-common-spacing-none);
                      transform: translateX(7.5px);
                    }
                  }
                }
              }

              a {
                background-color: var(--emu-semantic-colors-secondary-grey-300);
                color: var(--emu-semantic-colors-primary-purple-500);
                padding-top: 14px;
                padding-bottom: 14px;
                font-size: var(--emu-semantic-font-sizes-narrow-xl);

                @include mq('medium') {
                  background-color: var(--emu-common-colors-transparent);
                  transform: skew(-20deg);
                }

                &[aria-selected='true'] {
                  background-color: var(--emu-semantic-colors-primary-pink-100);
                  color: var(--emu-common-colors-white);
                }

                span {
                  @include mq('medium') {
                    transform: skew(20deg);
                  }
                }
              }
            }
          }
        }
      }
    }

    &__checklist {
      ul {
        max-width: 80%;
        padding-top: 10px;
        padding-left: var(--emu-common-spacing-none);
        margin-left: auto;
        margin-right: auto;
        padding-left: 40px;

        @include mq('medium') {
          max-width: 550px; //to match what we have in live
        }
      }

      li {
        font-size: var(--emu-semantic-font-sizes-narrow-xxl);
        position: relative;
        line-height: 1.4;
        font-family: var(--emu-semantic-font-families-poppins-bold);
        color: var(--emu-common-colors-black);
        list-style: none;
        margin-bottom: 20px;

        &::before {
          position: absolute;
          content: '';
          width: 30px; // to match live
          height: 29px;
          top: 0;
          bottom: 0;
          left: -40px;
          margin: auto;
          background: url('./assets/images/right-check-icon.png');
        }
      }

      sup {
        font-size: 48%;
        display: inline-block;
        vertical-align: super;
        top: 0;

        @include mq('medium') {
          top: 4px;
        }
      }
    }

    &__skewed-holder--jawlines {
      @include mq('medium') {
        max-width: 885px;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('x-large') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);

        &.aaaem-container.cmp-container {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &__tab-content-container {
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 30px;
      margin-bottom: 10px;
      overflow: hidden;

      @include mq('medium') {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 70px;

        > div > div:not(.full-screen-tab-content) {
          max-width: 885px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .full-screen-tab-content {
        margin-left: -20px;
        margin-right: -20px;

        @include mq('medium') {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }
      }

      .main-title {
        margin-top: 30px;
        margin-bottom: 20px;

        @include mq('medium') {
          margin-top: 42px;
          margin-bottom: 30px;
        }
      }

      .sub-title {
        @include mq('medium') {
          margin-bottom: 30px;
        }

        br {
          display: none;
          @include mq('medium') {
            display: block;
          }
        }

        sup {
          font-size: 48%;
          vertical-align: super;
          display: inline-block;
          top: -1px;
          left: 1px;
        }
      }

      .treatment-areas {
        &__text p {
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          margin-top: var(--emu-common-spacing-none);
          margin-top: 10px;
          line-height: 1.4;
          color: var(--emu-semantic-colors-secondary-dark-grey-600);

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
          }

          sup {
            font-size: 100%;
            vertical-align: middle;
            display: inline-block;
            top: -1px;
            left: 1px;

            @include mq('medium') {
              left: 0;
            }
          }

          .emphasis {
            sup {
              font-size: 60%;
              top: -2px;
              left: 0;
            }
          }

          b {
            font-family: var(--emu-semantic-font-families-poppins-bold);
          }
        }

        &__highlighted-text {
          padding-top: 30px;
          padding-right: 25px;
          padding-bottom: 30px;
          padding-left: 25px;
          margin-bottom: 30px;
          background-color: var(--emu-semantic-colors-primary-purple-500);

          @include mq('medium') {
            margin-bottom: 40px;
            padding-top: 60px;
            padding-left: var(--emu-common-spacing-none);
            padding-bottom: 60px;
            padding-right: var(--emu-common-spacing-none);
          }

          @include mq('x-large') {
            margin-left: -15px;
            margin-right: -15px;
          }

          p {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
            line-height: 1.5;
            font-family: var(--emu-semantic-font-families-poppins-light);
            color: var(--emu-common-colors-white);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
            }
          }

          br {
            @include mq('small') {
              display: none;
            }
          }

          .emphasis {
            @include mq('medium') {
              display: block;
            }
          }

          sup {
            font-size: 48%;
            display: inline-block;
            vertical-align: super;
            top: 1px;
          }
        }

        &__cta {
          margin-top: 20px;
          width: 250px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          font-family: var(--emu-semantic-font-families-poppins-semi-bold);
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: 1.4;

          @include mq('medium') {
            margin-top: 60px;
          }
        }

        &__img {
          img {
            display: block;
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        &__disclaimer {
          p {
            font-family: var(--emu-semantic-font-families-poppins-bold);
            padding-left: var(--emu-common-spacing-none);
            padding-top: 20px;
            margin-top: 5px;
            margin-bottom: 23px;
            font-size: 8px;
            color: var(--emu-common-colors-black);

            @include mq('medium') {
              padding-top: var(--emu-common-spacing-none);
              padding-left: 60px;
              font-size: 13px;
              margin-bottom: 60px;
            }
          }
        }

        &__before-after {
          margin-top: 40px;
          margin-bottom: 30px;
          display: flex;
          justify-content: space-between;

          @include mq('medium') {
            margin-top: 80px;
            margin-bottom: 55px;
          }

          > .container {
            flex-basis: 48%;

            @include mq('x-large') {
              max-width: 420px;
            }
          }

          img {
            margin-bottom: 10px;
          }

          h4 {
            margin: var(--emu-common-spacing-none);
            font-size: var(--emu-common-font-sizes-narrow-medium);
            padding: var(--emu-common-spacing-small);
            color: var(--emu-common-colors-white);
            font-family: var(--emu-semantic-font-families-poppins-semi-bold);
            font-weight: normal;
            line-height: 1.4;

            @include mq('medium') {
              font-size: var(--emu-common-font-sizes-wide-medium);
            }
          }

          &-inner-logo {
            margin-bottom: 40px;

            img {
              margin: auto;
            }
          }
        }

        &__spl-container {
          @include mq('medium') {
            margin-left: auto;
            margin-right: auto;
            max-width: 885px; // value from live site
          }

          &-content {
            padding-top: 22px;
            padding-right: 30px;
            padding-bottom: 22px;
            padding-left: 30px;
            background-image: linear-gradient(
              to right,
              var(--emu-semantic-colors-secondary-grey-300),
              var(--emu-semantic-colors-secondary-grey-200)
            );
          }

          h4 {
            margin: var(--emu-common-spacing-none);
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
            line-height: 1.4;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            background-color: var(--emu-semantic-colors-primary-purple-500);
            color: var(--emu-common-colors-white);
            font-family: var(--emu-semantic-font-families-poppins-semi-bold);
            font-weight: var(--emu-semantic-font-weight-500);

            @include mq('medium') {
              padding-left: 70px;
              padding-top: 20px;
              padding-bottom: 20px;
              font-size: var(--emu-semantic-font-sizes-wide-xl);
              line-height: 1.1;
            }

            sup {
              font-size: 100%;
              display: inline-block;
              vertical-align: middle;
              top: -1px;
              left: 1px;

              @include mq('medium') {
                left: 0;
              }
            }
          }

          h5 {
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
            font-family: var(--emu-semantic-font-families-poppins-bold);
            margin-bottom: 10px;
            margin-top: var(--emu-common-spacing-none);
            line-height: 1.4;
            color: var(--emu-common-colors-black);

            sup {
              font-size: 60%;
              display: inline-block;
              vertical-align: middle;
              top: -1px;
            }

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-xl);
            }
          }

          p {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: 10px;
            font-size: var(--emu-semantic-font-sizes-narrow-large);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-xl);
            }

            .emphasis {
              @include mq('medium') {
                display: block;
              }
            }
          }

          img {
            padding-top: 20px;
            padding-bottom: 20px;

            @include mq('medium') {
              padding-bottom: 40px;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        &__inner-tabs {
          background-image: linear-gradient(
            30deg,
            var(--emu-semantic-colors-secondary-grey-300),
            var(--emu-semantic-colors-secondary-grey-200)
          );

          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            margin-left: 15px;
            margin-right: 15px;
          }

          @include mq('x-large') {
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
            max-width: 100%;
            background: var(--emu-common-colors-transparent);
          }

          .aaaem-tabs {
            &__tablist {
              margin-bottom: var(--emu-common-spacing-none);

              @include mq('tablet') {
                max-width: 885px;
                margin-left: auto;
                margin-right: auto;
              }
            }

            &__tabpanels {
              @include mq('tablet') {
                max-width: 885px;
                margin-left: auto;
                margin-right: auto;
              }

              @include mq('x-large') {
                background-image: linear-gradient(
                  30deg,
                  var(--emu-semantic-colors-secondary-grey-300),
                  var(--emu-semantic-colors-secondary-grey-200)
                );
              }
            }

            &__tabpanel {
              padding-top: var(--emu-common-spacing-small);
              padding-right: 20px;
              padding-bottom: 25px;
              padding-left: 20px;

              @include mq('medium') {
                padding-right: var(--emu-common-spacing-none);
                padding-bottom: 35px;
                padding-left: 15px;
              }

              @include mq('x-large') {
                padding-bottom: 40px;
              }
            }
          }

          p {
            margin-bottom: 18px;
            font-size: 12px;
            text-align: center;
            margin-top: var(--emu-common-spacing-none);
            line-height: 1.4;
            margin-right: 10px;
            margin-left: 10px;
            color: var(--emu-common-colors-black);

            @include mq('medium') {
              margin-top: 15px;
              margin-bottom: 20px;
              font-size: var(--emu-semantic-font-sizes-wide-medium);
              text-align: left;
            }
          }

          img {
            margin-left: auto;
            margin-right: auto;
          }

          sup {
            font-size: 60%;
            vertical-align: middle;
            top: -2px;
          }

          b {
            font-family: var(--emu-semantic-font-families-poppins-bold);
            font-weight: var(--emu-semantic-font-weight-700);
          }

          br {
            @include mq('small') {
              display: none;
            }
          }
        }

        &-midface__text {
          b {
            color: var(--emu-semantic-colors-primary-purple-500);
          }
        }
      }
    }

    &__tab-content-container--chin {
      @include mq('medium') {
        max-width: 885px; // value from live site
        margin-left: auto;
        margin-right: auto;
      }

      @include mq('tablet') {
        padding-bottom: 30px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      .sub-title {
        margin-right: -10px;
        margin-left: -10px;
        color: var(--emu-semantic-colors-secondary-dark-grey-500);

        br {
          display: block;

          @include mq('small') {
            display: none;
          }
        }

        .emphasis {
          @include mq('medium') {
            display: block;
          }
        }
      }

      .skewed-container {
        &__wrapper {
          margin-top: 45px;
          margin-bottom: 15px;

          @include mq('medium') {
            margin-top: 100px;
            margin-left: 20px;
            margin-bottom: 10px;
          }

          @include mq('x-large') {
            max-width: 90%;
            margin-left: var(--emu-common-spacing-none);
          }

          @include mq('x-large-plus') {
            max-width: 870px; // value from live site
          }

          .skewed-container {
            &__main {
              &-inner {
                > .text {
                  padding-top: 35px;
                  padding-right: 20px;
                  padding-bottom: 25px;

                  @include mq('small') {
                    padding-right: 50px;
                  }

                  @include mq('medium') {
                    padding-right: 20px;
                  }

                  @include mq('x-large') {
                    padding-left: 20px;
                    padding-right: var(--emu-common-spacing-none);
                    padding-top: 25px;
                  }
                }
              }
            }
          }
        }
      }

      .disclaimer {
        p {
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('medium') {
            margin-left: 20px;
            padding-left: 35px;
          }

          @include mq('tablet') {
            margin-left: var(--emu-common-spacing-none);
          }

          @include mq('x-large') {
            padding-left: var(--emu-common-spacing-none);
          }
        }
      }

      .treatment-areas {
        &__before-after {
          margin-top: 25px;
          margin-bottom: 20px;

          @include mq('medium') {
            margin-top: 47px;
            margin-bottom: 30px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }
        }

        &__moldability-container {
          margin-top: 35px;
          padding-left: var(--emu-common-spacing-xxs);
          padding-right: var(--emu-common-spacing-xxs);

          @include mq('medium') {
            margin-top: 100px;
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
          }

          > div {
            @include mq('medium') {
              flex-basis: 50%;
            }
          }

          img {
            margin-top: 20px;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            @include mq('medium') {
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);
            }
          }

          p {
            font-family: var(--emu-semantic-font-families-poppins-light);
            margin-bottom: 35px;
            margin-top: var(--emu-common-spacing-none);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              margin-bottom: 65px;
            }

            & + p {
              margin-bottom: 10px;
            }

            .emphasis {
              @include mq('large') {
                display: block;
              }
            }

            sup {
              font-size: 100%;
              display: inline-block;
              vertical-align: middle;
              top: -1px;
              left: 1px;

              @include mq('medium') {
                left: 0;
              }
            }

            i {
              font-style: normal;

              sup {
                font-size: 60%;
                top: -3px;
                display: inline-block;
                vertical-align: middle;

                @include mq('medium') {
                  top: -2px;
                }
              }
            }
          }
        }

        &__cta {
          margin-bottom: 10px;
          margin-top: 18px;

          @include mq('medium') {
            margin-top: 40px;
          }
        }

        &__container {
          @include mq('x-large') {
            max-width: 885px; // value from live site
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    &__tab-content-container--jawline {
      .sub-title {
        color: var(--emu-semantic-colors-secondary-dark-grey-500);
        margin-bottom: 20px;

        h3 {
          @include mq('medium') {
            font-size: 26px;
          }

          @include mq('tablet') {
            font-size: 30px;
          }
        }

        .emphasis {
          @include mq('medium') {
            display: block;
          }
        }
      }

      .skewed-container {
        &__wrapper--treatment-areas-jawline-percent {
          margin-top: 60px;

          @include mq('medium') {
            width: 80%;
            max-width: 875px;
          }

          @include mq('x-large') {
            width: 100%;
          }

          .skewed-container {
            &__wrapper-inner {
              @include mq('x-large') {
                margin-left: -5px;
                margin-right: -5px;
              }

              @include mq('x-large-plus') {
                margin-left: var(--emu-common-spacing-none);
                margin-right: var(--emu-common-spacing-none);
              }
            }

            &__main {
              background-image: linear-gradient(
                -154deg,
                var(--emu-semantic-colors-secondary-grey-300),
                var(--emu-semantic-colors-secondary-grey-200)
              );
              padding-right: 50px;
              padding-left: 50px;
              width: 100%;
              max-width: none;

              @include mq('x-large') {
                padding-left: var(--emu-common-spacing-none);
                padding-right: var(--emu-common-spacing-none);
              }

              &-inner {
                @include mq('x-large') {
                  justify-content: center;
                }

                > .text {
                  padding: var(--emu-common-spacing-none);
                }

                .cmp-text {
                  display: flex;
                  padding-bottom: 20px;
                  padding-top: 20px;
                  align-items: center;
                  max-width: 945px;
                  justify-content: center;
                  margin: auto;
                  flex-direction: column;

                  @include mq('medium') {
                    flex-direction: row;
                    padding-top: 35px;
                    padding-bottom: 25px;
                    align-items: flex-start;
                  }

                  @include mq('x-large') {
                    max-width: 760px; // to match the live site.
                    .emphasis {
                      display: block;
                    }
                  }
                }
              }

              h2 {
                flex: 0 0 42%;
                font-size: 80px;
                margin: var(--emu-common-spacing-none);
                font-weight: var(--emu-semantic-font-weight-500);
                line-height: 1.1;
                text-align: center;
                white-space: nowrap;

                @include mq('x-large') {
                  font-size: 85px;
                  flex: auto;
                }

                sup {
                  font-size: 30px;
                  vertical-align: super;
                  display: inline-block;
                  top: -14px;
                  left: -14px;
                }
              }

              h5 {
                font-weight: var(--emu-semantic-font-weight-500);
                line-height: 1.4;
                text-align: center;
                margin: var(--emu-common-spacing-none);
                font-size: 20px;

                @include mq('medium') {
                  text-align: left;
                }

                .emphasis {
                  @include mq('x-large') {
                    display: block;
                  }
                }
              }
            }
          }
        }

        &__wrapper--treatment-areas-jawline {
          margin-top: 45px;

          @include mq('medium') {
            margin-top: 95px;
            width: 85%;
            max-width: 842px;
          }

          @include mq('x-large') {
            width: 100%;
            margin-left: var(--emu-common-spacing-none);
          }

          .skewed-container {
            &__main {
              width: 100%;

              &-inner {
                > .image {
                  flex: 0 0 auto;

                  @include mq('medium') {
                    flex: 0 0 45%;
                  }

                  @include mq('x-large') {
                    flex: 0 0 auto;
                  }
                }

                > .text {
                  padding: var(--emu-common-spacing-none);
                }

                h3 {
                  padding-top: 5px;
                  padding-bottom: 5px;
                  margin: 5px;
                  line-height: 1.4;
                  font-size: var(--emu-semantic-font-sizes-narrow-medium);
                  font-weight: var(--emu-semantic-font-weight-500);

                  @include mq('medium') {
                    font-size: 22px;
                  }

                  @include mq('x-large') {
                    padding-top: 45px;
                    padding-bottom: 35px;
                    padding-left: 35px;
                    margin: var(--emu-common-spacing-none);
                  }

                  br {
                    display: none;

                    @include mq('x-large') {
                      display: block;
                    }
                  }

                  sup {
                    font-size: 48%;
                    display: inline-block;
                    vertical-align: super;
                    top: 0;
                  }

                  .emphasis {
                    sup {
                      top: -1px;
                      left: 1px;

                      @include mq('medium') {
                        left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .treatment-areas {
        &__jawline-percent-grid {
          padding-bottom: 30px;
          margin-left: -20px;
          margin-right: -20px;
          margin-top: -10px;

          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 20px;
          }

          @include mq('x-large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          &-top,
          &-bottom {
            display: flex;
            flex-direction: column;
            max-width: 885px; // to match live
            padding-top: 30px;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            @include mq('medium') {
              flex-direction: row;
              > div.container {
                display: flex;
                flex: 1;
              }
            }

            &-left,
            &-right {
              max-width: 458px; // to match live site
              width: 82%;
              margin-bottom: 50px;
              position: relative;
              margin-left: auto;
              margin-right: auto;

              @include mq('medium') {
                max-width: 100%;
                width: 100%;
                margin-bottom: 25px;
              }

              &::after {
                position: absolute;
                content: ' ';
                right: 0;
                left: 0;
                top: 100%;
                bottom: -45px;
                height: 1px;
                width: 98%;
                margin: auto;
                background-color: var(
                  --emu-semantic-colors-secondary-dark-grey-100
                );

                @include mq('medium') {
                  width: 1px;
                  height: 270px;
                  top: -40px;
                  left: 100%;
                }
              }

              .aaaem-image {
                margin-bottom: 15px;

                img {
                  margin-left: auto;
                  margin-right: auto;
                }
              }

              .aaaem-text {
                max-width: 82%;

                @include mq('medium') {
                  max-width: 100%;
                }

                p {
                  margin-bottom: 20px;
                  font-size: var(--emu-semantic-font-sizes-narrow-xl);
                  line-height: 1.4;
                  margin: var(--emu-common-spacing-none);
                  color: var(--emu-semantic-colors-secondary-dark-grey-600);

                  @include mq('medium') {
                    font-size: var(--emu-semantic-font-sizes-wide-xl);
                  }

                  br {
                    display: none;
                    @include mq('x-large') {
                      display: block;
                    }
                  }

                  sup {
                    top: 4px;
                    vertical-align: super;
                  }

                  b {
                    font-family: var(--emu-semantic-font-families-poppins-bold);
                  }
                }
              }
            }
          }

          &-top {
            @include mq('medium') {
              padding-top: 25px;
              padding-bottom: 35px;
              position: relative;

              &::after {
                content: '';
                width: 100%;
                left: 0;
                position: absolute;
                height: 1px;
                top: 100%;
                background-color: var(
                  --emu-semantic-colors-secondary-dark-grey-100
                );
              }
            }

            &-right {
              margin-bottom: var(--emu-common-spacing-none);

              &::after {
                @include mq('medium') {
                  display: none;
                }
              }
            }
          }

          &-bottom {
            padding-bottom: var(--emu-common-spacing-none);

            @include mq('medium') {
              margin-top: 12px;
            }

            &-right {
              margin-bottom: var(--emu-common-spacing-none);

              &::after {
                display: none;
              }
            }
          }
        }

        &__jawline-tab-title {
          margin-bottom: 30px;

          br {
            display: block;
          }

          h3 {
            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-xxl);
            }

            @include mq('tablet') {
              font-size: 30px;
            }
          }
        }
      }
    }

    &__tab-content-container--lines {
      padding-bottom: 20px;

      .main-title {
        sup {
          font-size: 90%;
          top: 10px;
          vertical-align: super;

          @include mq('medium') {
            top: 6px;
            left: 2px;
          }

          @include mq('tablet') {
            top: 14px;
          }
        }
      }

      .sub-title {
        br {
          display: block;
        }

        h3 {
          margin-bottom: 20px;

          @include mq('medium') {
            margin-bottom: 40px;
          }
        }
      }

      .treatment-areas {
        &__lines-gel-container {
          @include mq('x-large') {
            &.aaaem-container {
              max-width: none;
            }
          }

          p {
            margin-top: 5px;
            margin-bottom: 30px;
          }

          sup {
            font-size: 100%;
            vertical-align: middle;
            top: -1px;
            left: 1px;

            @include mq('medium') {
              left: 0;
            }
          }

          .emphasis {
            sup {
              font-size: 60%;
              top: -2px;
            }
          }

          img {
            margin-left: auto;
            margin-right: auto;
          }

          &-inner {
            margin-top: 20px;
            margin-bottom: 20px;

            @include mq('medium') {
              margin-top: 40px;
              margin-bottom: 40px;
            }

            &--bottom {
              margin-top: 30px;

              @include mq('medium') {
                margin-top: 70px;
                margin-bottom: 60px;
              }
            }
          }

          .disclaimer {
            padding: var(--emu-common-spacing-none);
            margin: var(--emu-common-spacing-none);

            @include mq('x-large') {
              margin-left: -15px;
              margin-right: -15px;
              max-width: none;
            }

            p {
              padding-top: 15px;
              padding-right: var(--emu-common-spacing-none);
              padding-bottom: var(--emu-common-spacing-none);
              padding-left: var(--emu-common-spacing-none);
              margin-top: 10px;
              margin-right: var(--emu-common-spacing-none);
              margin-bottom: 30px;
              margin-left: auto;
              max-width: 885px; // value from live site

              b {
                font-family: var(--emu-semantic-font-families-poppins-bold);
              }
            }
          }
        }

        &__before-after {
          flex-direction: column;
          flex-wrap: wrap;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 25px;
          justify-content: center;

          @include mq('medium') {
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: 40px;
            padding-left: 15px;
            padding-right: 15px;
          }

          @include mq('x-large') {
            &.aaaem-container {
              max-width: 805px; // value from live site
              margin-left: auto;
              margin-right: auto;
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }
          }

          > .container {
            flex: 0 0 50%;
            max-width: none;
          }

          img {
            margin-bottom: var(--emu-common-spacing-none);
          }

          &-inner {
            background-color: var(--emu-common-colors-white);
            margin-top: 10px;
            padding-top: 20px;
            padding-right: 25px;
            padding-bottom: 20px;
            padding-left: 25px;
            margin-left: -20px;
            margin-right: -20px;

            @include mq('medium') {
              padding-top: 40px;
              padding-right: 60px;
              padding-bottom: 40px;
              padding-left: 60px;
              margin-top: var(--emu-common-spacing-none);
              margin-left: var(--emu-common-spacing-none);
              margin-right: 30px;
            }

            &--bottom {
              margin-top: 20px;
              @include mq('medium') {
                margin-top: var(--emu-common-spacing-none);
              }
            }
          }

          &-desc {
            margin-left: -20px;
            margin-right: -20px;

            p {
              margin-left: auto;
              margin-right: auto;
              max-width: 85%;
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              margin-bottom: 10px;
              margin-top: var(--emu-common-spacing-none);

              @include mq('medium') {
                font-size: var(--emu-semantic-font-sizes-wide-medium);
                max-width: 100%;
              }

              sup,
              .emphasis sup {
                font-size: 100%;
                vertical-align: middle;
                top: 1px;
                left: 1px;

                @include mq('medium') {
                  top: 0;
                  left: 0;
                }

                @include mq('tablet') {
                  top: -1px;
                }
              }

              b {
                font-family: var(--emu-semantic-font-families-poppins-bold);

                sup {
                  font-family: var(--emu-semantic-font-families-body);
                  font-size: 60%;
                  top: -2px;
                  vertical-align: middle;
                }
              }
            }

            .emphasis {
              @include mq('medium') {
                display: block;
              }
            }
          }
        }

        &__cta {
          margin-top: 65px;
          margin-bottom: 50px;

          @include mq('medium') {
            margin-top: 55px;
          }
        }

        &__skewed-holder--lines {
          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
            max-width: 885px; // value from live site
            margin-left: auto;
            margin-right: auto;
          }

          @include mq('x-large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          .disclaimer {
            margin: var(--emu-common-spacing-none);
            padding: var(--emu-common-spacing-none);
            margin-left: 20px;

            @include mq('medium') {
              margin-left: var(--emu-common-spacing-none);
            }

            p {
              margin: var(--emu-common-spacing-none);
              padding: var(--emu-common-spacing-none);
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: 3px;

              sup {
                font-size: 60%;
                top: -2px;
                vertical-align: middle;
              }
            }
          }

          > .container {
            margin-top: 30px;
            margin-bottom: 30px;

            @include mq('medium') {
              margin-top: 60px;
              margin-bottom: 20px;
            }
          }

          h4 {
            font-size: 20px;
            line-height: 1.1;
            font-weight: var(--emu-semantic-font-weight-500);
            color: var(--emu-semantic-colors-primary-purple-500);
            margin-top: 10px;
            margin-bottom: 10px;

            @include mq('medium') {
              font-size: 26px;
            }

            sup {
              font-size: 48%;
              vertical-align: super;
              top: -1px;
              left: 1px;

              @include mq('medium') {
                left: 0;
              }
            }
          }

          h5 {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            max-width: 85%;
            margin-left: auto;
            margin-right: auto;
            line-height: 1.4;
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            color: var(--emu-semantic-colors-primary-purple-500);

            @include mq('medium') {
              font-size: 20px;
              max-width: 100%;
            }

            @include mq('x-large') {
              margin-bottom: 10px;
            }

            .emphasis {
              @include mq('medium') {
                display: block;
              }
            }

            sup {
              font-size: 48%;
              display: inline-block;
              vertical-align: super;
              top: 0;

              @include mq('medium') {
                top: -1px;
              }
            }
          }

          ul {
            padding-top: 10px;
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: 10px;
            margin-left: 40px;
            padding-left: var(--emu-common-spacing-none);

            @include mq('medium') {
              padding-top: 5px;
              padding-bottom: 5px;
              margin-left: 100px;
            }
          }

          li {
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
            margin-bottom: 5px;
            line-height: 1.5;
            color: var(--emu-common-colors-black);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-xl);
              margin-bottom: var(--emu-common-spacing-none);
            }

            sup {
              font-size: 90%;
              display: inline-block;
              vertical-align: super;
              top: 5px;

              @include mq('medium') {
                top: 6px;
              }
            }

            .emphasis {
              sup {
                font-size: 48%;
                display: inline-block;
                vertical-align: super;
                top: 4px;
              }
            }
          }

          .skewed-container {
            &__wrapper {
              @include mq('x-large') {
                width: 90%;
              }

              @include mq('x-large-plus') {
                width: 100%;
                max-width: 870px; // value from live site
              }
            }

            &__main {
              &-inner {
                display: flex;
                > .text {
                  max-width: 80%;
                  padding-top: 20px;
                  padding-right: var(--emu-common-spacing-none);
                  padding-bottom: 25px;
                  padding-left: var(--emu-common-spacing-none);
                  margin-left: auto;
                  margin-right: auto;

                  @include mq('medium') {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__tab-content-container--lips {
      .main-title {
        sup {
          vertical-align: super;
          font-size: 90%;
          left: 2px;
          top: 10px;

          @include mq('medium') {
            top: 6px;
          }

          @include mq('tablet') {
            top: 14px;
          }
        }
      }

      .disclaimer {
        &--lips-skewed-container {
          p {
            margin-bottom: var(--emu-common-spacing-none);
            @include mq('medium') {
              margin-left: 20px;
            }

            @include mq('tablet') {
              margin-left: var(--emu-common-spacing-none);
            }
          }
        }

        &--lips {
          p {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: 3px;
            padding-left: var(--emu-common-spacing-none);

            .emphasis {
              sup {
                font-size: 60%;
                top: -2px;
              }
            }
          }

          u {
            text-decoration: none;
            display: none;

            @include mq('medium') {
              display: inline-block;
              margin-left: var(--emu-common-spacing-xxs);
            }
          }

          br {
            display: none;

            @include mq('medium') {
              display: block;
            }
          }
        }
      }

      .treatment-areas {
        &__skewed-holder--lips {
          @include mq('medium') {
            max-width: 885px;
            margin-left: auto;
            margin-right: auto;
          }

          @include mq('900px') {
            padding-left: 15px;
            padding-right: 15px;
          }

          @include mq('tablet') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          .skewed-container {
            &__wrapper {
              @include mq('medium') {
                margin-top: 50px;
              }

              @include mq('x-large') {
                max-width: 90%;
              }

              @include mq('x-large-plus') {
                max-width: 870px;
              }
            }

            &__main {
              &-inner {
                > .image {
                  @include mq('x-large') {
                    flex: initial;
                    min-width: 399px;
                  }
                }

                > .text {
                  padding: var(--emu-common-spacing-none);

                  @include mq('medium') {
                    padding-top: 34px;
                    padding-bottom: 34px;
                    padding-left: 40px;
                  }
                }

                h3 {
                  margin: 5px;
                  line-height: 1.32;

                  @include mq('medium') {
                    font-size: 22px;
                  }

                  @include mq('x-large') {
                    margin-top: 10px;
                    margin-right: var(--emu-common-spacing-none);
                    margin-bottom: 10px;
                    margin-left: var(--emu-common-spacing-none);
                  }

                  .emphasis {
                    @include mq('x-large') {
                      display: block;
                    }
                  }

                  i {
                    font-style: normal;

                    sup {
                      font-size: 48%;
                      top: 0;

                      @include mq('medium') {
                        top: 1px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &__lips-product-desc {
          margin-top: 18px;

          @include mq('medium') {
            margin-top: 40px;
          }

          &--bottom {
            margin-top: 60px;
          }

          .disclaimer {
            margin-left: -20px;
            margin-right: -20px;

            @include mq('medium') {
              max-width: 885px;
              margin-left: auto;
              margin-right: auto;
            }

            @include mq('900px') {
              padding-left: 15px;
              padding-right: 15px;
            }

            @include mq('tablet') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }

            p {
              margin-left: 25px;
              padding-left: var(--emu-common-spacing-none);
              margin-top: 20px;
              margin-bottom: var(--emu-common-spacing-none);

              @include mq('medium') {
                margin-left: 60px;
                font-size: 13px;
              }

              b {
                font-family: var(--emu-semantic-font-families-poppins-bold);
              }
            }
          }

          .treatment-areas__text {
            p {
              margin-top: 5px;
              margin-bottom: var(--emu-common-spacing-none);
            }

            .emphasis sup {
              font-size: 50%;
              top: -1px;
            }
          }

          img {
            margin-left: auto;
            margin-right: auto;
            margin-top: 18px;

            @include mq('medium') {
              margin-top: 40px;
            }
          }
        }

        &__lips-spl-container {
          margin-top: 50px;
          margin-bottom: 20px;

          @include mq('medium') {
            margin-top: 75px;
            margin-bottom: 35px;
          }

          img {
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
            margin-left: auto;
            margin-right: auto;

            @include mq('medium') {
              padding-top: 20px;
              padding-bottom: 40px;
            }
          }

          h4 {
            padding-top: var(--emu-common-spacing-xs);
            padding-right: 23px;
            padding-bottom: var(--emu-common-spacing-xs);
            padding-left: 23px;

            @include mq('medium') {
              padding-top: 18px;
              padding-right: 55px;
              padding-bottom: 18px;
              padding-left: 55px;
            }

            sup {
              font-size: 50%;
              top: -4px;
              left: -4px;
              vertical-align: middle;
            }
          }
        }

        &__lips-graph-desc {
          p {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: 10px;
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: 1.4;

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-xl);
            }

            b {
              color: var(--emu-semantic-colors-primary-purple-500);
            }

            i {
              font-style: none;

              sup {
                font-size: 60%;
                top: -2px;
                vertical-align: middle;
              }
            }
          }
        }

        &__lips-ha-diff {
          padding: 20px;
          margin-left: -20px;
          margin-right: -20px;

          @include mq('medium') {
            padding-top: 60px;
            padding-bottom: 95px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          img {
            margin-left: auto;
            margin-right: auto;
          }
        }

        &__cta {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 40px;

          @include mq('medium') {
            margin-bottom: 60px;
          }
        }
      }
    }

    &__tab-content-container--undereyes {
      padding-bottom: var(--emu-common-spacing-none);

      .sub-title {
        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        br {
          display: block;

          @include mq('small') {
            display: none;
          }
        }

        sup {
          top: 0;
          left: 0;

          @include mq('medium') {
            top: -1px;
          }
        }
      }

      .main-title {
        margin-left: -20px;
        margin-right: -20px;
      }

      .disclaimer {
        margin-left: -20px;
        margin-right: -20px;

        p {
          color: var(--emu-common-colors-black);
          margin-left: 25px;
          margin-top: 20px;
          padding-left: var(--emu-common-spacing-none);

          @include mq('medium') {
            margin-left: 60px;
            font-size: 13px;
          }

          b {
            font-family: var(--emu-semantic-font-families-poppins-bold);
          }
        }

        &--undereyes {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);

          p {
            margin-bottom: 3px;
            max-width: 85%;
            margin-left: 20px;
            margin-right: auto;
            margin-top: 10px;

            @include mq('medium') {
              padding-left: 35px;
              max-width: none;
              margin-right: var(--emu-common-spacing-none);
              font-size: 12px;
            }

            @include mq('tablet') {
              margin-left: var(--emu-common-spacing-none);
            }

            @include mq('x-large') {
              padding-left: var(--emu-common-spacing-none);
            }
          }
        }

        &--undereyes-last {
          p {
            padding-top: 30px;
            padding-bottom: 15px;
            max-width: 85%;
            margin-left: 20px;
            margin-bottom: 3px;
            margin-top: var(--emu-common-spacing-none);

            @include mq('medium') {
              padding-top: 70px;
              padding-bottom: 25px;
              margin-left: auto;
              max-width: 100%;
              font-size: 12px;
            }

            sup {
              font-size: 60%;
              top: -4px;
            }
          }
        }
      }

      img {
        margin-left: auto;
        margin-right: auto;
      }

      .treatment-areas {
        &__text {
          margin-left: -20px;
          margin-right: -20px;

          p {
            margin-bottom: 10px;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: 1.4;

            @include mq('medium') {
              max-width: 100%;
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: 10px;
              font-size: var(--emu-semantic-font-sizes-wide-xl);
            }

            .emphasis {
              @include mq('medium') {
                display: block;
              }
            }

            sup {
              font-size: 100%;
              vertical-align: middle;
              top: -1px;
              left: 1px;
            }

            i {
              font-style: normal;
              sup {
                font-size: 60%;
                top: -2px;
                left: 0;
              }
            }

            u {
              text-decoration: none;
              sup {
                font-size: 50%;
                top: -1px;
                left: 0;
              }
            }
          }
        }

        &__skewed-holder--undereyes {
          @include mq('medium') {
            max-width: 885px; // value from live site
            margin-left: auto;
            margin-right: auto;
          }

          // media query needed to match desgin
          @include mq('900px') {
            padding-left: 15px;
            padding-right: 15px;
          }

          @include mq('tablet') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          .skewed-container {
            &__wrapper {
              margin-top: 50px;

              @include mq('x-large') {
                max-width: 90%;
              }

              @include mq('x-large-plus') {
                max-width: 870px;
              }
            }

            &__main {
              &-inner {
                > .image {
                  img {
                    margin-left: var(--emu-common-spacing-none);
                  }

                  @include mq('x-large') {
                    flex: initial;
                    min-width: 360px; // value to match live site
                  }
                }

                > .text {
                  padding: var(--emu-common-spacing-none);

                  @include mq('medium') {
                    padding-top: 20px;
                    padding-bottom: 20px;
                  }
                }

                h3 {
                  font-size: 20px;
                  margin-top: 10px;
                  margin-bottom: 10px;

                  @include mq('medium') {
                    font-size: 24px;
                  }
                }

                h5 {
                  margin: 5px;
                  font-size: 12px;

                  @include mq('medium') {
                    font-size: 20px;
                  }

                  @include mq('x-large') {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-left: var(--emu-common-spacing-none);
                    margin-right: var(--emu-common-spacing-none);
                  }
                }
              }
            }
          }
        }

        &__cta {
          margin-top: 30px;

          @include mq('medium') {
            margin-top: 60px;
          }
        }
      }
    }
  }
}

// author styles
.treatment-areas__img-map-tabs,
.aaaem-tabs__tabpanels {
  @include aem-editor-view {
    position: static !important; // making sure that the content is authorable
    transform: none !important; // making sure that the content is authorable
  }
}
