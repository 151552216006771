.aaaem-tabs {
  @include mq('medium') {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 885px; // value from live site
    margin-left: auto;
    margin-right: auto;
  }

  @include mq('x-large') {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  &__tablist {
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;

    @include mq('medium') {
      flex-wrap: nowrap;
      gap: var(--emu-semantic-spacing-none);
      background-color: var(
        --emu-component-containers-tabs-navigation-item-color-background-default-light
      );
    }

    li {
      flex-basis: 48.5%; // value from live site

      @include mq('medium') {
        flex: 1;
      }

      &:first-child,
      &:last-child {
        a {
          @include mq('medium') {
            transform: skew(0deg);
            span {
              transform: skew(0deg);
            }
          }
        }
      }

      &:first-child {
        a {
          @include mq('medium') {
            clip-path: polygon(
              0px 0px,
              100% 0%,
              calc(100% - 32px) 100%,
              0% 100%
            );
          }
        }
      }

      &:last-child {
        @include mq('medium') {
          a {
            clip-path: polygon(10% 0px, 100% 0px, 100% 100%, 0px 100%);
            span {
              padding-left: 10px;
            }
          }
        }
      }

      &:nth-child(even) {
        margin-bottom: 10px;
        margin-right: 5px;
        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    a {
      text-align: center;
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: 1.4;
      font-family: var(--emu-semantic-font-families-poppins-semi-bold);
      display: block;

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-wide-medium);
        transform: skew(-30deg);
        padding-top: var(
          --emu-component-containers-tabs-navigation-item-padding-top-wide
        );
        padding-right: var(
          --emu-component-containers-tabs-navigation-item-padding-right-wide
        );
        padding-bottom: var(
          --emu-component-containers-tabs-navigation-item-padding-bottom-wide
        );
        padding-left: var(
          --emu-component-containers-tabs-navigation-item-padding-left-wide
        );
        background-color: var(--emu-common-colors-transparent);

        span {
          transform: skew(30deg);
          display: block;
        }
      }
    }
  }
}
