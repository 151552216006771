[data-component='text'],
[data-component='title'] {
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  font: var(--emu-semantic-typography-narrow-body-regular);

  p,
  li {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  sup {
    font-size: 48%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -1px;
  }

  b {
    font-weight: var(--emu-common-font-weight-regular);
  }
}
