.emu-image-map {
  width: 100%;

  img {
    width: 100%;
  }

  .emu-mapping-marker {
    border: var(--emu-common-border-width-thin) solid
      var(--emu-component-ingredients-image-map-buttons-color-icon-light);

    span {
      &::before {
        width: var(--emu-common-border-width-medium);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        height: var(--emu-common-border-width-medium);
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.active {
      background-color: var(
        --emu-component-ingredients-image-map-buttons-color-icon-light
      );

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: var(--emu-common-border-width-medium);
        background-color: var(
          --emu-component-ingredients-image-map-buttons-color-icon-light
        );
        width: 50vw;

        @include mq('small') {
          width: 65vw;
        }

        @include mq('tablet') {
          width: min(40vw, 420px);
        }
      }

      span {
        &::before,
        &::after {
          background-color: var(--emu-common-colors-black);
        }
      }
    }
  }
}
