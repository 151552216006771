
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(27, 80, 83, 0.9) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #CC0700 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-purple-100: #D8CBD4 !default;
$emu-semantic-colors-primary-purple-200: #997e9f !default;
$emu-semantic-colors-primary-purple-300: #6B4A76 !default;
$emu-semantic-colors-primary-purple-400: #401256 !default;
$emu-semantic-colors-primary-purple-500: #3D1152 !default;
$emu-semantic-colors-primary-purple-600: #3d1a52 !default;
$emu-semantic-colors-primary-pink-100: #A81B8D !default;
$emu-semantic-colors-primary-green-100: #115E67 !default;
$emu-semantic-colors-primary-green-200: #1B5053 !default;
$emu-semantic-colors-primary-green-300: #2d575c !default;
$emu-semantic-colors-secondary-grey-100: #ebebeb !default;
$emu-semantic-colors-secondary-grey-200: #F7F1EE !default;
$emu-semantic-colors-secondary-grey-300: #DCC4BA !default;
$emu-semantic-colors-secondary-grey-400: #d6d6d6 !default;
$emu-semantic-colors-secondary-grey-500: #b0b0b0 !default;
$emu-semantic-colors-secondary-dark-grey-100: #979797 !default;
$emu-semantic-colors-secondary-dark-grey-200: #7A7A7A !default;
$emu-semantic-colors-secondary-dark-grey-300: #6c6c6c !default;
$emu-semantic-colors-secondary-dark-grey-400: #626262 !default;
$emu-semantic-colors-secondary-dark-grey-500: #383838 !default;
$emu-semantic-colors-secondary-dark-grey-600: #333333 !default;
$emu-semantic-colors-secondary-dark-grey-700: #303030 !default;
$emu-semantic-colors-secondary-blue-100: #0054be !default;
$emu-semantic-colors-secondary-blue-200: #23527c !default;
$emu-semantic-colors-secondary-orange-100: #FF8C00 !default;
$emu-semantic-font-weight-400: 400 !default;
$emu-semantic-font-weight-500: 500 !default;
$emu-semantic-font-weight-600: 600 !default;
$emu-semantic-font-weight-700: 700 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: 'Poppins-Regular', sans-serif !default;
$emu-semantic-font-families-heading: 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-font-families-mono: 'Oswald-Regular',sans-serif !default;
$emu-semantic-font-families-poppins: 'Poppins-Regular',sans-serif !default;
$emu-semantic-font-families-poppins-light: 'Poppins-Light',sans-serif !default;
$emu-semantic-font-families-poppins-semi-bold: 'Poppins-SemiBold',sans-serif !default;
$emu-semantic-font-families-poppins-bold: 'Poppins-Bold',sans-serif !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 0px !default;
$emu-semantic-spacing-vertical: 0px !default;
$emu-semantic-spacing-one-line-height-wide: 0px !default;
$emu-semantic-spacing-one-line-height-narrow: 0px !default;
$emu-semantic-font-sizes-narrow-medium: 14px !default;
$emu-semantic-font-sizes-narrow-large: 14px !default;
$emu-semantic-font-sizes-narrow-xl: 16px !default;
$emu-semantic-font-sizes-narrow-xxl: 18px !default;
$emu-semantic-font-sizes-narrow-xxxl: 30px !default;
$emu-semantic-font-sizes-wide-medium: 18px !default;
$emu-semantic-font-sizes-wide-large: 20px !default;
$emu-semantic-font-sizes-wide-xl: 18px !default;
$emu-semantic-font-sizes-wide-xxl: 25px !default;
$emu-semantic-font-sizes-wide-xxxl: 35px !default;
$emu-semantic-line-heights-narrow-large: 18px !default;
$emu-semantic-line-heights-narrow-medium: 15px !default;
$emu-semantic-line-heights-narrow-xl: 22px !default;
$emu-semantic-line-heights-narrow-xxl: 25px !default;
$emu-semantic-line-heights-narrow-xxxl: 36px !default;
$emu-semantic-line-heights-wide-large: 25px !default;
$emu-semantic-line-heights-wide-medium: 20px !default;
$emu-semantic-line-heights-wide-xl: 25px !default;
$emu-semantic-line-heights-wide-xxl: 35px !default;
$emu-semantic-line-heights-wide-xxxl: 42px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 480px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-tablet: 992px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-x-large: 1200px !default;
$emu-semantic-sizing-breakpoints-x-large-plus: 1280px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-one-line-height-wide: 20px !default;
$emu-semantic-sizing-one-line-height-narrow: 15px !default;
$emu-semantic-typography-narrow-headings-xxxl: 400 30px/36px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 400 18px/25px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 400 16px/22px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 400 14px/18px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 700 14px/15px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 13/18 'Poppins-Regular', sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 400 35px/42px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 400 25px/35px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 400 18px/25px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-wide-headings-large: 400 20px/25px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 700 18px/20px 'Oswald-MediumItalic',sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 16/22 'Poppins-Regular', sans-serif !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'purple': (
          '100': $emu-semantic-colors-primary-purple-100,
          '200': $emu-semantic-colors-primary-purple-200,
          '300': $emu-semantic-colors-primary-purple-300,
          '400': $emu-semantic-colors-primary-purple-400,
          '500': $emu-semantic-colors-primary-purple-500,
          '600': $emu-semantic-colors-primary-purple-600
        ),
        'pink': (
          '100': $emu-semantic-colors-primary-pink-100
        ),
        'green': (
          '100': $emu-semantic-colors-primary-green-100,
          '200': $emu-semantic-colors-primary-green-200,
          '300': $emu-semantic-colors-primary-green-300
        )
      ),
      'secondary': (
        'grey': (
          '100': $emu-semantic-colors-secondary-grey-100,
          '200': $emu-semantic-colors-secondary-grey-200,
          '300': $emu-semantic-colors-secondary-grey-300,
          '400': $emu-semantic-colors-secondary-grey-400,
          '500': $emu-semantic-colors-secondary-grey-500
        ),
        'darkGrey': (
          '100': $emu-semantic-colors-secondary-dark-grey-100,
          '200': $emu-semantic-colors-secondary-dark-grey-200,
          '300': $emu-semantic-colors-secondary-dark-grey-300,
          '400': $emu-semantic-colors-secondary-dark-grey-400,
          '500': $emu-semantic-colors-secondary-dark-grey-500,
          '600': $emu-semantic-colors-secondary-dark-grey-600,
          '700': $emu-semantic-colors-secondary-dark-grey-700
        ),
        'blue': (
          '100': $emu-semantic-colors-secondary-blue-100,
          '200': $emu-semantic-colors-secondary-blue-200
        ),
        'orange': (
          '100': $emu-semantic-colors-secondary-orange-100
        )
      )
    ),
    'fontWeight': (
      '400': $emu-semantic-font-weight-400,
      '500': $emu-semantic-font-weight-500,
      '600': $emu-semantic-font-weight-600,
      '700': $emu-semantic-font-weight-700,
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono,
      'poppins': $emu-semantic-font-families-poppins,
      'poppinsLight': $emu-semantic-font-families-poppins-light,
      'poppinsSemiBold': $emu-semantic-font-families-poppins-semi-bold,
      'poppinsBold': $emu-semantic-font-families-poppins-bold
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'large': $emu-semantic-line-heights-narrow-large,
        'medium': $emu-semantic-line-heights-narrow-medium,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'tablet': $emu-semantic-sizing-breakpoints-tablet,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'x-large-plus': $emu-semantic-sizing-breakpoints-x-large-plus,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
