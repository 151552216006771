#collections-section {
  margin-top: 40px; //To match the lzive site in mobile device

  @include mq('medium') {
    margin-top: 55px; // To match the live site in table and larger screen
  }

  .sub-title {
    &--video-title {
      .emu-title__text {
        margin-bottom: 15px;
        margin-top: 20px;

        @include mq('medium') {
          margin-bottom: 35px;
        }
      }
    }
  }

  .page-section--collection {
    margin-bottom: 55px;
  }

  .collections-section {
    &__desc {
      h5 {
        margin-bottom: 10px;
        margin-top: 10px;
        font-family: var(--emu-semantic-font-families-body);
        font-weight: var(--emu-semantic-font-weight-500);
        line-height: 1.4;
        color: var(--emu-common-colors-black);
        font-size: var(--emu-semantic-font-sizes-narrow-xl);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-large);
        }

        br {
          @include mq('small') {
            display: none;
          }
        }
      }
    }

    &__product-logo {
      text-align: center;

      img {
        max-width: 100px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
        display: inline-block;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-xs);
        }

        @include mq('tablet') {
          margin-bottom: 10px;
        }
      }

      &--large {
        img {
          max-width: 125px;
        }
      }

      &--small {
        img {
          max-width: 90;
        }
      }

      &--medium {
        img {
          max-width: 112px;
        }
      }
    }

    &__carousel {
      color: var(--emu-semantic-colors-secondary-dark-grey-600);
      font-family: var(--emu-semantic-font-families-body);
      line-height: var(--emu-semantic-line-heights-narrow-xl);
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      background-image: linear-gradient(
        to right,
        var(--emu-semantic-colors-secondary-grey-300),
        var(--emu-semantic-colors-secondary-grey-200)
      );
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 35px;
      margin-bottom: 35px;

      // Styling carousel for tablet and larger screen
      @include mq('medium') {
        padding-top: 62px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
        margin-top: 45px;
        margin-bottom: 85px;
      }

      &-main-image {
        img {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px;
          max-width: 100%; // to match live website

          @include mq('medium') {
            max-width: 60%;
            vertical-align: middle;
            display: inline-block;
          }

          @include mq('tablet') {
            max-width: 100%;
            margin-bottom: var(--emu-common-spacing-none);
          }
        }

        @include mq('medium') {
          text-align: center;
        }
      }

      &-text {
        h6 {
          margin-top: 10px;
          line-height: 1.1;
          font-family: var(--emu-semantic-font-families-poppins-semi-bold);
          color: var(--emu-semantic-colors-primary-purple-500);
          margin-bottom: 5px;
          font-size: var(--emu-semantic-font-sizes-narrow-medium);

          sup {
            font-size: 48%;
            vertical-align: super;
            top: 0px;

            @include mq('medium') {
              top: 1px;
            }
          }

          .emphasis {
            display: block;
          }

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
          }
        }

        p {
          line-height: 1.4;
          margin-bottom: 10px;
          margin-top: var(--emu-common-spacing-none);
          color: var(--emu-common-colors-black);
          font-size: var(--emu-common-font-sizes-narrow-medium);

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-wide-medium);
          }
        }
      }

      .tns-inner {
        @include mq('385px') {
          // to match live site
          margin-left: 10px;
          margin-right: 10px;
        }
        @include mq('medium') {
          margin: var(--emu-common-spacing-none);
        }
      }

      .aaaem-carousel {
        &__action {
          background-color: var(--emu-common-colors-transparent);
          border: none;
          top: 30%;
          transform: translateY(-5px);

          svg {
            width: 50px;
            height: 50px;
            display: block;
            margin-left: -4px;
            margin-right: -4px;
            fill: var(--emu-semantic-colors-primary-purple-500);
          }
        }

        &__content {
          @include mq('medium') {
            display: flex; // to match live site
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }

        &__item {
          &-container {
            margin-left: 20px;
            margin-right: 20px;

            @include mq('440px') {
              margin-left: var(--emu-common-spacing-none);
              margin-right: var(--emu-common-spacing-none);
            }
          }

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
            background-color: var(--emu-common-colors-transparent);
            flex: 0 0 30%; //to match live website
            margin-bottom: 20px;
          }

          @include mq('tablet') {
            margin-bottom: var(--emu-common-spacing-none);
            flex: 0 0 16.5%;
          }

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }

  .dermal-section {
    background-color: var(--emu-semantic-colors-secondary-grey-200);
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

    @include mq('medium') {
      margin-top: 100px;
      padding-top: 90px;
      padding-bottom: 30px;
    }

    .main-title {
      h2 {
        sup {
          top: 0;

          @include mq('medium') {
            top: -1px;
          }
        }
      }
    }

    &__grid {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;

      @include mq('medium') {
        margin-top: 50px;
        margin-bottom: 40px;
        gap: 0;
      }

      > .container {
        flex: 0 38%; // value from live site
        background-color: var(--emu-common-colors-transparent);

        &:last-child {
          margin-top: 30px;

          @include mq('medium') {
            margin-top: 60px;
            margin-right: var(--emu-common-spacing-none);
          }
        }

        &:nth-last-child(2) {
          @include mq('medium') {
            margin-top: 60px;
          }
        }

        @include mq('medium') {
          flex: 0 0 20%; // value from live site
          margin-right: 60px;
        }
      }

      &-item {
        p {
          font-size: 13px;
          line-height: 1.4;
          margin-top: 10px;
          margin-bottom: 15px;
          color: var(--emu-common-colors-black);
          font-family: var(--emu-semantic-font-families-poppins-semi-bold);

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-wide-medium);
          }

          @include mq('x-large') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
          }

          sup {
            top: 1px;
            font-size: 48%;
            display: inline-block;
            vertical-align: super;
          }
        }
      }
    }

    &__disclaimer {
      padding-left: 15px;

      @include mq('medium') {
        max-width: 40%;
        padding-left: 30px;
        margin-left: auto;
      }

      @include mq('x-large') {
        max-width: 35%;
      }

      @include mq('x-large-plus') {
        max-width: 28%;
      }
    }
  }

  .skewed-outer {
    background-image: linear-gradient(
      130deg,
      var(--emu-semantic-colors-secondary-grey-300),
      var(--emu-semantic-colors-secondary-grey-200)
    );

    @include mq('medium') {
      transform: skew(-19deg);
    }
  }

  .offset-container--only-juvederm {
    @include mq('tablet') {
      margin-left: auto;
      margin-top: auto;
      max-width: 100%;
    }
  }

  .skewed-container__wrapper {
    &--jaw-inspiring {
      .skewed-container {
        &__wrapper-inner {
          margin-top: 30px;
          margin-bottom: 20px;

          @include mq('medium') {
            margin-bottom: 150px;
            margin-top: 130px;
          }
        }

        &__main-inner {
          display: flex;
          align-items: center;
          position: relative;

          > .container {
            flex-basis: 50%;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include mq('x-large') {
              flex-basis: 60%;
            }
          }

          > .button {
            position: static;
            a {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }
          }

          h3 {
            font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
            line-height: 1.1;
            color: var(--emu-semantic-colors-primary-green-100);
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            font-family: var(--emu-semantic-font-families-heading);

            @include mq('small') {
              font-size: 33px;
            }

            @include mq('medium') {
              font-size: 40px;
            }

            @include mq('tablet') {
              font-size: 55px;
            }
          }

          h5 {
            font-size: 13px;
            line-height: 1.25;
            color: var(--emu-semantic-colors-primary-green-100);
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            font-weight: var(--emu-semantic-font-weight-500);

            @include mq('small') {
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
            }

            @include mq('medium') {
              font-size: var(--emu-common-font-sizes-wide-medium);
            }

            @include mq('tablet') {
              font-size: 24px;
            }

            sup {
              font-size: 48%;
              display: inline-block;
              vertical-align: super;
              top: 0;

              @include mq('medium') {
                top: -1px;
              }
            }
          }

          .product-img {
            img {
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }

    &--only-juvederm {
      @include mq('medium') {
        max-width: 77%;
      }

      @include mq('tablet') {
        max-width: 100%;
      }

      .skewed-container {
        &__wrapper-inner {
          position: relative;
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-secondary-dark-grey-100);
          padding-top: 40px;
          padding-bottom: 30px;

          @include mq('medium') {
            padding-top: 140px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            padding-bottom: 105px;
          }

          @include mq('tablet') {
            padding-left: 15px;
            padding-right: 15px;
          }
        }

        &__main {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('tablet') {
            max-width: 65%;
            margin-left: 250px;
            margin-right: auto;
          }

          @include mq('x-large') {
            max-width: 70%;
          }

          @include mq('x-large-plus') {
            max-width: 950px; // value from live site
          }

          &-inner {
            position: relative;

            @include mq('medium') {
              transform: skew(19deg);
            }

            @include mq('tablet') {
              transform: skew(24deg);
            }

            @include mq('x-large') {
              display: flex;
              justify-content: flex-end;
            }
          }

          img {
            position: absolute;
            max-width: 240px;
            left: -85px;
            bottom: -5px;

            @include mq('medium') {
              max-width: 350px;
              left: -90px;
              bottom: -10px;
            }

            @include mq('tablet') {
              max-width: 415px;
            }
          }

          .only-juvederm__text {
            max-width: 67%;
            margin-left: auto;

            padding-top: 10px;
            padding-right: 20px;
            padding-bottom: 10px;

            padding-left: 20px;

            @include mq('small') {
              max-width: 70%;
              padding-top: 40px;
              padding-bottom: 30px;
            }

            @include mq('medium') {
              max-width: 68%;
            }

            @include mq('tablet') {
              max-width: 70%;
              padding-top: 35px;
              padding-right: 40px;
              padding-bottom: 35px;
              padding-left: 120px;
            }

            @include mq('x-large') {
              max-width: 100%;
              padding-top: 45px;
              padding-bottom: 45px;
            }

            p {
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
              line-height: 1.25;
              font-weight: var(--emu-semantic-font-weight-500);
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);

              @include mq('medium') {
                font-size: var(--emu-semantic-font-sizes-wide-xl);
                text-align: center;
              }

              @include mq('tablet') {
                font-size: var(--emu-semantic-font-sizes-wide-large);
              }

              @include mq('x-large') {
                font-size: 24px;
              }
            }

            sup {
              font-size: 48%;
              display: inline-block;
              vertical-align: super;
              top: 0;

              @include mq('medium') {
                top: 1px;
              }
            }

            .emphasis {
              @include mq('x-large') {
                display: block;
              }

              br {
                display: none;
                @include mq('x-large') {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .tailored-gel {
    background-image: radial-gradient(
      var(--emu-semantic-colors-primary-purple-300),
      var(--emu-semantic-colors-primary-purple-500)
    );
    padding-bottom: var(--emu-common-spacing-small);
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;

    @include mq('medium') {
      padding-top: 90px;
      padding-bottom: 40px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    &__container {
      @include mq('x-large') {
        margin-left: 200px;
      }

      @include mq('x-large-plus') {
        margin-left: 165px;
      }
    }

    .sub-title h3 {
      font-size: var(--emu-common-font-sizes-wide-medium);
      margin-bottom: 20px;

      @include mq('medium') {
        font-size: 25px;
        margin-bottom: 80px;
      }

      @include mq('tablet') {
        font-size: 30px;
      }

      br {
        display: none;

        @include mq('x-large') {
          display: block;
        }
      }
    }

    .disclaimer {
      p {
        padding-left: var(--emu-common-spacing-none);
      }
    }

    &__top-text {
      p {
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
        margin-left: 10px;
        font-family: var(--emu-semantic-font-families-body);

        @include mq('medium') {
          margin-top: 20px;
          margin-bottom: 50px;
          font-size: var(--emu-semantic-font-sizes-wide-xl);
        }

        b {
          font-family: var(--emu-semantic-font-families-poppins-bold);
        }

        sup {
          font-size: 55%;
          display: inline-block;
          vertical-align: middle;
          top: -4px;
        }
      }
    }

    &__bottom-text {
      p {
        margin-top: 18px;
        margin-bottom: 10px;

        @include mq('medium') {
          font-size: 17px;
          margin-top: 40px;
        }

        sup {
          font-size: 80%;
          display: inline-block;
          vertical-align: super;
          top: 2px;

          @include mq('medium') {
            top: 4px;
          }
        }
      }
    }
  }
}

// author styles
.jaw-inspiring__anchor-text {
  @include aem-editor-view {
    position: static !important; // making sure that the content is authorable
  }
}
