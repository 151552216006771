@import "https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css";
@font-face {
  font-family: Oswald-MediumItalic;
  src: url("resources/fonts/Oswald-MediumItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Oswald-ExtraLight;
  src: url("resources/fonts/Oswald-ExtraLight.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Oswald-Light;
  src: url("resources/fonts/Oswald-Light.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Oswald-Regular;
  src: url("resources/fonts/Oswald-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Poppins-Regular;
  src: url("resources/fonts/Poppins-Regular.woff2") format("woff2"), url("resources/fonts/Poppins-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Poppins-Bold;
  src: url("resources/fonts/Poppins-Bold.woff2") format("woff2"), url("resources/fonts/Poppins-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Poppins-Light;
  src: url("resources/fonts/Poppins-Light.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("resources/fonts/Poppins-SemiBold.woff2") format("woff2"), url("resources/fonts/Poppins-SemiBold.woff") format("woff");
  font-display: swap;
}

body {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
  color: inherit;
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typograph-narrow);
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-100: #ff7570;
  --emu-common-colors-red-200: #ff615c;
  --emu-common-colors-red-300: #ff4e47;
  --emu-common-colors-red-400: #ff3a33;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #ff120a;
  --emu-common-colors-red-700: #f50800;
  --emu-common-colors-red-800: #e00700;
  --emu-common-colors-red-900: #cc0700;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-font-families-sans: Arial;
  --emu-common-font-families-serif: Times New Roman;
  --emu-common-font-families-mono: Courier;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: 200ms;
  --emu-common-other-time-transition-base: 400ms;
  --emu-common-other-time-transition-long: 600ms;
  --emu-common-other-time-transition-xl: 1000ms;
  --emu-common-other-time-duration-instant: 400ms;
  --emu-common-other-time-duration-short: 2000ms;
  --emu-common-other-time-duration-base: 4000ms;
  --emu-common-other-time-duration-long: 6000ms;
  --emu-common-other-time-delay-none: 0ms;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: 100ms;
  --emu-common-other-time-delay-long: 200ms;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-white);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-surface-modal-backdrop: #1b5053e6;
  --emu-semantic-colors-actions-primary-light: var(--emu-common-colors-red-500);
  --emu-semantic-colors-actions-primary-dark: var(--emu-common-colors-red-900);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-purple-100: #d8cbd4;
  --emu-semantic-colors-primary-purple-200: #997e9f;
  --emu-semantic-colors-primary-purple-300: #6b4a76;
  --emu-semantic-colors-primary-purple-400: #401256;
  --emu-semantic-colors-primary-purple-500: #3d1152;
  --emu-semantic-colors-primary-purple-600: #3d1a52;
  --emu-semantic-colors-primary-pink-100: #a81b8d;
  --emu-semantic-colors-primary-green-100: #115e67;
  --emu-semantic-colors-primary-green-200: #1b5053;
  --emu-semantic-colors-primary-green-300: #2d575c;
  --emu-semantic-colors-secondary-grey-100: #ebebeb;
  --emu-semantic-colors-secondary-grey-200: #f7f1ee;
  --emu-semantic-colors-secondary-grey-300: #dcc4ba;
  --emu-semantic-colors-secondary-grey-400: #d6d6d6;
  --emu-semantic-colors-secondary-grey-500: #b0b0b0;
  --emu-semantic-colors-secondary-dark-grey-100: #979797;
  --emu-semantic-colors-secondary-dark-grey-200: #7a7a7a;
  --emu-semantic-colors-secondary-dark-grey-300: #6c6c6c;
  --emu-semantic-colors-secondary-dark-grey-400: #626262;
  --emu-semantic-colors-secondary-dark-grey-500: #383838;
  --emu-semantic-colors-secondary-dark-grey-600: #333;
  --emu-semantic-colors-secondary-dark-grey-700: #303030;
  --emu-semantic-colors-secondary-blue-100: #0054be;
  --emu-semantic-colors-secondary-blue-200: #23527c;
  --emu-semantic-colors-secondary-orange-100: #ff8c00;
  --emu-semantic-font-weight-400: 400;
  --emu-semantic-font-weight-500: 500;
  --emu-semantic-font-weight-600: 600;
  --emu-semantic-font-weight-700: 700;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "Poppins-Regular", sans-serif;
  --emu-semantic-font-families-heading: "Oswald-MediumItalic", sans-serif;
  --emu-semantic-font-families-mono: "Oswald-Regular", sans-serif;
  --emu-semantic-font-families-poppins: "Poppins-Regular", sans-serif;
  --emu-semantic-font-families-poppins-light: "Poppins-Light", sans-serif;
  --emu-semantic-font-families-poppins-semi-bold: "Poppins-SemiBold", sans-serif;
  --emu-semantic-font-families-poppins-bold: "Poppins-Bold", sans-serif;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-common-spacing-none);
  --emu-semantic-font-sizes-narrow-medium: 14px;
  --emu-semantic-font-sizes-narrow-large: 14px;
  --emu-semantic-font-sizes-narrow-xl: 16px;
  --emu-semantic-font-sizes-narrow-xxl: 18px;
  --emu-semantic-font-sizes-narrow-xxxl: 30px;
  --emu-semantic-font-sizes-wide-medium: 18px;
  --emu-semantic-font-sizes-wide-large: 20px;
  --emu-semantic-font-sizes-wide-xl: 18px;
  --emu-semantic-font-sizes-wide-xxl: 25px;
  --emu-semantic-font-sizes-wide-xxxl: 35px;
  --emu-semantic-line-heights-narrow-large: 18px;
  --emu-semantic-line-heights-narrow-medium: 15px;
  --emu-semantic-line-heights-narrow-xl: 22px;
  --emu-semantic-line-heights-narrow-xxl: 25px;
  --emu-semantic-line-heights-narrow-xxxl: 36px;
  --emu-semantic-line-heights-wide-large: 25px;
  --emu-semantic-line-heights-wide-medium: 20px;
  --emu-semantic-line-heights-wide-xl: 25px;
  --emu-semantic-line-heights-wide-xxl: 35px;
  --emu-semantic-line-heights-wide-xxxl: 42px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 480px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 992px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-x-large-plus: 1280px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-sizing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) 13 / 18 var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) 16 / 22 var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-medium);
  --emu-component-lists-accordion-item-header-icon-size-height: 12px;
  --emu-component-lists-accordion-item-header-icon-size-width: 12px;
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-semantic-colors-primary-pink-100);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: 500;
  --emu-component-lists-navigation-text-padding-top-narrow: 19px;
  --emu-component-lists-navigation-text-padding-top-wide: 19px;
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: 19px;
  --emu-component-lists-navigation-text-padding-bottom-wide: 19px;
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 20px;
  --emu-component-ingredients-image-map-buttons-size-width: 20px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-semantic-colors-primary-green-200);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-primary-green-200);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-primary-green-200);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-primary-green-200);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-padding-top-narrow: 10px;
  --emu-component-actions-button-padding-top-wide: 10px;
  --emu-component-actions-button-padding-right-narrow: 22px;
  --emu-component-actions-button-padding-right-wide: 22px;
  --emu-component-actions-button-padding-bottom-narrow: 10px;
  --emu-component-actions-button-padding-bottom-wide: 10px;
  --emu-component-actions-button-padding-left-narrow: 22px;
  --emu-component-actions-button-padding-left-wide: 22px;
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-common-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-border-radius-primary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-left: var(--emu-common-border-radius-xxs);
  --emu-component-actions-button-border-radius-secondary-filled-top-right: var(--emu-common-border-radius-xxs);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-right: var(--emu-common-border-radius-xxs);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-left: var(--emu-common-border-radius-xxs);
  --emu-component-actions-button-border-radius-secondary-outline-top-left: var(--emu-common-border-radius-xxs);
  --emu-component-actions-button-border-radius-secondary-outline-top-right: var(--emu-common-border-radius-xxs);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-right: var(--emu-common-border-radius-xxs);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-left: var(--emu-common-border-radius-xxs);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-primary-green-200);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-primary-green-200);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-primary-green-200);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-primary-green-200);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-semantic-colors-primary-pink-100);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-primary-pink-100);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-primary-pink-100);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-primary-pink-100);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-secondary-orange-100);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-secondary-grey-100);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-secondary-grey-100);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-icon-size-height: 12px;
  --emu-component-actions-button-icon-size-width: 12px;
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-back-to-top-z-index: 500;
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-unset) px;
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-unset) px;
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: 20px;
  --emu-component-actions-back-to-top-position-top-narrow: 50%;
  --emu-component-actions-back-to-top-position-top-wide: 50%;
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: 10px;
  --emu-component-containers-tabs-navigation-item-padding-top-wide: 14px;
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: 10px;
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: 14px;
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-primary-pink-100);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: 5px;
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-secondary-grey-200);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 0;
  --emu-component-containers-isi-z-index: 400;
  --emu-component-containers-container-max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-z-index: 100;
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: 600;
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 92%;
  --emu-component-layers-modal-body-sizing-max-width: 1440px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: 500;
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-body);
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  max-width: 100%;
  width: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--root {
  margin-left: auto;
  margin-right: auto;
}

.container-large {
  max-width: 1015px;
}

.container-with-separator {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-secondary-dark-grey-100);
}

.container--extra-large {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.content--centered {
  text-align: center;
}

.bg--purple-500 {
  background-color: var(--emu-semantic-colors-primary-purple-500);
}

.bg--grey-200 {
  background-color: var(--emu-semantic-colors-secondary-grey-200);
}

.bg--pink-100 {
  background-color: var(--emu-semantic-colors-primary-pink-100);
}

[data-component="text"][class*="text--color-"] h1, [data-component="text"][class*="text--color-"] h2, [data-component="text"][class*="text--color-"] h3, [data-component="text"][class*="text--color-"] h4, [data-component="text"][class*="text--color-"] h5, [data-component="text"][class*="text--color-"] h6, [data-component="title"][class*="text--color-"] h1, [data-component="title"][class*="text--color-"] h2, [data-component="title"][class*="text--color-"] h3, [data-component="title"][class*="text--color-"] h4, [data-component="title"][class*="text--color-"] h5, [data-component="title"][class*="text--color-"] h6 {
  color: inherit;
}

[data-component="text"][class*="font-"] h1, [data-component="text"][class*="font-"] h2, [data-component="text"][class*="font-"] h3, [data-component="text"][class*="font-"] h4, [data-component="text"][class*="font-"] h5, [data-component="text"][class*="font-"] h6, [data-component="text"][class*="font-"] p, [data-component="text"][class*="font-"] li, [data-component="title"][class*="font-"] h1, [data-component="title"][class*="font-"] h2, [data-component="title"][class*="font-"] h3, [data-component="title"][class*="font-"] h4, [data-component="title"][class*="font-"] h5, [data-component="title"][class*="font-"] h6, [data-component="title"][class*="font-"] p, [data-component="title"][class*="font-"] li {
  font-family: inherit;
}

[data-component="text"].text--color-white, [data-component="title"].text--color-white {
  color: var(--emu-common-colors-white);
}

[data-component="text"].text--color-black, [data-component="title"].text--color-black {
  color: var(--emu-common-colors-black);
}

[data-component="text"].text--color-purple-500, [data-component="title"].text--color-purple-500 {
  color: var(--emu-semantic-colors-primary-purple-500);
}

[data-component="text"].text--color-pink-100, [data-component="title"].text--color-pink-100 {
  color: var(--emu-semantic-colors-primary-pink-100);
}

[data-component="text"].text--color-dark-grey-500, [data-component="title"].text--color-dark-grey-500 {
  color: var(--emu-semantic-colors-secondary-dark-grey-500);
}

[data-component="text"].text--color--dark-grey-600, [data-component="title"].text--color--dark-grey-600 {
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
}

[data-component="text"].text--color-green-100, [data-component="title"].text--color-green-100 {
  color: var(--emu-semantic-colors-primary-green-100);
}

[data-component="text"].font-oswald-medium-italic, [data-component="title"].font-oswald-medium-italic {
  font-family: var(--emu-semantic-font-families-heading);
}

[data-component="text"].font-oswald-regular, [data-component="title"].font-oswald-regular {
  font-family: var(--emu-semantic-font-families-mono);
}

[data-component="text"].font-poppins-regular, [data-component="title"].font-poppins-regular {
  font-family: var(--emu-semantic-font-families-body);
}

[data-component="text"].font-poppins-Light, [data-component="title"].font-poppins-Light {
  font-family: var(--emu-semantic-font-families-poppins-light);
}

[data-component="text"].font-poppins-semi-bold, [data-component="title"].font-poppins-semi-bold {
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
}

[data-component="text"].font-poppins-bold, [data-component="title"].font-poppins-bold {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

.main-container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 25px;
}

.main-container.main-container--small {
  max-width: 90%;
}

@media (min-width: 992px) {
  .main-container, .main-container.main-container--small {
    max-width: 75%;
    margin-left: 220px;
    padding-top: 70px;
    padding-bottom: 65px;
  }
}

@media (min-width: 1192px) {
  .main-container, .main-container.main-container--small {
    padding-top: 75px;
  }
}

@media (min-width: 1200px) {
  .main-container, .main-container.main-container--small {
    margin-left: 230px;
  }
}

@media (min-width: 1280px) {
  .main-container, .main-container.main-container--small {
    max-width: 1015px;
  }
}

.offset-container {
  max-width: 100%;
}

@media (min-width: 992px) {
  .offset-container {
    max-width: 75%;
    margin-left: 220px;
  }
}

@media (min-width: 1200px) {
  .offset-container {
    margin-left: 230px;
  }
}

@media (min-width: 1280px) {
  .offset-container {
    max-width: 1015px;
  }
}

@media (min-width: 768px) {
  .offset-container--variant-x {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .offset-container--variant-x {
    margin-left: 220px;
  }
}

@media (min-width: 1200px) {
  .offset-container--variant-x {
    max-width: 75%;
    margin-left: 230px;
  }
}

@media (min-width: 1280px) {
  .offset-container--variant-x {
    max-width: 1015px;
  }
}

.hide {
  display: none;
}

.main-title[data-component="title"] h1.emu-title__text, .main-title[data-component="title"] h2.emu-title__text, .main-title[data-component="title"] h3.emu-title__text, .main-title[data-component="title"] h4.emu-title__text, .main-title[data-component="title"] h5.emu-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: 1.2;
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-purple-500);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .main-title[data-component="title"] h1.emu-title__text, .main-title[data-component="title"] h2.emu-title__text, .main-title[data-component="title"] h3.emu-title__text, .main-title[data-component="title"] h4.emu-title__text, .main-title[data-component="title"] h5.emu-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
  }
}

@media (min-width: 992px) {
  .main-title[data-component="title"] h1.emu-title__text, .main-title[data-component="title"] h2.emu-title__text, .main-title[data-component="title"] h3.emu-title__text, .main-title[data-component="title"] h4.emu-title__text, .main-title[data-component="title"] h5.emu-title__text {
    font-size: 48px;
  }
}

@media (min-width: 480px) {
  .main-title[data-component="title"] br {
    display: none;
  }
}

.main-title[data-component="title"] sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: -2px;
  left: 1px;
}

@media (min-width: 768px) {
  .main-title[data-component="title"] sup {
    left: 0;
  }
}

.sub-title[data-component="title"] h1, .sub-title[data-component="title"] h1.emu-title__text, .sub-title[data-component="title"] h2, .sub-title[data-component="title"] h2.emu-title__text, .sub-title[data-component="title"] h3, .sub-title[data-component="title"] h3.emu-title__text, .sub-title[data-component="title"] h4, .sub-title[data-component="title"] h4.emu-title__text, .sub-title[data-component="title"] h5, .sub-title[data-component="title"] h5.emu-title__text, .sub-title[data-component="text"] h1, .sub-title[data-component="text"] h1.emu-title__text, .sub-title[data-component="text"] h2, .sub-title[data-component="text"] h2.emu-title__text, .sub-title[data-component="text"] h3, .sub-title[data-component="text"] h3.emu-title__text, .sub-title[data-component="text"] h4, .sub-title[data-component="text"] h4.emu-title__text, .sub-title[data-component="text"] h5, .sub-title[data-component="text"] h5.emu-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: 1.4;
  font-family: var(--emu-semantic-font-families-mono);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .sub-title[data-component="title"] h1, .sub-title[data-component="title"] h1.emu-title__text, .sub-title[data-component="title"] h2, .sub-title[data-component="title"] h2.emu-title__text, .sub-title[data-component="title"] h3, .sub-title[data-component="title"] h3.emu-title__text, .sub-title[data-component="title"] h4, .sub-title[data-component="title"] h4.emu-title__text, .sub-title[data-component="title"] h5, .sub-title[data-component="title"] h5.emu-title__text, .sub-title[data-component="text"] h1, .sub-title[data-component="text"] h1.emu-title__text, .sub-title[data-component="text"] h2, .sub-title[data-component="text"] h2.emu-title__text, .sub-title[data-component="text"] h3, .sub-title[data-component="text"] h3.emu-title__text, .sub-title[data-component="text"] h4, .sub-title[data-component="text"] h4.emu-title__text, .sub-title[data-component="text"] h5, .sub-title[data-component="text"] h5.emu-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

@media (min-width: 992px) {
  .sub-title[data-component="title"] h1, .sub-title[data-component="title"] h1.emu-title__text, .sub-title[data-component="title"] h2, .sub-title[data-component="title"] h2.emu-title__text, .sub-title[data-component="title"] h3, .sub-title[data-component="title"] h3.emu-title__text, .sub-title[data-component="title"] h4, .sub-title[data-component="title"] h4.emu-title__text, .sub-title[data-component="title"] h5, .sub-title[data-component="title"] h5.emu-title__text, .sub-title[data-component="text"] h1, .sub-title[data-component="text"] h1.emu-title__text, .sub-title[data-component="text"] h2, .sub-title[data-component="text"] h2.emu-title__text, .sub-title[data-component="text"] h3, .sub-title[data-component="text"] h3.emu-title__text, .sub-title[data-component="text"] h4, .sub-title[data-component="text"] h4.emu-title__text, .sub-title[data-component="text"] h5, .sub-title[data-component="text"] h5.emu-title__text {
    font-size: 30px;
  }
}

@media (min-width: 480px) {
  .sub-title[data-component="title"] br, .sub-title[data-component="text"] br {
    display: none;
  }
}

.sub-title[data-component="title"] sup, .sub-title[data-component="text"] sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  .sub-title[data-component="title"] sup, .sub-title[data-component="text"] sup {
    left: 0;
  }
}

.hcpj-video.aaaem-embed .aaaem-embed__oembed:not(.no-ratio), .hcpj-video .aaaem-embed__embeddable:not(.no-ratio) {
  padding-bottom: 52%;
}

.cmp-text.hcpj-video-transcript {
  width: 93%;
  background-color: var(--emu-semantic-colors-secondary-grey-200);
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 30px;
}

.cmp-text.hcpj-video-transcript h3 {
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  color: var(--emu-semantic-colors-secondary-dark-grey-500);
  margin-top: 10px;
  margin-bottom: 6px;
  font-size: 15px;
  line-height: 1.1;
}

.cmp-text.hcpj-video-transcript p {
  margin-bottom: 10px;
  margin-top: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
  font-size: 15px;
  line-height: 1.4;
}

.skewed-container__main {
  background-image: linear-gradient(340deg, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
  margin: 10px auto;
}

@media (min-width: 768px) {
  .skewed-container__main {
    transform: skew(-19deg);
  }
}

@media (min-width: 992px) {
  .skewed-container__main {
    max-width: 87%;
    margin-left: auto;
    margin-right: auto;
    transform: skew(-24deg);
  }
}

@media (min-width: 1200px) {
  .skewed-container__main {
    max-width: 90%;
  }
}

@media (min-width: 1280px) {
  .skewed-container__main {
    max-width: 950px;
  }
}

@media (min-width: 768px) {
  .skewed-container__main-inner {
    transform: skew(24deg);
  }

  .skewed-container__wrapper {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .skewed-container__wrapper {
    max-width: 100%;
  }
}

.disclaimer.cmp-text p {
  font-family: var(--emu-semantic-font-families-poppins-light);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 3px;
  padding-left: 15px;
  font-size: 8px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .disclaimer.cmp-text p {
    padding-left: var(--emu-common-spacing-none);
    font-size: var(--emu-common-font-sizes-narrow-medium);
  }
}

.disclaimer.cmp-text p b {
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
}

.disclaimer.cmp-text p sup {
  vertical-align: middle;
  font-size: 100%;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .disclaimer.cmp-text p sup {
    top: 1px;
  }
}

.disclaimer.cmp-text p sup .emphasis {
  font-size: 60%;
  position: relative;
  top: -2px;
}

@media (min-width: 768px) {
  .disclaimer.cmp-text p sup .emphasis {
    top: -4px;
  }
}

.cq-Editable-dom--container .mobile-view, .cq-Editable-dom--container .tablet-desktop-view {
  display: inline !important;
  display: initial !important;
}

.cq-Editable-dom--container .skewed-container__wrapper img, .cq-Editable-dom--container .skewed-container__wrapper .image {
  display: inline !important;
  display: initial !important;
  position: static !important;
}

#home {
  margin-top: -70px;
  padding-top: 70px;
  position: relative;
}

@media (min-width: 992px) {
  #home {
    margin-top: -118px;
    padding-top: 108px;
  }
}

@media (min-width: 1280px) {
  #home {
    max-width: 1440px;
    margin-top: -120px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 120px;
  }
}

#home img {
  width: 100%;
}

#home .tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  #home .tablet-desktop-view {
    display: block;
  }
}

#home .home-section__banner-text {
  text-align: center;
  font-weight: var(--emu-semantic-font-weight-500);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  position: absolute;
  top: 40%;
  bottom: 0;
  left: 8.5%;
  transform: translateY(-30%);
}

@media (min-width: 480px) {
  #home .home-section__banner-text {
    top: 48%;
  }
}

@media (min-width: 768px) {
  #home .home-section__banner-text {
    top: 40%;
  }
}

@media (min-width: 992px) {
  #home .home-section__banner-text {
    top: 45%;
    left: 20.5%;
    transform: translateY(-30%)translateY(-5px);
  }
}

@media (min-width: 1200px) {
  #home .home-section__banner-text {
    transform: translateY(-30%)translateY(1px);
  }
}

#home .home-section__banner-text p {
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 1.07;
  font-weight: var(--emu-semantic-font-weight-500);
  font-family: var(--emu-semantic-font-families-mono);
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #home .home-section__banner-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

@media (min-width: 992px) {
  #home .home-section__banner-text p {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  #home .home-section__banner-text p {
    font-size: 30px;
  }
}

#home .home-section__banner-text p sup {
  vertical-align: super;
  font-size: 48%;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #home .home-section__banner-text p sup {
    left: 0;
  }
}

#home .home-section__banner-text h1 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 12px;
  font-size: 36px;
  line-height: 1.07;
}

@media (min-width: 768px) {
  #home .home-section__banner-text h1 {
    font-size: 60px;
  }
}

@media (min-width: 992px) {
  #home .home-section__banner-text h1 {
    font-size: 74px;
  }
}

@media (min-width: 1200px) {
  #home .home-section__banner-text h1 {
    font-size: 90px;
  }
}

#home .home-section__banner-text h3 {
  font-family: var(--emu-semantic-font-families-heading);
  margin-top: var(--emu-common-spacing-none);
  font-size: var(--emu-common-font-sizes-wide-medium);
  margin-bottom: 10px;
  line-height: 1.1;
}

@media (min-width: 768px) {
  #home .home-section__banner-text h3 {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  #home .home-section__banner-text h3 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  #home .home-section__banner-text h3 {
    font-size: 35px;
  }
}

#home .home-section__tagline {
  background-image: linear-gradient(to right, var(--emu-semantic-colors-primary-purple-400) 5%, var(--emu-semantic-colors-primary-purple-200) 100%);
  padding: 15px 15px 14px;
}

@media (min-width: 768px) {
  #home .home-section__tagline {
    background-image: linear-gradient(to right, var(--emu-semantic-colors-primary-purple-500) 60%, var(--emu-semantic-colors-primary-purple-100) 100%);
    padding: 35px 15px 22px;
  }
}

@media (min-width: 1024px) {
  #home .home-section__tagline {
    padding-bottom: 23px;
  }
}

#home .home-section__tagline-inner {
  background-color: var(--emu-common-colors-transparent);
  margin-left: 10px;
}

@media (min-width: 768px) {
  #home .home-section__tagline-inner {
    max-width: 800px;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  #home .home-section__tagline-inner {
    margin-left: auto;
    margin-right: auto;
  }
}

#home .home-section__tagline-text {
  color: var(--emu-common-colors-white);
  line-height: 1.2;
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
}

@media (min-width: 768px) {
  #home .home-section__tagline-text {
    margin-bottom: 10px;
    font-size: 21px;
  }
}

#home .home-section__tagline-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

#home .home-section__tagline-text p sup {
  vertical-align: middle;
  font-size: 100%;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #home .home-section__tagline-text p sup {
    left: 0;
  }
}

#home .home-section__tagline-text p br {
  display: none;
}

@media (min-width: 768px) {
  #home .home-section__tagline-text p br {
    display: block;
  }
}

#home .home-section__cta {
  border: var(--emu-common-border-width-none);
  padding: var(--emu-common-spacing-none);
  width: 37px;
  height: 18px;
  text-align: center;
  background-image: url("resources/images/down-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
}

@media (min-width: 768px) {
  #home .home-section__cta {
    display: inline-block;
    transform: translateY(6px);
  }
}

#home .home-section__cta, #home .home-section__cta:active, #home .home-section__cta:focus, #home .home-section__cta:hover {
  background-color: var(--emu-common-colors-transparent);
  outline: none;
}

.cq-Editable-dom--container .banner-text {
  position: static !important;
  transform: none !important;
}

#collections-section {
  margin-top: 40px;
}

@media (min-width: 768px) {
  #collections-section {
    margin-top: 55px;
  }
}

#collections-section .sub-title--video-title .emu-title__text {
  margin-top: 20px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #collections-section .sub-title--video-title .emu-title__text {
    margin-bottom: 35px;
  }
}

#collections-section .page-section--collection {
  margin-bottom: 55px;
}

#collections-section .collections-section__desc h5 {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-500);
  color: var(--emu-common-colors-black);
  line-height: 1.4;
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #collections-section .collections-section__desc h5 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

@media (min-width: 480px) {
  #collections-section .collections-section__desc h5 br {
    display: none;
  }
}

#collections-section .collections-section__product-logo {
  text-align: center;
}

#collections-section .collections-section__product-logo img {
  max-width: 100px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

@media (min-width: 768px) {
  #collections-section .collections-section__product-logo img {
    margin-bottom: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 992px) {
  #collections-section .collections-section__product-logo img {
    margin-bottom: 10px;
  }
}

#collections-section .collections-section__product-logo--large img {
  max-width: 125px;
}

#collections-section .collections-section__product-logo--small img {
  max-width: 90px;
}

#collections-section .collections-section__product-logo--medium img {
  max-width: 112px;
}

#collections-section .collections-section__carousel {
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
  font-family: var(--emu-semantic-font-families-body);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  background-image: linear-gradient(to right, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
  margin-top: 35px;
  margin-bottom: 35px;
  padding: 25px 40px;
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel {
    margin-top: 45px;
    margin-bottom: 85px;
    padding: 62px 15px 30px;
  }
}

#collections-section .collections-section__carousel-main-image img {
  max-width: 100%;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel-main-image img {
    max-width: 60%;
    vertical-align: middle;
    display: inline-block;
  }
}

@media (min-width: 992px) {
  #collections-section .collections-section__carousel-main-image img {
    max-width: 100%;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel-main-image {
    text-align: center;
  }
}

#collections-section .collections-section__carousel-text h6 {
  line-height: 1.1;
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  color: var(--emu-semantic-colors-primary-purple-500);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  margin-top: 10px;
  margin-bottom: 5px;
}

#collections-section .collections-section__carousel-text h6 sup {
  vertical-align: super;
  font-size: 48%;
  top: 0;
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel-text h6 sup {
    top: 1px;
  }
}

#collections-section .collections-section__carousel-text h6 .emphasis {
  display: block;
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel-text h6 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

#collections-section .collections-section__carousel-text p {
  margin-bottom: 10px;
  margin-top: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-black);
  line-height: 1.4;
  font-size: var(--emu-common-font-sizes-narrow-medium);
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel-text p {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

@media (min-width: 385px) {
  #collections-section .collections-section__carousel .tns-inner {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel .tns-inner {
    margin: var(--emu-common-spacing-none);
  }
}

#collections-section .collections-section__carousel .aaaem-carousel__action {
  background-color: var(--emu-common-colors-transparent);
  border: none;
  top: 30%;
  transform: translateY(-5px);
}

#collections-section .collections-section__carousel .aaaem-carousel__action svg {
  width: 50px;
  height: 50px;
  fill: var(--emu-semantic-colors-primary-purple-500);
  margin-left: -4px;
  margin-right: -4px;
  display: block;
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel .aaaem-carousel__content {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }
}

#collections-section .collections-section__carousel .aaaem-carousel__item-container {
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 440px) {
  #collections-section .collections-section__carousel .aaaem-carousel__item-container {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #collections-section .collections-section__carousel .aaaem-carousel__item {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
    background-color: var(--emu-common-colors-transparent);
    flex: 0 0 30%;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  #collections-section .collections-section__carousel .aaaem-carousel__item {
    margin-bottom: var(--emu-common-spacing-none);
    flex: 0 0 16.5%;
  }
}

@media (min-width: 1024px) {
  #collections-section .collections-section__carousel .aaaem-carousel__item {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#collections-section .dermal-section {
  background-color: var(--emu-semantic-colors-secondary-grey-200);
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #collections-section .dermal-section {
    margin-top: 100px;
    padding-top: 90px;
    padding-bottom: 30px;
  }
}

#collections-section .dermal-section .main-title h2 sup {
  top: 0;
}

@media (min-width: 768px) {
  #collections-section .dermal-section .main-title h2 sup {
    top: -1px;
  }
}

#collections-section .dermal-section__grid {
  grid-gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

@media (min-width: 768px) {
  #collections-section .dermal-section__grid {
    grid-gap: 0;
    gap: 0;
    margin-top: 50px;
    margin-bottom: 40px;
  }
}

#collections-section .dermal-section__grid > .container {
  background-color: var(--emu-common-colors-transparent);
  flex: 0 38%;
}

#collections-section .dermal-section__grid > .container:last-child {
  margin-top: 30px;
}

@media (min-width: 768px) {
  #collections-section .dermal-section__grid > .container:last-child {
    margin-top: 60px;
    margin-right: var(--emu-common-spacing-none);
  }

  #collections-section .dermal-section__grid > .container:nth-last-child(2) {
    margin-top: 60px;
  }

  #collections-section .dermal-section__grid > .container {
    flex: 0 0 20%;
    margin-right: 60px;
  }
}

#collections-section .dermal-section__grid-item p {
  color: var(--emu-common-colors-black);
  font-size: 13px;
  line-height: 1.4;
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  margin-top: 10px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #collections-section .dermal-section__grid-item p {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

@media (min-width: 1200px) {
  #collections-section .dermal-section__grid-item p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

#collections-section .dermal-section__grid-item p sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 1px;
}

#collections-section .dermal-section__disclaimer {
  padding-left: 15px;
}

@media (min-width: 768px) {
  #collections-section .dermal-section__disclaimer {
    max-width: 40%;
    margin-left: auto;
    padding-left: 30px;
  }
}

@media (min-width: 1200px) {
  #collections-section .dermal-section__disclaimer {
    max-width: 35%;
  }
}

@media (min-width: 1280px) {
  #collections-section .dermal-section__disclaimer {
    max-width: 28%;
  }
}

#collections-section .skewed-outer {
  background-image: linear-gradient(130deg, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
}

@media (min-width: 768px) {
  #collections-section .skewed-outer {
    transform: skew(-19deg);
  }
}

@media (min-width: 992px) {
  #collections-section .offset-container--only-juvederm {
    max-width: 100%;
    margin-top: auto;
    margin-left: auto;
  }
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__wrapper-inner {
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__wrapper-inner {
    margin-top: 130px;
    margin-bottom: 150px;
  }
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner {
  align-items: center;
  display: flex;
  position: relative;
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner > .container {
  flex-direction: column;
  flex-basis: 50%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  display: flex;
}

@media (min-width: 1200px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner > .container {
    flex-basis: 60%;
  }
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner > .button {
  position: static;
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner > .button a {
  position: absolute;
  inset: 0;
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h3 {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  color: var(--emu-semantic-colors-primary-green-100);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 1.1;
  font-family: var(--emu-semantic-font-families-heading);
}

@media (min-width: 480px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h3 {
    font-size: 33px;
  }
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h3 {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h3 {
    font-size: 55px;
  }
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h5 {
  color: var(--emu-semantic-colors-primary-green-100);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: 13px;
  line-height: 1.25;
  font-weight: var(--emu-semantic-font-weight-500);
}

@media (min-width: 480px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h5 {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
  }
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h5 {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h5 {
    font-size: 24px;
  }
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h5 sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 0;
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner h5 sup {
    top: -1px;
  }
}

#collections-section .skewed-container__wrapper--jaw-inspiring .skewed-container__main-inner .product-img img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--only-juvederm {
    max-width: 77%;
  }
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--only-juvederm {
    max-width: 100%;
  }
}

#collections-section .skewed-container__wrapper--only-juvederm .skewed-container__wrapper-inner {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-secondary-dark-grey-100);
  padding-top: 40px;
  padding-bottom: 30px;
  position: relative;
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__wrapper-inner {
    padding-top: 140px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 105px;
  }
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__wrapper-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main {
    max-width: 65%;
    margin-left: 250px;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main {
    max-width: 70%;
  }
}

@media (min-width: 1280px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main {
    max-width: 950px;
  }
}

#collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main-inner {
  position: relative;
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main-inner {
    transform: skew(19deg);
  }
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main-inner {
    transform: skew(24deg);
  }
}

@media (min-width: 1200px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main-inner {
    justify-content: flex-end;
    display: flex;
  }
}

#collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main img {
  max-width: 240px;
  position: absolute;
  bottom: -5px;
  left: -85px;
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main img {
    max-width: 350px;
    bottom: -10px;
    left: -90px;
  }
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main img {
    max-width: 415px;
  }
}

#collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text {
  max-width: 67%;
  margin-left: auto;
  padding: 10px 20px;
}

@media (min-width: 480px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text {
    max-width: 70%;
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text {
    max-width: 68%;
  }
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text {
    max-width: 70%;
    padding: 35px 40px 35px 120px;
  }
}

@media (min-width: 1200px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text {
    max-width: 100%;
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

#collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 1.25;
  font-weight: var(--emu-semantic-font-weight-500);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    text-align: center;
  }
}

@media (min-width: 992px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

@media (min-width: 1200px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text p {
    font-size: 24px;
  }
}

#collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 0;
}

@media (min-width: 768px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text sup {
    top: 1px;
  }
}

@media (min-width: 1200px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text .emphasis {
    display: block;
  }
}

#collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text .emphasis br {
  display: none;
}

@media (min-width: 1200px) {
  #collections-section .skewed-container__wrapper--only-juvederm .skewed-container__main .only-juvederm__text .emphasis br {
    display: block;
  }
}

#collections-section .tailored-gel {
  background-image: radial-gradient(var(--emu-semantic-colors-primary-purple-300), var(--emu-semantic-colors-primary-purple-500));
  padding-bottom: var(--emu-common-spacing-small);
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 768px) {
  #collections-section .tailored-gel {
    padding-top: 90px;
    padding-bottom: 40px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #collections-section .tailored-gel__container {
    margin-left: 200px;
  }
}

@media (min-width: 1280px) {
  #collections-section .tailored-gel__container {
    margin-left: 165px;
  }
}

#collections-section .tailored-gel .sub-title h3 {
  font-size: var(--emu-common-font-sizes-wide-medium);
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #collections-section .tailored-gel .sub-title h3 {
    margin-bottom: 80px;
    font-size: 25px;
  }
}

@media (min-width: 992px) {
  #collections-section .tailored-gel .sub-title h3 {
    font-size: 30px;
  }
}

#collections-section .tailored-gel .sub-title h3 br {
  display: none;
}

@media (min-width: 1200px) {
  #collections-section .tailored-gel .sub-title h3 br {
    display: block;
  }
}

#collections-section .tailored-gel .disclaimer p {
  padding-left: var(--emu-common-spacing-none);
}

#collections-section .tailored-gel__top-text p {
  font-family: var(--emu-semantic-font-families-body);
  margin: 10px 10px 15px;
}

@media (min-width: 768px) {
  #collections-section .tailored-gel__top-text p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

#collections-section .tailored-gel__top-text p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

#collections-section .tailored-gel__top-text p sup {
  vertical-align: middle;
  font-size: 55%;
  display: inline-block;
  top: -4px;
}

#collections-section .tailored-gel__bottom-text p {
  margin-top: 18px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #collections-section .tailored-gel__bottom-text p {
    margin-top: 40px;
    font-size: 17px;
  }
}

#collections-section .tailored-gel__bottom-text p sup {
  vertical-align: super;
  font-size: 80%;
  display: inline-block;
  top: 2px;
}

@media (min-width: 768px) {
  #collections-section .tailored-gel__bottom-text p sup {
    top: 4px;
  }
}

.cq-Editable-dom--container .jaw-inspiring__anchor-text {
  position: static !important;
}

#innovation-section .main-title h3.emu-title__text {
  color: var(--emu-common-colors-white);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #innovation-section .main-title h3.emu-title__text {
    margin-bottom: var(--emu-common-spacing-xs);
    font-size: 48px;
  }
}

#innovation-section .innovation-container {
  background-image: radial-gradient(var(--emu-semantic-colors-primary-purple-300), var(--emu-semantic-colors-primary-purple-500));
  padding-top: 30px;
  position: relative;
}

@media (min-width: 768px) {
  #innovation-section .innovation-container {
    margin-top: 60px;
    padding-top: 80px;
  }
}

#innovation-section .innovation-container:before {
  content: "";
  width: 120px;
  height: 100%;
  z-index: var(--emu-common-other-z-index-layer);
  background-image: linear-gradient(to right, var(--emu-semantic-colors-primary-purple-500), transparent);
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  #innovation-section .innovation-container:before {
    width: 320px;
  }
}

@media (min-width: 992px) {
  #innovation-section .innovation-container:before {
    width: 350px;
  }
}

#innovation-section .innovation-container__title-section p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 20px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #innovation-section .innovation-container__title-section p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }
}

#innovation-section .innovation-container__title-section p sup {
  vertical-align: middle;
  font-size: 100%;
  display: inline-block;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #innovation-section .innovation-container__title-section p sup {
    left: 0;
  }
}

#innovation-section .innovation-container__title-section p .emphasis {
  display: block;
}

#innovation-section .innovation-container__title-section p .emphasis sup {
  vertical-align: middle;
  font-size: 50%;
  top: -1px;
  left: 0;
}

@media (min-width: 480px) {
  #innovation-section .innovation-container__title-section p .emphasis {
    display: inline;
  }
}

#innovation-section .innovation-container__view-more {
  text-align: center;
}

#innovation-section .innovation-container__view-more p {
  margin: var(--emu-common-spacing-none);
  background-color: var(--emu-semantic-colors-primary-pink-100);
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  font-size: var(--emu-common-font-sizes-wide-medium);
  padding: 12px 35px;
  line-height: 1.4;
  display: inline-block;
}

#innovation-section .innovation-container__carousel-section h3 {
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-mono);
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  margin-bottom: 10px;
  margin-top: var(--emu-common-spacing-none);
  line-height: 1.4;
}

#innovation-section .innovation-container__carousel-section h3 sup {
  vertical-align: super;
  font-size: 48%;
  top: 0;
}

#innovation-section .innovation-container__carousel-section .image img {
  margin-left: auto;
  margin-right: auto;
}

#innovation-section .innovation-container__carousel-section p {
  font-size: var(--emu-common-font-sizes-wide-medium);
  margin-top: 15px;
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #innovation-section .innovation-container__carousel-section p {
    max-width: 100%;
  }
}

#innovation-section .innovation-container__carousel-section p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
  font-weight: var(--emu-semantic-font-weight-700);
}

@media (min-width: 1280px) {
  #innovation-section .innovation-container__carousel-section p .emphasis {
    display: block;
  }
}

#innovation-section .innovation-container__carousel-section p .emphasis b {
  font-family: var(--emu-semantic-font-families-body);
}

#innovation-section .innovation-container .aaaem-carousel__action {
  position: static;
}

#innovation-section .innovation-container .aaaem-carousel__action svg {
  fill: var(--emu-common-colors-white);
  width: 23px;
  height: 23px;
  stroke: var(--emu-common-colors-white);
}

#innovation-section .innovation-container .aaaem-carousel__actions {
  grid-gap: 90px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 90px;
  display: flex;
  position: absolute;
  top: -45px;
  left: 0;
}

#innovation-section .innovation-container .aaaem-carousel__content {
  background-color: var(--emu-semantic-colors-primary-purple-500);
  -webkit-user-select: none;
  user-select: none;
  padding-top: 60px;
  padding-bottom: 50px;
  position: relative;
}

#innovation-section .innovation-container .aaaem-carousel__content:before {
  content: "";
  height: 30px;
  background: var(--emu-semantic-colors-primary-pink-100);
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#innovation-section .innovation-container .aaaem-carousel__item {
  position: relative;
}

#innovation-section .innovation-container .aaaem-carousel__item:before {
  content: "";
  width: var(--emu-common-sizing-small);
  height: var(--emu-common-sizing-small);
  background-color: var(--emu-common-colors-white);
  border-radius: 50%;
  position: absolute;
  top: -52px;
  left: 50%;
  transform: translateX(-50%);
}

#innovation-section .innovation-container .aaaem-carousel__item:after {
  content: "";
  height: 20px;
  border-left: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-pink-100);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 1024px) {
  #innovation-section .innovation-container .aaaem-carousel__item-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#syringe-design-section .main-title {
  width: 80%;
  margin: auto;
}

@media (min-width: 768px) {
  #syringe-design-section .main-title {
    width: 100%;
  }

  #syringe-design-section .main-title h2 {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .main-title h2 {
    font-size: 48px;
  }
}

#syringe-design-section .main-title br {
  display: none;
}

@media (min-width: 768px) {
  #syringe-design-section .main-title br {
    display: block;
  }
}

#syringe-design-section .main-title i {
  vertical-align: super;
  font-size: 32%;
  font-style: normal;
  line-height: 0;
  display: inline-block;
  position: relative;
  top: -5px;
  left: 2px;
}

@media (min-width: 768px) {
  #syringe-design-section .main-title i {
    top: -3px;
    left: 2px;
  }
}

@media (min-width: 992px) {
  #syringe-design-section .main-title i {
    top: -10px;
    left: 5px;
  }
}

#syringe-design-section .sub-title {
  margin-bottom: 10px;
}

#syringe-design-section .sub-title--key-areas br {
  display: none;
}

@media (min-width: 768px) {
  #syringe-design-section .sub-title--key-areas br {
    display: block;
  }
}

#syringe-design-section .sub-title--color-code h3 {
  margin-bottom: 15px;
  line-height: 1.3;
}

@media (min-width: 768px) {
  #syringe-design-section .sub-title--color-code h3 {
    margin-bottom: 55px;
    font-size: 30px;
    line-height: 1.6;
  }
}

#syringe-design-section .disclaimer p {
  padding-left: 30px;
}

@media (min-width: 768px) {
  #syringe-design-section .disclaimer p {
    padding-left: 60px;
  }
}

@media (min-width: 992px) {
  #syringe-design-section .disclaimer p {
    padding-left: var(--emu-common-spacing-none);
  }
}

#syringe-design-section .disclaimer p sup {
  vertical-align: middle;
  font-size: 60%;
  top: -4px;
}

@media (min-width: 480px) {
  #syringe-design-section .disclaimer br {
    display: none;
  }
}

#syringe-design-section .disclaimer--syringe-design-rating p {
  padding: var(--emu-common-spacing-none);
  margin-top: 10px;
  margin-left: 20px;
}

@media (min-width: 768px) {
  #syringe-design-section .disclaimer--syringe-design-rating p {
    margin-left: var(--emu-common-spacing-none);
  }
}

#syringe-design-section .disclaimer--syringe-design-rating p sup {
  vertical-align: middle;
  font-size: 100%;
  display: inline-block;
  top: 1px;
  left: 1px;
}

@media (min-width: 768px) {
  #syringe-design-section .disclaimer--syringe-design-rating p sup {
    top: -1px;
  }
}

#syringe-design-section .disclaimer--syringe-design-rating p .emphasis sup {
  vertical-align: middle;
  font-size: 60%;
  display: inline-block;
  top: -1px;
  left: 0;
}

#syringe-design-section .offset-container--syringe-design-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  #syringe-design-section .offset-container--syringe-design-bottom {
    padding-top: 90px;
    padding-bottom: 50px;
  }
}

#syringe-design-section .skewed-container__wrapper--syringe-scale {
  margin-top: 25px;
}

@media (min-width: 768px) {
  #syringe-design-section .skewed-container__wrapper--syringe-scale {
    max-width: 100%;
    margin-top: 120px;
  }

  #syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main {
    max-width: 86%;
  }
}

@media (min-width: 992px) {
  #syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main {
    margin-left: 50px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main {
    max-width: 890px;
    margin-left: 35px;
  }
}

@media (min-width: 1280px) {
  #syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main {
    max-width: 950px;
    margin-right: 0;
    margin-right: initial;
  }
}

#syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main-inner {
  justify-content: end;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main-inner {
    justify-content: flex-start;
    transform: skew(19deg);
  }
}

@media (min-width: 992px) {
  #syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main-inner {
    justify-content: center;
    transform: skew(24deg);
  }
}

@media (min-width: 1280px) {
  #syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main-inner {
    justify-content: initial;
  }
}

@media (min-width: 768px) {
  #syringe-design-section .skewed-container__wrapper--syringe-scale .skewed-container__main-inner > .container {
    width: 100%;
    max-width: 1000px;
  }
}

#syringe-design-section .skewed-container__wrapper--syringe-rating {
  max-width: 100%;
}

#syringe-design-section .skewed-container__wrapper--syringe-rating .skewed-container__main {
  margin: auto;
  padding: 15px 20px 15px 35px;
}

@media (min-width: 768px) {
  #syringe-design-section .skewed-container__wrapper--syringe-rating .skewed-container__main {
    max-width: 90%;
    margin-right: auto;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 80px;
    transform: skew(-24deg);
  }
}

@media (min-width: 992px) {
  #syringe-design-section .skewed-container__wrapper--syringe-rating .skewed-container__main {
    max-width: 82%;
    margin-left: 40px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .skewed-container__wrapper--syringe-rating .skewed-container__main {
    max-width: 92%;
    margin-left: auto;
    padding-left: 130px;
  }
}

@media (min-width: 1280px) {
  #syringe-design-section .skewed-container__wrapper--syringe-rating .skewed-container__main {
    max-width: 950px;
  }
}

@media (min-width: 768px) {
  #syringe-design-section .skewed-container__wrapper--syringe-rating .skewed-container__main-inner {
    transform: skew(24deg);
  }
}

#syringe-design-section .syringe-design__main-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__main-container {
    margin-top: 130px;
    margin-bottom: 130px;
  }
}

#syringe-design-section .syringe-design__sub-section {
  max-width: 80%;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  display: flex;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__sub-section {
    max-width: 100%;
    margin-top: 22px;
    margin-bottom: 45px;
  }
}

#syringe-design-section .syringe-design__sub-section .image .cmp-image {
  width: 65px;
  margin-left: 15px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__sub-section .image .cmp-image {
    width: 108px;
    max-width: 100%;
    margin-left: 40px;
  }
}

#syringe-design-section .syringe-design__collection-text {
  max-width: 85%;
  margin: 20px auto;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__collection-text {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

#syringe-design-section .syringe-design__collection-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 1.4;
  font-style: 14px;
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__collection-text p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }
}

#syringe-design-section .syringe-design__collection-text b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
  color: var(--emu-semantic-colors-primary-purple-500);
}

#syringe-design-section .syringe-design__collection-text b sup {
  vertical-align: middle;
  font-size: 100%;
  line-height: 0;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__collection-text b sup {
    left: 0;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__collection-text .emphasis {
    display: block;
  }
}

#syringe-design-section .syringe-design__collection-text .emphasis b {
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
}

#syringe-design-section .syringe-design__collection-text .emphasis sup {
  vertical-align: middle;
  font-size: 50%;
  top: -1px;
}

#syringe-design-section .syringe-design__scale-image {
  max-width: 110px;
  z-index: var(--emu-common-other-z-index-layer);
  position: absolute;
  bottom: 0;
  left: 5px;
}

@media (min-width: 480px) {
  #syringe-design-section .syringe-design__scale-image {
    max-width: 150px;
    left: 10px;
  }
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-image {
    left: -40px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__scale-image {
    max-width: 195px;
  }
}

#syringe-design-section .syringe-design__scale-text-container {
  padding: 25px 30px 20px 5px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-text-container {
    align-items: flex-end;
    margin-left: 14%;
    padding-bottom: 50px;
    display: flex;
  }
}

@media (min-width: 992px) {
  #syringe-design-section .syringe-design__scale-text-container {
    padding-right: 24px;
  }
}

@media (min-width: 1024px) {
  #syringe-design-section .syringe-design__scale-text-container {
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__scale-text-container {
    margin-left: 16%;
    padding-left: 30px;
    display: inline-flex;
  }
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-text-container > .text {
    flex-basis: 63%;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__scale-text-container > .text {
    flex-basis: 71%;
  }
}

#syringe-design-section .syringe-design__scale-text {
  margin-left: var(--emu-common-spacing-none);
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
  display: flex;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-text {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__scale-text {
    justify-content: flex-start;
  }
}

#syringe-design-section .syringe-design__scale-text h3 {
  color: var(--emu-semantic-colors-primary-purple-500);
  font-family: var(--emu-semantic-font-families-heading);
  line-height: 1.25;
  font-size: var(--emu-common-font-sizes-narrow-medium);
  text-align: center;
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-text h3 {
    text-align: right;
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
  }
}

@media (min-width: 992px) {
  #syringe-design-section .syringe-design__scale-text h3 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__scale-text h3 {
    font-size: 24px;
  }
}

#syringe-design-section .syringe-design__scale-text h4 {
  font-size: var(--emu-semantic-font-sizes-wide-xxxl);
  margin-top: var(--emu-common-spacing-none);
  margin-right: 12px;
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-purple-500);
  line-height: 1;
  font-family: var(--emu-semantic-font-families-heading);
  flex-basis: 15%;
  margin-left: 12px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-text h4 {
    font-size: 55px;
  }
}

@media (min-width: 992px) {
  #syringe-design-section .syringe-design__scale-text h4 {
    font-size: 90px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__scale-text h4 {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 1440px) {
  #syringe-design-section .syringe-design__scale-text h4 {
    margin-right: 27px;
  }
}

#syringe-design-section .syringe-design__scale-text .emphasis {
  display: block;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-text .emphasis {
    display: contents;
  }
}

@media (min-width: 1280px) {
  #syringe-design-section .syringe-design__scale-text .emphasis {
    display: block;
    position: relative;
  }
}

#syringe-design-section .syringe-design__scale-stars ul {
  padding-left: var(--emu-common-spacing-none);
  grid-gap: var(--emu-common-spacing-xs);
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--emu-common-spacing-xs);
  margin-top: -5px;
  margin-bottom: -5px;
  list-style: none;
  display: flex;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-stars ul {
    grid-gap: 4.5px;
    gap: 4.5px;
    margin-bottom: -4px;
  }
}

#syringe-design-section .syringe-design__scale-stars .animate-star:not(:last-child) {
  color: var(--emu-semantic-colors-primary-pink-100);
}

#syringe-design-section .syringe-design__scale-stars li {
  margin: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  font-size: 17px;
  line-height: normal;
  display: inline-block;
  position: relative;
}

@keyframes stretch-bounce {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  50% {
    transform: scale(.9);
  }

  75% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(1) {
  transition: color 0s 1.2s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(1).animate-star {
  animation: .5s ease-in-out 1.2s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(2) {
  transition: color 0s 1.4s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(2).animate-star {
  animation: .5s ease-in-out 1.4s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(3) {
  transition: color 0s 1.6s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(3).animate-star {
  animation: .5s ease-in-out 1.6s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(4) {
  transition: color 0s 1.8s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(4).animate-star {
  animation: .5s ease-in-out 1.8s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(5) {
  transition: color 0s 2s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(5).animate-star {
  animation: .5s ease-in-out 2s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(6) {
  transition: color 0s 2.2s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(6).animate-star {
  animation: .5s ease-in-out 2.2s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(7) {
  transition: color 0s 2.4s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(7).animate-star {
  animation: .5s ease-in-out 2.4s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(8) {
  transition: color 0s 2.6s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(8).animate-star {
  animation: .5s ease-in-out 2.6s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(9) {
  transition: color 0s 2.8s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(9).animate-star {
  animation: .5s ease-in-out 2.8s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(10) {
  transition: color 0s 3s;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(10).animate-star {
  animation: .5s ease-in-out 3s stretch-bounce;
}

#syringe-design-section .syringe-design__scale-stars li:before {
  content: "";
  z-index: var(--emu-common-other-z-index-layer);
  font-family: fontAwesome;
  display: inline-block;
  position: relative;
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(9):before {
  content: "";
}

#syringe-design-section .syringe-design__scale-stars li:nth-child(9):after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--emu-common-colors-white);
  border-radius: 50%;
  margin: auto;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
}

#syringe-design-section .syringe-design__scale-stars .emphasis {
  display: none;
}

#syringe-design-section .syringe-design__scale-stars-section {
  margin-left: 30px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-stars-section {
    margin-left: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-xxs);
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__scale-stars-section {
    margin-top: var(--emu-common-spacing-none);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}

#syringe-design-section .syringe-design__scale-stars-section h5 {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-purple-500);
  margin-top: 7px;
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 1.25;
  font-weight: var(--emu-semantic-font-weight-500);
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-stars-section h5 {
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    text-align: left;
  }
}

@media (min-width: 992px) {
  #syringe-design-section .syringe-design__scale-stars-section h5 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__scale-stars-section h5 {
    margin-top: 5px;
    font-size: 24px;
  }
}

#syringe-design-section .syringe-design__scale-stars-section h5 sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: -5px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__scale-stars-section h5 sup {
    top: 0;
  }
}

#syringe-design-section .syringe-design__key-areas {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__key-areas {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

#syringe-design-section .syringe-design__key-areas-item-grid {
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__key-areas-item-grid {
    flex-direction: initial;
    align-items: initial;
    margin-top: 50px;
  }
}

#syringe-design-section .syringe-design__key-areas-item-grid > .container {
  flex: 0 0 33%;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__key-areas-item-grid > .container {
    margin-bottom: var(--emu-common-spacing-none);
    flex: none;
  }
}

#syringe-design-section .syringe-design__key-areas-item .image {
  max-width: 165px;
  margin-left: auto;
  margin-right: auto;
}

#syringe-design-section .syringe-design__key-areas-item .emphasis {
  display: block;
}

#syringe-design-section .syringe-design__key-areas-item p {
  color: var(--emu-common-colors-black);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  margin-top: 25px;
  margin-bottom: var(--emu-common-spacing-none);
  text-align: center;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__key-areas-item p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }
}

#syringe-design-section .syringe-design__key-areas-item p sup {
  vertical-align: super;
  display: inline-block;
  top: 0;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__key-areas-item p sup {
    top: 1px;
  }
}

#syringe-design-section .syringe-design__rating-content-wrapper {
  grid-gap: 15px;
  align-items: center;
  gap: 15px;
  display: flex;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__rating-content-wrapper {
    grid-gap: 25px;
    gap: 25px;
  }
}

#syringe-design-section .syringe-design__rating-title {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  padding: 5px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__rating-title {
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__rating-title {
    width: 145px;
    height: 145px;
  }
}

#syringe-design-section .syringe-design__rating-title:before {
  content: "";
  width: 92%;
  height: 92%;
  background-color: #f2e8e3;
  border-radius: 50%;
  position: absolute;
}

#syringe-design-section .syringe-design__rating-title h3 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-common-colors-white);
  width: 85px;
  height: 85px;
  background-color: var(--emu-semantic-colors-primary-purple-500);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  line-height: 1.1;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__rating-title h3 {
    width: 95px;
    height: 95px;
    background-image: linear-gradient(to right, var(--emu-semantic-colors-primary-purple-500), var(--emu-semantic-colors-primary-pink-100));
    font-size: 42px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__rating-title h3 {
    width: 110px;
    height: 110px;
    font-size: 48px;
  }
}

#syringe-design-section .syringe-design__rating-title h3 sup {
  vertical-align: super;
  font-size: 50%;
  top: 0;
}

#syringe-design-section .syringe-design__rating-content h5 {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 1.25;
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-500);
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__rating-content h5 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

@media (min-width: 992px) {
  #syringe-design-section .syringe-design__rating-content h5 {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__rating-content h5 {
    font-size: 26px;
  }
}

#syringe-design-section .syringe-design__rating-content h5 br {
  display: none;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__rating-content h5 br {
    display: block;
  }
}

#syringe-design-section .syringe-design__rating-content h5 sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 0;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__rating-content h5 sup {
    top: -1px;
  }
}

#syringe-design-section .syringe-design__color-code {
  padding-top: 50px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__color-code {
    padding: 85px 15px 40px;
  }
}

#syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__action {
  padding: var(--emu-common-spacing-none);
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

#syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__action svg {
  width: 52px;
  height: 52px;
}

#syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__action-next {
  right: 2px;
}

#syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__action-prev {
  left: 2px;
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__content {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }

  #syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__item {
    flex: 0 0 27%;
    margin-bottom: 40px;
    margin-left: 25px;
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  #syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__item {
    margin-left: var(--emu-common-spacing-none);
    margin-top: var(--emu-common-spacing-none);
    flex: 0 0 30%;
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  #syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__item .emu-image {
    justify-content: center;
    display: flex;
  }

  #syringe-design-section .syringe-design__color-code-carousel .aaaem-carousel__item img {
    max-width: 250px;
  }
}

.cq-Editable-dom--container .syringe-design__scale-image {
  position: static !important;
}

#treatment-section > .container {
  padding-top: 20px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  #treatment-section > .container {
    border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-secondary-dark-grey-100);
    padding-top: 95px;
  }
}

#treatment-section .main-title--treatment-areas {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #treatment-section .main-title--treatment-areas {
    margin-bottom: 40px;
  }
}

@media (min-width: 480px) {
  #treatment-section .emu-mapping-marker-1.active:after {
    content: "";
    width: var(--emu-common-border-width-medium);
    height: 7vh;
    background-color: var(--emu-component-ingredients-image-map-buttons-color-icon-light);
    position: absolute;
    top: 50%;
    left: calc(17px - 65vw);
    transform: translate(50%, -7vh);
  }
}

#treatment-section .skewed-container__wrapper {
  margin-top: 30px;
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper {
    width: 85%;
    max-width: 885px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #treatment-section .skewed-container__wrapper {
    width: 100%;
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main {
  background: linear-gradient(to left, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main {
    transform: skew(-24deg);
  }
}

@media (min-width: 992px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main {
    max-width: 100%;
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main-inner {
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner {
    transform: skew(24deg);
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main-inner > .image {
  flex: 0 0 45%;
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner > .image {
    display: none;
  }
}

@media (min-width: 1200px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner > .image {
    flex: initial;
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main-inner > .image:nth-child(2) {
  display: none;
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner > .image:nth-child(2) {
    display: block;
  }

  #treatment-section .skewed-container__wrapper .skewed-container__main-inner > .image img {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner > .image img {
    margin-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main-inner > .text {
  padding-top: 25px;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: var(--emu-common-spacing-none);
}

@media (min-width: 1200px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner > .text {
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 25px;
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main-inner h3 {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  margin-bottom: 15px;
  margin-top: var(--emu-common-spacing-none);
  line-height: 1.1;
  font-weight: var(--emu-semantic-font-weight-500);
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-purple-500);
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner h3 {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner h3 {
    font-size: 32px;
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main-inner h3 sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner h3 sup {
    left: 0;
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main-inner h5 {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 1.32;
  font-weight: var(--emu-semantic-font-weight-500);
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-purple-500);
  margin: 5px;
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner h5 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

@media (min-width: 1200px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner h5 {
    margin: var(--emu-common-spacing-none);
    font-size: 24px;
  }

  #treatment-section .skewed-container__wrapper .skewed-container__main-inner h5 .emphasis {
    display: block;
  }
}

#treatment-section .skewed-container__wrapper .skewed-container__main-inner h5 sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 0;
}

@media (min-width: 768px) {
  #treatment-section .skewed-container__wrapper .skewed-container__main-inner h5 sup {
    top: 1px;
  }
}

#treatment-section .disclaimer {
  max-width: 885px;
  margin-left: auto;
  margin-right: auto;
}

#treatment-section .disclaimer p {
  color: var(--emu-common-colors-black);
  margin-top: 10px;
  margin-bottom: 30px;
  padding-left: 20px;
}

@media (min-width: 768px) {
  #treatment-section .disclaimer p {
    margin-bottom: 40px;
    padding-left: 35px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .disclaimer p {
    padding-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .disclaimer--treatment-areas-jawline {
  max-width: none;
}

@media (min-width: 1200px) {
  #treatment-section .disclaimer--treatment-areas-jawline {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 768px) {
  #treatment-section .disclaimer--treatment-areas-jawline p {
    margin-left: 20px;
    padding-left: 35px;
  }
}

@media (min-width: 992px) {
  #treatment-section .disclaimer--treatment-areas-jawline p {
    margin-left: 50px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .disclaimer--treatment-areas-jawline p {
    padding-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .disclaimer--treatment-areas-jawline sup {
  vertical-align: middle;
  font-size: 60%;
  top: -2px;
}

@media (min-width: 480px) {
  #treatment-section .disclaimer--treatment-areas-jawline br {
    display: none;
  }
}

#treatment-section .disclaimer--treatment-areas-jawlines-satisfaction {
  max-width: 855px;
}

@media (min-width: 480px) {
  #treatment-section .disclaimer--treatment-areas-jawlines-satisfaction br {
    display: none;
  }
}

@media (min-width: 1200px) {
  #treatment-section .disclaimer--treatment-areas-jawlines-satisfaction br {
    display: block;
  }
}

#treatment-section .disclaimer--treatment-areas-jawlines-satisfaction p {
  padding-left: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  #treatment-section .disclaimer--treatment-areas-jawlines-satisfaction p {
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .disclaimer--treatment-areas-jawlines-satisfaction p {
    padding-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .disclaimer--treatment-areas-jawlines-satisfaction sup {
  vertical-align: middle;
  font-size: 60%;
  top: -2px;
}

#treatment-section .disclaimer--treatment-areas-jawlines-tabs p {
  padding-left: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .disclaimer--treatment-areas-jawlines-tabs p {
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .disclaimer--treatment-areas-jawlines-tabs p {
    padding-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .disclaimer--jawline-skewed-container p {
  padding-left: var(--emu-common-spacing-none);
  margin-left: 20px;
}

@media (min-width: 768px) {
  #treatment-section .disclaimer--jawline-skewed-container p {
    padding-left: 35px;
  }
}

@media (min-width: 992px) {
  #treatment-section .disclaimer--jawline-skewed-container p {
    margin-left: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #treatment-section .disclaimer--jawline-skewed-container p {
    padding-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__img-map-container {
  position: relative;
}

#treatment-section .treatment-areas__img-map-disclaimer p {
  color: var(--emu-common-colors-white);
  margin-top: 5px;
  margin-bottom: var(--emu-common-spacing-none);
  text-align: center;
  font-size: 8px;
  line-height: 1.3;
  position: absolute;
  bottom: 8px;
  right: 15px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__img-map-disclaimer p {
    color: var(--emu-common-colors-black);
    font-size: 10px;
    bottom: 12px;
    right: 25px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__img-map-disclaimer p {
    right: 75px;
  }
}

#treatment-section .treatment-areas__img-map-disclaimer p sup {
  vertical-align: super;
  font-size: 80%;
  display: inline-block;
  top: 2px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__img-map-disclaimer p sup {
    top: 3px;
    left: 0;
  }
}

#treatment-section .treatment-areas__img-map-disclaimer p .emphasis {
  display: block;
}

#treatment-section .treatment-areas__img-map-disclaimer p .emphasis sup {
  left: 0;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__img-map-disclaimer p .emphasis sup {
    top: 4px;
  }
}

#treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tablist {
  display: none;
}

#treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanels {
  width: 160px;
  height: 140px;
  background-color: var(--emu-semantic-colors-secondary-grey-200);
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 30px 20px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanels {
    width: 240px;
    height: 205px;
  }
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanels {
    width: 350px;
    height: 230px;
    padding: 25px 40px 30px;
    left: 80px;
  }
}

#treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel {
  padding-top: 10px;
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel {
    padding-top: 15px;
  }
}

#treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel img {
  width: 100%;
  max-width: 80px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel img {
    max-width: 140px;
  }
}

#treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel .cmp-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel .cmp-container > .image + div {
  margin-top: 5px;
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel .cmp-container > .image + div {
    margin-top: 10px;
  }

  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel .cmp-container > .image + div.image {
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel .cmp-container > .image + div.image {
    margin-top: 15px;
  }
}

#treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel .sub-title h3 {
  font-size: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel .sub-title h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel .sub-title h3 {
    font-size: 30px;
  }
}

#treatment-section .treatment-areas__img-map-tabs .aaaem-tabs__tabpanel p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 1.4;
  font-family: var(--emu-semantic-font-families-mono);
}

#treatment-section .treatment-areas__content-tabs.aaaem-tabs {
  padding-top: 10px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__content-tabs.aaaem-tabs {
    padding: var(--emu-common-spacing-none);
    max-width: 100%;
  }
}

#treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist {
    background-color: var(--emu-semantic-colors-secondary-grey-300);
  }
}

#treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li {
  flex-basis: 32%;
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li {
    margin-bottom: var(--emu-common-spacing-none);
    flex: 1;
  }

  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li:first-child {
    padding-right: 15px;
  }

  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li:first-child a {
    clip-path: polygon(0 0, 100% 0%, calc(100% - 25px) 100%, 0% 100%);
    width: calc(100% + 15px);
    position: relative;
    transform: none;
  }

  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li:first-child a span {
    transform: translateX(-7.5px);
  }

  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li:last-child {
    padding-left: 15px;
  }

  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li:last-child a {
    width: calc(100% + 15px);
    clip-path: polygon(12% 0, 100% 0, 100% 100%, 0 100%);
    transform: translateX(-15px);
  }

  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li:last-child a span {
    padding-left: var(--emu-common-spacing-none);
    transform: translateX(7.5px);
  }
}

#treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li a {
  background-color: var(--emu-semantic-colors-secondary-grey-300);
  color: var(--emu-semantic-colors-primary-purple-500);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  padding-top: 14px;
  padding-bottom: 14px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li a {
    background-color: var(--emu-common-colors-transparent);
    transform: skew(-20deg);
  }
}

#treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li a[aria-selected="true"] {
  background-color: var(--emu-semantic-colors-primary-pink-100);
  color: var(--emu-common-colors-white);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__content-tabs.aaaem-tabs > .aaaem-tabs__tablist li a span {
    transform: skew(20deg);
  }
}

#treatment-section .treatment-areas__checklist ul {
  max-width: 80%;
  padding-top: 10px;
  padding-left: var(--emu-common-spacing-none);
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__checklist ul {
    max-width: 550px;
  }
}

#treatment-section .treatment-areas__checklist li {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: 1.4;
  font-family: var(--emu-semantic-font-families-poppins-bold);
  color: var(--emu-common-colors-black);
  margin-bottom: 20px;
  list-style: none;
  position: relative;
}

#treatment-section .treatment-areas__checklist li:before {
  content: "";
  width: 30px;
  height: 29px;
  background: url("resources/images/right-check-icon.png");
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -40px;
}

#treatment-section .treatment-areas__checklist sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 0;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__checklist sup {
    top: 4px;
  }

  #treatment-section .treatment-areas__skewed-holder--jawlines {
    max-width: 885px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__skewed-holder--jawlines {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  #treatment-section .treatment-areas__skewed-holder--jawlines.aaaem-container.cmp-container {
    margin-left: auto;
    margin-right: auto;
  }
}

#treatment-section .treatment-areas__tab-content-container {
  margin-bottom: 10px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container {
    padding-bottom: 70px;
    padding-left: 15px;
    padding-right: 15px;
  }

  #treatment-section .treatment-areas__tab-content-container > div > div:not(.full-screen-tab-content) {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }
}

#treatment-section .treatment-areas__tab-content-container .full-screen-tab-content {
  margin-left: -20px;
  margin-right: -20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .full-screen-tab-content {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container .main-title {
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .main-title {
    margin-top: 42px;
    margin-bottom: 30px;
  }

  #treatment-section .treatment-areas__tab-content-container .sub-title {
    margin-bottom: 30px;
  }
}

#treatment-section .treatment-areas__tab-content-container .sub-title br {
  display: none;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .sub-title br {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container .sub-title sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: -1px;
  left: 1px;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  margin-top: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
  margin-top: 10px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__text p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__text p sup {
  vertical-align: middle;
  font-size: 100%;
  display: inline-block;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__text p sup {
    left: 0;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__text p .emphasis sup {
  font-size: 60%;
  top: -2px;
  left: 0;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__text p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__highlighted-text {
  background-color: var(--emu-semantic-colors-primary-purple-500);
  margin-bottom: 30px;
  padding: 30px 25px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__highlighted-text {
    padding-top: 60px;
    padding-left: var(--emu-common-spacing-none);
    padding-bottom: 60px;
    padding-right: var(--emu-common-spacing-none);
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__highlighted-text {
    margin-left: -15px;
    margin-right: -15px;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__highlighted-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: 1.5;
  font-family: var(--emu-semantic-font-families-poppins-light);
  color: var(--emu-common-colors-white);
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__highlighted-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__highlighted-text br {
    display: none;
  }
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__highlighted-text .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__highlighted-text sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 1px;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__cta {
  width: 250px;
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4;
  display: flex;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__cta {
    margin-top: 60px;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__img img {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__disclaimer p {
  font-family: var(--emu-semantic-font-families-poppins-bold);
  padding-left: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-black);
  margin-top: 5px;
  margin-bottom: 23px;
  padding-top: 20px;
  font-size: 8px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__disclaimer p {
    padding-top: var(--emu-common-spacing-none);
    margin-bottom: 60px;
    padding-left: 60px;
    font-size: 13px;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after {
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after {
    margin-top: 80px;
    margin-bottom: 55px;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after > .container {
  flex-basis: 48%;
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after > .container {
    max-width: 420px;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after img {
  margin-bottom: 10px;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after h4 {
  margin: var(--emu-common-spacing-none);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  padding: var(--emu-common-spacing-small);
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  font-weight: normal;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after h4 {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after-inner-logo {
  margin-bottom: 40px;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__before-after-inner-logo img {
  margin: auto;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container-content {
  background-image: linear-gradient(to right, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
  padding: 22px 30px;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container h4 {
  margin: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  background-color: var(--emu-semantic-colors-primary-purple-500);
  color: var(--emu-common-colors-white);
  line-height: 1.4;
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  font-weight: var(--emu-semantic-font-weight-500);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container h4 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 70px;
    line-height: 1.1;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container h4 sup {
  vertical-align: middle;
  font-size: 100%;
  display: inline-block;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container h4 sup {
    left: 0;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container h5 {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-family: var(--emu-semantic-font-families-poppins-bold);
  margin-bottom: 10px;
  margin-top: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-black);
  line-height: 1.4;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container h5 sup {
  vertical-align: middle;
  font-size: 60%;
  display: inline-block;
  top: -1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container h5 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container p {
  margin-top: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }

  #treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container p .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container img {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__spl-container img {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs {
  background-image: linear-gradient(30deg, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    max-width: 100%;
    background: var(--emu-common-colors-transparent);
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs .aaaem-tabs__tablist {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs .aaaem-tabs__tablist, #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs .aaaem-tabs__tabpanels {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs .aaaem-tabs__tabpanels {
    background-image: linear-gradient(30deg, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs .aaaem-tabs__tabpanel {
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs .aaaem-tabs__tabpanel {
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 35px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs .aaaem-tabs__tabpanel {
    padding-bottom: 40px;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs p {
  text-align: center;
  margin-bottom: 18px;
  margin-top: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-black);
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    text-align: left;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs img {
  margin-left: auto;
  margin-right: auto;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs sup {
  vertical-align: middle;
  font-size: 60%;
  top: -2px;
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
  font-weight: var(--emu-semantic-font-weight-700);
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__tab-content-container .treatment-areas__inner-tabs br {
    display: none;
  }
}

#treatment-section .treatment-areas__tab-content-container .treatment-areas-midface__text b {
  color: var(--emu-semantic-colors-primary-purple-500);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--chin {
    padding-bottom: 30px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .sub-title {
  color: var(--emu-semantic-colors-secondary-dark-grey-500);
  margin-left: -10px;
  margin-right: -10px;
}

#treatment-section .treatment-areas__tab-content-container--chin .sub-title br {
  display: block;
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__tab-content-container--chin .sub-title br {
    display: none;
  }
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .sub-title .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .skewed-container__wrapper {
  margin-top: 45px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .skewed-container__wrapper {
    margin-top: 100px;
    margin-bottom: 10px;
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--chin .skewed-container__wrapper {
    max-width: 90%;
    margin-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1280px) {
  #treatment-section .treatment-areas__tab-content-container--chin .skewed-container__wrapper {
    max-width: 870px;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .skewed-container__wrapper .skewed-container__main-inner > .text {
  padding-top: 35px;
  padding-bottom: 25px;
  padding-right: 20px;
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__tab-content-container--chin .skewed-container__wrapper .skewed-container__main-inner > .text {
    padding-right: 50px;
  }
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .skewed-container__wrapper .skewed-container__main-inner > .text {
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--chin .skewed-container__wrapper .skewed-container__main-inner > .text {
    padding-left: 20px;
    padding-right: var(--emu-common-spacing-none);
    padding-top: 25px;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .disclaimer p {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .disclaimer p {
    margin-left: 20px;
    padding-left: 35px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--chin .disclaimer p {
    margin-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--chin .disclaimer p {
    padding-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__before-after {
  margin-top: 25px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__before-after {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-top: 47px;
    margin-bottom: 30px;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container {
  padding-left: var(--emu-common-spacing-xxs);
  padding-right: var(--emu-common-spacing-xxs);
  margin-top: 35px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container {
    width: 100%;
    flex-direction: row-reverse;
    margin-top: 100px;
    display: flex;
  }

  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container > div {
    flex-basis: 50%;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container img {
  margin: 20px auto 30px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container img {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p {
  font-family: var(--emu-semantic-font-families-poppins-light);
  margin-bottom: 35px;
  margin-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    margin-bottom: 65px;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p + p {
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p sup {
  vertical-align: middle;
  font-size: 100%;
  display: inline-block;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p sup {
    left: 0;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p i {
  font-style: normal;
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p i sup {
  vertical-align: middle;
  font-size: 60%;
  display: inline-block;
  top: -3px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__moldability-container p i sup {
    top: -2px;
  }
}

#treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__cta {
  margin-top: 18px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__cta {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--chin .treatment-areas__container {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .sub-title {
  color: var(--emu-semantic-colors-secondary-dark-grey-500);
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .sub-title h3 {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .sub-title h3 {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .sub-title .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent {
  margin-top: 60px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent {
    width: 80%;
    max-width: 875px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent {
    width: 100%;
  }

  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__wrapper-inner {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 1280px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__wrapper-inner {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main {
  background-image: linear-gradient(-154deg, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
  width: 100%;
  max-width: none;
  padding-left: 50px;
  padding-right: 50px;
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main-inner {
    justify-content: center;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main-inner > .text {
  padding: var(--emu-common-spacing-none);
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main-inner .cmp-text {
  max-width: 945px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main-inner .cmp-text {
    flex-direction: row;
    align-items: flex-start;
    padding-top: 35px;
    padding-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main-inner .cmp-text {
    max-width: 760px;
  }

  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main-inner .cmp-text .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main h2 {
  margin: var(--emu-common-spacing-none);
  font-size: 80px;
  font-weight: var(--emu-semantic-font-weight-500);
  text-align: center;
  white-space: nowrap;
  flex: 0 0 42%;
  line-height: 1.1;
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main h2 {
    flex: auto;
    font-size: 85px;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main h2 sup {
  vertical-align: super;
  font-size: 30px;
  display: inline-block;
  top: -14px;
  left: -14px;
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main h5 {
  font-weight: var(--emu-semantic-font-weight-500);
  text-align: center;
  margin: var(--emu-common-spacing-none);
  font-size: 20px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main h5 {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline-percent .skewed-container__main h5 .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline {
  margin-top: 45px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline {
    width: 85%;
    max-width: 842px;
    margin-top: 95px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline {
    width: 100%;
    margin-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main {
  width: 100%;
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner > .image {
  flex: none;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner > .image {
    flex: 0 0 45%;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner > .image {
    flex: none;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner > .text {
  padding: var(--emu-common-spacing-none);
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner h3 {
  line-height: 1.4;
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-weight: var(--emu-semantic-font-weight-500);
  margin: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner h3 {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner h3 {
    margin: var(--emu-common-spacing-none);
    padding-top: 45px;
    padding-bottom: 35px;
    padding-left: 35px;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner h3 br {
  display: none;
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner h3 br {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner h3 sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 0;
}

#treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner h3 .emphasis sup {
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .skewed-container__wrapper--treatment-areas-jawline .skewed-container__main-inner h3 .emphasis sup {
    left: 0;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid {
  margin-top: -10px;
  margin-left: -20px;
  margin-right: -20px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom {
  max-width: 885px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom {
    flex-direction: row;
  }

  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top > div.container, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom > div.container {
    flex: 1;
    display: flex;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right {
  max-width: 458px;
  width: 82%;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right {
    max-width: 100%;
    width: 100%;
    margin-bottom: 25px;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left:after, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right:after, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left:after, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right:after {
  content: " ";
  height: 1px;
  width: 98%;
  background-color: var(--emu-semantic-colors-secondary-dark-grey-100);
  margin: auto;
  position: absolute;
  inset: 100% 0 -45px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left:after, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right:after, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left:after, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right:after {
    width: 1px;
    height: 270px;
    top: -40px;
    left: 100%;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-image, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-image, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-image, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-image {
  margin-bottom: 15px;
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-image img, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-image img, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-image img, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-image img {
  margin-left: auto;
  margin-right: auto;
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-text, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-text, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-text, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-text {
  max-width: 82%;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-text, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-text, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-text, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-text {
    max-width: 100%;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-text p, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-text p, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-text p, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  margin-bottom: 20px;
  margin: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
  line-height: 1.4;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-text p, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-text p, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-text p, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-text p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-text p br, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-text p br, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-text p br, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-text p br {
  display: none;
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-text p br, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-text p br, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-text p br, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-text p br {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-text p sup, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-text p sup, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-text p sup, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-text p sup {
  vertical-align: super;
  top: 4px;
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-left .aaaem-text p b, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right .aaaem-text p b, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-left .aaaem-text p b, #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right .aaaem-text p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top {
    padding-top: 25px;
    padding-bottom: 35px;
    position: relative;
  }

  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--emu-semantic-colors-secondary-dark-grey-100);
    position: absolute;
    top: 100%;
    left: 0;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-top-right:after {
    display: none;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom {
    margin-top: 12px;
  }
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right {
  margin-bottom: var(--emu-common-spacing-none);
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-percent-grid-bottom-right:after {
  display: none;
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-tab-title {
  margin-bottom: 30px;
}

#treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-tab-title br {
  display: block;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-tab-title h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--jawline .treatment-areas__jawline-tab-title h3 {
    font-size: 30px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines {
  padding-bottom: 20px;
}

#treatment-section .treatment-areas__tab-content-container--lines .main-title sup {
  vertical-align: super;
  font-size: 90%;
  top: 10px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .main-title sup {
    top: 6px;
    left: 2px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--lines .main-title sup {
    top: 14px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .sub-title br {
  display: block;
}

#treatment-section .treatment-areas__tab-content-container--lines .sub-title h3 {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .sub-title h3 {
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container.aaaem-container {
    max-width: none;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container p {
  margin-top: 5px;
  margin-bottom: 30px;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container sup {
  vertical-align: middle;
  font-size: 100%;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container sup {
    left: 0;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container .emphasis sup {
  font-size: 60%;
  top: -2px;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container img {
  margin-left: auto;
  margin-right: auto;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container-inner {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container-inner {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container-inner--bottom {
  margin-top: 30px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container-inner--bottom {
    margin-top: 70px;
    margin-bottom: 60px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container .disclaimer {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container .disclaimer {
    max-width: none;
    margin-left: -15px;
    margin-right: -15px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container .disclaimer p {
  padding-top: 15px;
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  margin-top: 10px;
  margin-right: var(--emu-common-spacing-none);
  max-width: 885px;
  margin-bottom: 30px;
  margin-left: auto;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__lines-gel-container .disclaimer p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after {
  margin-top: var(--emu-common-spacing-none);
  flex-flow: column wrap;
  justify-content: center;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after {
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after.aaaem-container {
    max-width: 805px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-left: auto;
    margin-right: auto;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after > .container {
  max-width: none;
  flex: 0 0 50%;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after img {
  margin-bottom: var(--emu-common-spacing-none);
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-inner {
  background-color: var(--emu-common-colors-white);
  margin-top: 10px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px 25px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-inner {
    margin-top: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-none);
    margin-right: 30px;
    padding: 40px 60px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-inner--bottom {
  margin-top: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-inner--bottom {
    margin-top: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc {
  margin-left: -20px;
  margin-right: -20px;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p {
  max-width: 85%;
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    max-width: 100%;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p sup, #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p .emphasis sup {
  vertical-align: middle;
  font-size: 100%;
  top: 1px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p sup, #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p .emphasis sup {
    top: 0;
    left: 0;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p sup, #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p .emphasis sup {
    top: -1px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc p b sup {
  font-family: var(--emu-semantic-font-families-body);
  vertical-align: middle;
  font-size: 60%;
  top: -2px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__before-after-desc .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__cta {
  margin-top: 65px;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__cta {
    margin-top: 55px;
  }

  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .disclaimer {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  margin-left: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .disclaimer {
    margin-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .disclaimer p {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 3px;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .disclaimer p sup {
  vertical-align: middle;
  font-size: 60%;
  top: -2px;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines > .container {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines > .container {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h4 {
  font-size: 20px;
  line-height: 1.1;
  font-weight: var(--emu-semantic-font-weight-500);
  color: var(--emu-semantic-colors-primary-purple-500);
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h4 {
    font-size: 26px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h4 sup {
  vertical-align: super;
  font-size: 48%;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h4 sup {
    left: 0;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h5 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  max-width: 85%;
  line-height: 1.4;
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  color: var(--emu-semantic-colors-primary-purple-500);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h5 {
    max-width: 100%;
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h5 {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h5 .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h5 sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 0;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines h5 sup {
    top: -1px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines ul {
  margin-top: var(--emu-common-spacing-none);
  padding-top: 10px;
  padding-left: var(--emu-common-spacing-none);
  margin-bottom: 10px;
  margin-left: 40px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines ul {
    margin-left: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines li {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  color: var(--emu-common-colors-black);
  margin-bottom: 5px;
  line-height: 1.5;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines li {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines li sup {
  vertical-align: super;
  font-size: 90%;
  display: inline-block;
  top: 5px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines li sup {
    top: 6px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines li .emphasis sup {
  vertical-align: super;
  font-size: 48%;
  display: inline-block;
  top: 4px;
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .skewed-container__wrapper {
    width: 90%;
  }
}

@media (min-width: 1280px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .skewed-container__wrapper {
    width: 100%;
    max-width: 870px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .skewed-container__main-inner {
  display: flex;
}

#treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .skewed-container__main-inner > .text {
  max-width: 80%;
  padding-top: 20px;
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: 25px;
  padding-left: var(--emu-common-spacing-none);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lines .treatment-areas__skewed-holder--lines .skewed-container__main-inner > .text {
    max-width: 100%;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .main-title sup {
  vertical-align: super;
  font-size: 90%;
  top: 10px;
  left: 2px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .main-title sup {
    top: 6px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--lips .main-title sup {
    top: 14px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips-skewed-container p {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips-skewed-container p {
    margin-left: 20px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips-skewed-container p {
    margin-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips p {
  margin-top: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  margin-bottom: 3px;
}

#treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips p .emphasis sup {
  font-size: 60%;
  top: -2px;
}

#treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips u {
  text-decoration: none;
  display: none;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips u {
    margin-left: var(--emu-common-spacing-xxs);
    display: inline-block;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips br {
  display: none;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .disclaimer--lips br {
    display: block;
  }

  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 900px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__wrapper {
    max-width: 90%;
  }
}

@media (min-width: 1280px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__wrapper {
    max-width: 870px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner > .image {
    flex: initial;
    min-width: 399px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner > .text {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner > .text {
    padding-top: 34px;
    padding-bottom: 34px;
    padding-left: 40px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner h3 {
  margin: 5px;
  line-height: 1.32;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner h3 {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner h3 {
    margin-top: 10px;
    margin-right: var(--emu-common-spacing-none);
    margin-bottom: 10px;
    margin-left: var(--emu-common-spacing-none);
  }

  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner h3 .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner h3 i {
  font-style: normal;
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner h3 i sup {
  font-size: 48%;
  top: 0;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__skewed-holder--lips .skewed-container__main-inner h3 i sup {
    top: 1px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc {
  margin-top: 18px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc {
    margin-top: 40px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc--bottom {
  margin-top: 60px;
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .disclaimer {
  margin-left: -20px;
  margin-right: -20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .disclaimer {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 900px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .disclaimer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .disclaimer {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .disclaimer p {
  padding-left: var(--emu-common-spacing-none);
  margin-top: 20px;
  margin-left: 25px;
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .disclaimer p {
    margin-left: 60px;
    font-size: 13px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .disclaimer p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .treatment-areas__text p {
  margin-top: 5px;
  margin-bottom: var(--emu-common-spacing-none);
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc .treatment-areas__text .emphasis sup {
  font-size: 50%;
  top: -1px;
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc img {
  margin-top: 18px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-product-desc img {
    margin-top: 40px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-spl-container {
  margin-top: 50px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-spl-container {
    margin-top: 75px;
    margin-bottom: 35px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-spl-container img {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-spl-container img {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-spl-container h4 {
  padding-top: var(--emu-common-spacing-xs);
  padding-right: 23px;
  padding-bottom: var(--emu-common-spacing-xs);
  padding-left: 23px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-spl-container h4 {
    padding: 18px 55px;
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-spl-container h4 sup {
  vertical-align: middle;
  font-size: 50%;
  top: -4px;
  left: -4px;
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-graph-desc p {
  margin-top: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  margin-bottom: 10px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-graph-desc p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-graph-desc p b {
  color: var(--emu-semantic-colors-primary-purple-500);
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-graph-desc p i {
  font-style: none;
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-graph-desc p i sup {
  vertical-align: middle;
  font-size: 60%;
  top: -2px;
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-ha-diff {
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-ha-diff {
    padding-top: 60px;
    padding-bottom: 95px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__lips-ha-diff img {
  margin-left: auto;
  margin-right: auto;
}

#treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__cta {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--lips .treatment-areas__cta {
    margin-bottom: 60px;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .sub-title {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .sub-title br {
  display: block;
}

@media (min-width: 480px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .sub-title br {
    display: none;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .sub-title sup {
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .sub-title sup {
    top: -1px;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .main-title, #treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer {
  margin-left: -20px;
  margin-right: -20px;
}

#treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer p {
  color: var(--emu-common-colors-black);
  padding-left: var(--emu-common-spacing-none);
  margin-top: 20px;
  margin-left: 25px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer p {
    margin-left: 60px;
    font-size: 13px;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

#treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer--undereyes {
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
}

#treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer--undereyes p {
  max-width: 85%;
  margin: 10px auto 3px 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer--undereyes p {
    max-width: none;
    margin-right: var(--emu-common-spacing-none);
    padding-left: 35px;
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer--undereyes p {
    margin-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer--undereyes p {
    padding-left: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer--undereyes-last p {
  max-width: 85%;
  margin-bottom: 3px;
  margin-left: 20px;
  margin-top: var(--emu-common-spacing-none);
  padding-top: 30px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer--undereyes-last p {
    max-width: 100%;
    margin-left: auto;
    padding-top: 70px;
    padding-bottom: 25px;
    font-size: 12px;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .disclaimer--undereyes-last p sup {
  font-size: 60%;
  top: -4px;
}

#treatment-section .treatment-areas__tab-content-container--undereyes img {
  margin-left: auto;
  margin-right: auto;
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text {
  margin-left: -20px;
  margin-right: -20px;
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text p {
  max-width: 90%;
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text p {
    max-width: 100%;
    margin-top: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    margin-bottom: 10px;
  }

  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text p .emphasis {
    display: block;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text p sup {
  vertical-align: middle;
  font-size: 100%;
  top: -1px;
  left: 1px;
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text p i {
  font-style: normal;
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text p i sup {
  font-size: 60%;
  top: -2px;
  left: 0;
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text p u {
  text-decoration: none;
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__text p u sup {
  font-size: 50%;
  top: -1px;
  left: 0;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 900px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__wrapper {
  margin-top: 50px;
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__wrapper {
    max-width: 90%;
  }
}

@media (min-width: 1280px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__wrapper {
    max-width: 870px;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner > .image img {
  margin-left: var(--emu-common-spacing-none);
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner > .image {
    flex: initial;
    min-width: 360px;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner > .text {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner > .text {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner h3 {
    font-size: 24px;
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner h5 {
  margin: 5px;
  font-size: 12px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner h5 {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__skewed-holder--undereyes .skewed-container__main-inner h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

#treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__cta {
  margin-top: 30px;
}

@media (min-width: 768px) {
  #treatment-section .treatment-areas__tab-content-container--undereyes .treatment-areas__cta {
    margin-top: 60px;
  }
}

.cq-Editable-dom--container .treatment-areas__img-map-tabs, .cq-Editable-dom--container .aaaem-tabs__tabpanels {
  position: static !important;
  transform: none !important;
}

#new-customers-section {
  margin-top: 50px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #new-customers-section {
    margin-top: 100px;
    margin-bottom: 80px;
  }
}

#new-customers-section .new-customer__contact-section {
  flex-flow: column wrap;
  justify-content: center;
  align-items: stretch;
  margin-top: 30px;
  display: flex;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__contact-section {
    flex-flow: row;
    align-items: center;
  }
}

#new-customers-section .new-customer__contact-section a, #new-customers-section .new-customer__contact-section a:hover, #new-customers-section .new-customer__contact-section a:active, #new-customers-section .new-customer__contact-section a:focus {
  color: inherit;
  text-decoration: none;
}

#new-customers-section .new-customer__contact-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  text-align: center;
  line-height: 30px;
  font-family: var(--emu-semantic-font-families-poppins-light);
  color: var(--emu-semantic-colors-primary-purple-500);
  font-weight: var(--emu-semantic-font-weight-500);
  text-align: center;
  margin-top: 5px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__contact-text p {
    text-align: left;
    margin-bottom: 5px;
    line-height: 45px;
  }
}

@media (min-width: 992px) {
  #new-customers-section .new-customer__contact-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    text-align: left;
  }
}

#new-customers-section .new-customer__contact-text p b {
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  line-height: 1.3;
  font-weight: var(--emu-semantic-font-weight-700);
}

#new-customers-section .new-customer__contact-text p .emphasis {
  display: block;
}

@media (min-width: 480px) {
  #new-customers-section .new-customer__contact-text p .emphasis {
    display: inline;
  }
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__contact-text p .emphasis {
    display: block;
  }
}

@media (min-width: 480px) {
  #new-customers-section .new-customer__contact-text p br {
    display: none;
  }
}

#new-customers-section .new-customer__contact-text p sup {
  vertical-align: super;
  font-size: 90%;
  top: 5px;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__contact-text p sup {
    top: 6px;
  }
}

#new-customers-section .new-customer__contact-num {
  background-image: linear-gradient(to left, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__contact-num {
    width: 300px;
    margin-left: 20px;
    margin-bottom: var(--emu-common-spacing-none);
    transform: skew(-24deg);
  }
}

@media (min-width: 1200px) {
  #new-customers-section .new-customer__contact-num {
    width: auto;
    max-width: 400px;
  }
}

#new-customers-section .new-customer__contact-num h5 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-purple-500);
  font-size: 22px;
  line-height: 1.1;
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-semantic-font-weight-700);
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__contact-num h5 {
    padding: 22px 50px;
    transform: skew(24deg);
  }
}

@media (min-width: 1200px) {
  #new-customers-section .new-customer__contact-num h5 {
    padding-left: 80px;
    padding-right: 80px;
    font-size: 24px;
  }
}

#new-customers-section .new-customer__services-wrapper {
  max-width: 905px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__services-wrapper {
    flex-wrap: nowrap;
    margin: auto;
  }
}

#new-customers-section .new-customer__services-wrapper a, #new-customers-section .new-customer__services-wrapper a:hover, #new-customers-section .new-customer__services-wrapper a:active, #new-customers-section .new-customer__services-wrapper a:focus {
  color: inherit;
  text-decoration: none;
}

#new-customers-section .new-customer__services-wrapper > .container {
  margin-top: var(--emu-common-spacing-xxs);
  flex-flow: column wrap;
  flex: 0 0 100%;
  padding-top: 15px;
  padding-bottom: 5px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__services-wrapper > .container {
    flex: 0 0 34%;
    padding-top: 30px;
    padding-bottom: 55px;
  }
}

#new-customers-section .new-customer__services-wrapper > .container:not(:last-child) {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__services-wrapper > .container:not(:last-child) {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#new-customers-section .new-customer__services-wrapper > .container:not(:last-child):after {
  content: "";
  width: 36%;
  height: var(--emu-semantic-border-width-medium);
  background-color: var(--emu-semantic-colors-primary-pink-100);
  margin: auto;
  display: block;
  position: absolute;
  inset: 0 0 -166px;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__services-wrapper > .container:not(:last-child):after {
    width: var(--emu-semantic-border-width-medium);
    height: 102px;
    margin: 0;
    top: 28px;
    bottom: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  #new-customers-section .new-customer__services-wrapper > .container:not(:last-child):after {
    top: 42px;
  }
}

#new-customers-section .new-customer__services-wrapper > .container:nth-child(2) .new-customer__service-phone p {
  font-family: var(--emu-semantic-font-families-poppins-light);
}

@media (min-width: 1280px) {
  #new-customers-section .new-customer__services-wrapper > .container:nth-child(2) .new-customer__service-phone p {
    font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  }
}

#new-customers-section .new-customer__services-wrapper > .container:last-child {
  margin-top: var(--emu-common-spacing-none);
}

#new-customers-section .new-customer__services-wrapper > .container:last-child .new-customer__service-title h5 {
  margin-top: 6px;
  margin-bottom: 5px;
  line-height: 1.3;
}

#new-customers-section .new-customer__service {
  margin-top: 5px;
  margin-bottom: 5px;
}

#new-customers-section .new-customer__service h5 {
  color: var(--emu-semantic-colors-primary-purple-500);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  line-height: 1.405;
  font-weight: var(--emu-semantic-font-weight-700);
}

@media (min-width: 992px) {
  #new-customers-section .new-customer__service h5 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

#new-customers-section .new-customer__service-copy--desk {
  display: none;
}

@media (min-width: 1280px) {
  #new-customers-section .new-customer__service-copy--desk {
    display: block;
  }

  #new-customers-section .new-customer__service-copy--mob {
    display: none;
  }
}

#new-customers-section .new-customer__service-phone p {
  color: var(--emu-semantic-colors-primary-pink-100);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-weight: var(--emu-semantic-font-weight-700);
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  margin-top: 3px;
  margin-bottom: 6px;
}

@media (min-width: 992px) {
  #new-customers-section .new-customer__service-phone p {
    margin-bottom: var(--emu-common-spacing-xs);
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: 1.5;
  }
}

#new-customers-section .new-customer__service-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  color: var(--emu-semantic-colors-primary-purple-500);
  font-family: var(--emu-semantic-font-families-poppins-light);
  line-height: 1.5;
}

@media (min-width: 992px) {
  #new-customers-section .new-customer__service-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

#new-customers-section .new-customer__service-text .emphasis {
  display: block;
}

#new-customers-section .new-customer__insta {
  max-width: 700px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20px;
  display: flex;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__insta {
    padding-top: var(--emu-common-spacing-none);
    flex-direction: row;
  }
}

#new-customers-section .new-customer__insta-content {
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__insta-content {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#new-customers-section .new-customer__insta-content img {
  width: 40px;
  height: auto;
  vertical-align: middle;
  margin-right: 20px;
  display: inline-block;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__insta-content img {
    width: auto;
    margin-right: var(--emu-common-spacing-none);
  }
}

#new-customers-section .new-customer__insta-content h3 {
  line-height: 1.4;
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  color: var(--emu-semantic-colors-secondary-dark-grey-500);
  font-family: var(--emu-semantic-font-families-mono);
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__insta-content h3 {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (min-width: 992px) {
  #new-customers-section .new-customer__insta-content h3 {
    font-size: 30px;
  }
}

#new-customers-section .new-customer__insta-content h3 sup {
  vertical-align: super;
  font-size: 48%;
  top: -1px;
  left: 1px;
}

@media (min-width: 768px) {
  #new-customers-section .new-customer__insta-content h3 sup {
    left: 0;
  }
}

#new-customers-section .new-customer__insta-cta {
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  padding: 10px 40px 10px 22px;
  line-height: 1.4;
}

#new-customers-section .new-customer__insta-cta:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url("resources/images/arrow-icon.png") no-repeat;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3px;
}

.cq-Editable-dom--container .new-customer__service-copy--mob, .cq-Editable-dom--container .new-customer__service-copy--desk {
  display: block !important;
}

#error-page .error-page--404 .error-text h1 {
  margin: var(--emu-common-spacing-none);
  text-align: center;
  color: var(--emu-semantic-colors-primary-purple-500);
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #error-page .error-page--404 .error-text h1 {
    text-align: left;
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
  }
}

@media (min-width: 992px) {
  #error-page .error-page--404 .error-text h1 {
    font-size: 48px;
    line-height: 57.6px;
  }
}

#error-page .error-page--404 .error-text p {
  margin: var(--emu-common-spacing-none);
  text-align: center;
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
  margin-bottom: 10px;
  line-height: 19.6px;
}

@media (min-width: 768px) {
  #error-page .error-page--404 .error-text p {
    text-align: left;
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

@media (min-width: 992px) {
  #error-page .error-page--404 .error-text p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#error-page .error-page--404 .error-cta {
  padding-left: 9px;
  padding-top: var(--emu-common-spacing-xxs);
  justify-content: center;
  line-height: 19.6px;
  text-decoration: none;
  display: flex;
}

@media (min-width: 768px) {
  #error-page .error-page--404 .error-cta {
    justify-content: flex-start;
    padding-left: 12px;
  }
}

#error-page .error-page--404 .error-cta .chevron {
  display: inline-block;
  transform: rotate(90deg)translate(-3px, 10px);
}

#error-page .error-page--404 .error-cta .chevron svg {
  stroke: var(--emu-semantic-colors-primary-pink-100);
  fill: var(--emu-semantic-colors-primary-pink-100);
  stroke-width: .5px;
  width: 18px;
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  display: block;
}

@media (min-width: 992px) {
  #error-page .error-page--404 .error-cta .chevron svg {
    stroke-width: 1px;
  }
}

#error-page .error-page--404 .error-cta .cmp-button__text {
  color: var(--emu-semantic-colors-primary-pink-100);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  display: block;
  transform: translateX(2px)translateY(-1px);
}

@media (min-width: 768px) {
  #error-page .error-page--404 .error-cta .cmp-button__text {
    transform: translateX(2px)translateY(0);
  }
}

@media (min-width: 992px) {
  #error-page .error-page--404 .error-cta .cmp-button__text {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-xl);
    transform: translateX(2px)translateY(-1px);
  }
}

#error-page .error-page--404 .js-toggle-on .chevron svg {
  transform: none;
}

#error-page .error-page--500 {
  max-width: 65%;
  background-color: #ebebeb80;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 768px) {
  #error-page .error-page--500 {
    max-width: 100%;
  }
}

#error-page .error-page--500 .error-text h1 {
  color: var(--emu-common-colors-black);
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-common-sizing-medium);
  line-height: var(--emu-semantic-line-heights-wide-xxl);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 20px;
}

#error-page .error-page--500 .error-text h2 {
  color: var(--emu-common-colors-black);
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-wide-medium);
  line-height: var(--emu-semantic-line-heights-wide-medium);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 10px;
}

#error-page .error-page--500 .error-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 10px;
}

#error-page .error-page--500 .error-text p a {
  color: #337ab7;
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  text-decoration: none;
}

@media (min-width: 768px) {
  #error-page .error-page--500 .error-text p a {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

#sitemap .main-container {
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  #sitemap .main-container {
    padding-bottom: 60px;
  }
}

#sitemap .sitemap__text h1 {
  margin-top: var(--emu-common-spacing-none);
  font-family: var(--emu-semantic-font-families-mono);
  color: var(--emu-semantic-colors-secondary-dark-grey-500);
  font-size: var(--emu-semantic-font-sizes-wide-medium);
  font-weight: var(--emu-semantic-font-weight-500);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #sitemap .sitemap__text h1 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

@media (min-width: 992px) {
  #sitemap .sitemap__text h1 {
    font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
  }
}

#sitemap .sitemap__text p {
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  margin-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-xxs);
}

@media (min-width: 768px) {
  #sitemap .sitemap__text p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    padding-bottom: 5px;
  }
}

@media (min-width: 992px) {
  #sitemap .sitemap__text p {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#sitemap .sitemap__text a {
  color: var(--emu-semantic-colors-primary-pink-100);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  letter-spacing: normal;
  margin-bottom: 5px;
  line-height: 19.6px;
  text-decoration: none;
  display: inline-block;
}

#sitemap .sitemap__text a:focus {
  text-decoration: underline;
}

@media (min-width: 992px) {
  #sitemap .sitemap__text a {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    margin-bottom: 7px;
    line-height: 25.2px;
  }
}

#sitemap .sitemap__text a sup {
  font-size: 13px;
  left: -1px;
}

@media (min-width: 992px) {
  #sitemap .sitemap__text a sup {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

#sitemap .sitemap__text ul {
  margin-top: 22px;
  margin-bottom: var(--emu-common-spacing-large);
  padding-left: var(--emu-common-spacing-none);
  line-height: 19px;
  list-style: none;
}

#sitemap .sitemap__text li {
  padding-bottom: var(--emu-common-spacing-xxs);
}

@media (min-width: 768px) {
  #sitemap .sitemap__text li {
    padding-bottom: 5px;
  }
}

@media (min-width: 992px) {
  #sitemap .sitemap__text li {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

.backToTop .aaaem-back-to-top-button {
  color: var(--emu-semantic-colors-primary-pink-100);
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  font-family: var(--emu-semantic-font-families-mono);
  opacity: 0;
  pointer-events: none;
  max-width: 32px;
  background-image: url("resources/images/back-to-top-icon.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 28px 23px;
  text-decoration: none;
  transition: opacity .3s linear;
  display: flex;
}

@media (min-width: 768px) {
  .backToTop .aaaem-back-to-top-button {
    max-width: 70px;
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    background-size: 47px 40px;
    line-height: 16px;
    right: 20px;
    transform: translateY(-8px);
  }
}

.backToTop .aaaem-back-to-top-button.show {
  opacity: 1;
  pointer-events: initial;
}

.backToTop .aaaem-back-to-top-button span {
  margin-top: 27px;
}

@media (min-width: 768px) {
  .backToTop .aaaem-back-to-top-button span {
    margin-top: 47px;
  }
}

[data-component="container-isi-v2"].isi-scrollable .isi-container {
  z-index: var(--emu-component-containers-isi-z-index);
}

[data-component="container-isi-v2"] p sup {
  vertical-align: middle;
  font-size: 100%;
  top: -1px;
}

[data-component="container-isi-v2"] [data-ref="content-footer"] {
  padding-top: 40px;
}

[data-component="container-isi-v2"] .aaaem-isi-banner {
  padding-top: var(--emu-common-spacing-none);
  margin-top: 10px;
  position: relative;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner {
    margin-top: 15px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button {
  padding: var(--emu-common-spacing-none);
  font-size: 11px;
  line-height: 17px;
  font-family: var(--emu-semantic-font-families-poppins-bold);
  color: var(--emu-semantic-colors-primary-pink-100);
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 21px;
  }
}

@media (min-width: 992px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button {
    font-size: 13px;
    line-height: var(--emu-semantic-line-heights-wide-medium);
  }
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button:focus {
  color: var(--emu-semantic-colors-secondary-blue-200);
  text-decoration: underline;
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button:focus .cmp-button__text:after {
  background-color: var(--emu-semantic-colors-secondary-blue-200);
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button:hover {
  color: var(--emu-semantic-colors-primary-pink-100);
  text-decoration: none;
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button:hover .cmp-button__text:after {
  background-color: var(--emu-semantic-colors-primary-pink-100);
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button .cmp-button__text {
  padding-left: var(--emu-common-spacing-medium);
  white-space: nowrap;
  border-left: 1px solid var(--emu-semantic-colors-secondary-dark-grey-500);
  margin-left: 5px;
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button .cmp-button__text:after {
  content: "";
  width: 11px;
  height: 2px;
  background-color: var(--emu-semantic-colors-primary-pink-100);
  vertical-align: middle;
  margin-left: 3px;
  display: inline-block;
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button.js-toggle-on .cmp-button__text {
  padding-right: var(--emu-common-spacing-none);
}

[data-component="container-isi-v2"] .aaaem-isi-banner .aaaem-button.js-toggle-on .cmp-button__text:after {
  display: none;
}

[data-component="container-isi-v2"] .aaaem-container, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
  background-color: var(--emu-common-colors-transparent);
}

[data-component="container-isi-v2"] > .aaaem-isi-container__isi-content {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

@media (min-width: 992px) {
  [data-component="container-isi-v2"] > .aaaem-isi-container__isi-content {
    max-width: 68%;
    margin-left: 210px;
    margin-right: 20px;
  }
}

@media (min-width: 1025px) {
  [data-component="container-isi-v2"] > .aaaem-isi-container__isi-content {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  [data-component="container-isi-v2"] > .aaaem-isi-container__isi-content {
    max-width: 73%;
    margin-left: auto;
    margin-right: 120px;
  }
}

@media (min-width: 1280px) {
  [data-component="container-isi-v2"] > .aaaem-isi-container__isi-content {
    max-width: 76%;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container {
  max-height: 170px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .aaaem-isi-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  [data-component="container-isi-v2"] .aaaem-isi-container {
    padding-right: 45px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content {
  max-width: 80%;
  padding-top: 18px;
  display: block !important;
}

@media (min-width: 480px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content {
    padding-top: var(--emu-common-spacing-none);
    max-width: none;
    flex: 1;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content p {
  margin: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-purple-600);
  font-size: 13px;
  line-height: 17px;
  font-weight: var(--emu-semantic-font-weight-500);
  font-family: var(--emu-semantic-font-families-poppins-bold);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content p {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: 23px;
  }
}

@media (min-width: 480px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content p br {
    display: none;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper {
  flex-direction: row-reverse;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 480px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper {
    position: static;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper > .read-more-button {
  white-space: nowrap;
  padding-right: var(--emu-common-spacing-medium);
  margin-right: 5px;
  text-decoration: none;
}

@media (min-width: 992px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper > .read-more-button {
    padding-left: 10px;
    padding-right: 10px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper > .read-more-button:focus {
  text-decoration: none;
}

@media (min-width: 992px) {
  [data-component="container-isi-v2"] .isi-container {
    z-index: var(--emu-common-other-z-index-header);
  }
}

[data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content {
  height: 100%;
  transition-duration: 0s;
  overflow: hidden;
}

[data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content .hcpj-isi__title {
  display: none;
}

[data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content p, [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content li, [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content .emphasis {
  font-size: 13px;
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content p, [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content li, [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content .emphasis {
    font-size: var(--emu-common-font-sizes-wide-medium);
    margin-bottom: var(--emu-common-spacing-none);
    line-height: 22px;
  }
}

[data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content h4 {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: 21px;
  font-family: var(--emu-semantic-font-families-poppins-bold);
  font-weight: var(--emu-semantic-font-weight-700);
  margin-bottom: 10px;
}

[data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content .hcpj-isi__inner-container {
  max-width: 100%;
}

[data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content.js-expand {
  height: 20px;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content.js-expand {
    height: 25px;
  }
}

.hcpj-isi__inner-container .aaaem-text {
  font-size: 13px;
  line-height: var(--emu-semantic-line-heights-narrow-large);
}

@media (min-width: 768px) {
  .hcpj-isi__inner-container .aaaem-text {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

.hcpj-isi__inner-container .aaaem-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .hcpj-isi__inner-container .aaaem-text p {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  .hcpj-isi__inner-container .aaaem-text p {
    margin-bottom: 15px;
  }
}

.hcpj-isi__inner-container .aaaem-text h4 {
  font-size: 13px;
  line-height: var(--emu-semantic-line-heights-wide-medium);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-xs);
  font-family: var(--emu-semantic-font-families-poppins-bold);
  color: var(--emu-common-colors-black);
  font-weight: var(--emu-semantic-font-weight-400);
}

@media (min-width: 768px) {
  .hcpj-isi__inner-container .aaaem-text h4 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: 30px;
  }
}

.hcpj-isi__inner-container .aaaem-text ul {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .hcpj-isi__inner-container .aaaem-text ul {
    padding-left: var(--emu-common-spacing-large);
  }
}

.hcpj-isi__inner-container .aaaem-text ul li {
  margin-bottom: 6px;
}

.hcpj-isi__inner-container .aaaem-text a {
  text-decoration: none;
}

.hcpj-isi__inner-container .hcpj-isi__title {
  font-size: 13px;
  line-height: 16px;
  font-weight: var(--emu-semantic-font-weight-700);
  color: var(--emu-semantic-colors-primary-purple-500);
}

@media (min-width: 768px) {
  .hcpj-isi__inner-container .hcpj-isi__title {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: 24px;
  }
}

.hcpj-isi__inner-container .hcpj-isi__title p {
  font-family: var(--emu-semantic-font-families-poppins-bold);
  margin-bottom: var(--emu-common-spacing-xs);
}

.hcpj-isi__inner-container > .text:nth-child(3) b a {
  color: var(--emu-semantic-colors-primary-purple-300);
  text-decoration: underline;
}

.hcpj-isi__inner-container > .text:nth-child(3) b a:active, .hcpj-isi__inner-container > .text:nth-child(3) b a:hover {
  text-decoration: none;
}

.hcpj-isi__inner-container > .text:nth-child(3) b a:focus, .hcpj-isi__inner-container > .text:nth-child(3) b a:hover {
  color: var(--emu-semantic-colors-secondary-blue-200);
}

.hcpj-isi__inner-container > .text:last-child p {
  margin-bottom: 18px;
}

@media (min-width: 768px) {
  .hcpj-isi__inner-container > .text:last-child p {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

@media (min-width: 992px) {
  .hcpj-isi__inner-container > .text:last-child p {
    margin-bottom: 23px;
  }
}

.hcpj-isi__inner-container > .text:last-child p:last-child {
  margin-bottom: var(--emu-common-spacing-small);
}

.hcpj-isi__inner-container > .text:last-child p a {
  color: var(--emu-semantic-colors-secondary-blue-100);
  text-decoration: underline;
}

.hcpj-isi__inner-container > .text:last-child p a:hover {
  color: var(--emu-semantic-colors-secondary-blue-200);
}

.hcpj-isi__primary-text .emphasis {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 16px;
}

@media (min-width: 768px) {
  .hcpj-isi__primary-text .emphasis {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

.hcpj-isi__secondary-text {
  padding-top: var(--emu-common-spacing-small);
  padding-right: 5px;
}

.hcpj-isi__secondary-text p b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
  font-weight: var(--emu-semantic-font-weight-700);
}

.hcpj-isi__secondary-text p b > .emphasis {
  display: inline-block;
}

.hcpj-isi__secondary-text p a > .emphasis {
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
}

@media (min-width: 1280px) {
  .hcpj-isi__secondary-text p a > .emphasis {
    font-family: var(--emu-semantic-font-families-poppins-bold);
    font-weight: var(--emu-semantic-font-weight-700);
  }
}

.footer-in-viewport .aaaem-isi-container {
  visibility: hidden;
}

[data-component="cookie-consent-banner"].emu-consent-banner.js-visible {
  bottom: 0;
}

@media (min-width: 992px) {
  .hcpj-footer__bottom-wrapper {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
  }

  .hcpj-footer__bottom-wrapper > .image {
    flex: 1;
  }

  .hcpj-footer__bottom-wrapper > .container {
    flex: 2.3;
  }
}

@media (min-width: 1410px) {
  .hcpj-footer__bottom-wrapper > .container {
    flex: 2;
  }
}

.hcpj-footer__bottom-wrapper .aaaem-image {
  margin-right: var(--emu-common-spacing-small);
}

@media (min-width: 992px) {
  .hcpj-footer__bottom-wrapper .aaaem-image {
    margin-right: var(--emu-common-spacing-none);
  }
}

.hcpj-footer__bottom-wrapper .aaaem-image img {
  width: 358px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1295px) {
  .hcpj-footer__bottom-wrapper .aaaem-image img {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    margin-right: var(--emu-common-spacing-small);
  }
}

.hcpj-footer__content a, .hcpj-footer__content p {
  font-family: var(--emu-semantic-font-families-poppins-light);
}

.hcpj-footer__content ul {
  padding-left: var(--emu-common-spacing-none);
  margin-top: 12px;
  margin-bottom: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  text-align: center;
}

@media (min-width: 460px) {
  .hcpj-footer__content ul {
    text-align: unset;
  }
}

@media (min-width: 768px) {
  .hcpj-footer__content ul {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: 23px;
  }
}

@media (min-width: 992px) {
  .hcpj-footer__content ul {
    margin-bottom: var(--emu-common-spacing-none);
    white-space: nowrap;
  }
}

.hcpj-footer__content ul li {
  list-style: none;
  display: inline-block;
}

.hcpj-footer__content ul li:first-child a {
  padding-left: var(--emu-common-spacing-none);
}

.hcpj-footer__content ul li:last-child a {
  padding-right: var(--emu-common-spacing-none);
}

.hcpj-footer__content ul li:last-child a:after {
  display: none;
}

.hcpj-footer__content ul li a {
  color: var(--emu-common-colors-black);
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  grid-gap: 10px;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.hcpj-footer__content ul li a img {
  max-width: 40px;
}

@media (min-width: 992px) {
  .hcpj-footer__content ul li a {
    padding-left: var(--emu-common-spacing-xs);
  }
}

.hcpj-footer__content ul li a:after {
  content: "";
  width: var(--emu-common-border-width-thin);
  height: 14px;
  background-color: var(--emu-common-colors-black);
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.hcpj-footer__content ul li a:focus {
  text-decoration: underline;
}

.hcpj-footer__content .hcpj-footer__bottom-section {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  color: var(--emu-common-colors-black);
  line-height: 17px;
}

@media (min-width: 768px) {
  .hcpj-footer__content .hcpj-footer__bottom-section {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: var(--emu-semantic-line-heights-narrow-xxl);
  }
}

.hcpj-footer__content .hcpj-footer__bottom-section p {
  margin-top: 25px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .hcpj-footer__content .hcpj-footer__bottom-section p {
    margin-top: 42px;
  }
}

@media (min-width: 992px) {
  .hcpj-footer__content .hcpj-footer__bottom-section p {
    margin-top: 22px;
  }
}

.hcpj-footer__top-container .aaaem-text {
  color: var(--emu-common-colors-black);
  font-size: 10px;
  line-height: 14px;
}

@media (min-width: 768px) {
  .hcpj-footer__top-container .aaaem-text {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: var(--emu-semantic-line-heights-wide-medium);
  }
}

.hcpj-footer__top-container .aaaem-text p {
  margin-top: 5px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .hcpj-footer__top-container .aaaem-text p {
    margin-top: 22px;
  }
}

.hcpj-footer__top-container .aaaem-text p sup {
  font-size: 100%;
  top: -1px;
  left: 1px;
}

.hcpj-footer__top-container .aaaem-text p a {
  color: inherit;
  text-decoration: none;
}

.hcpj-footer__top-container .aaaem-text p a:focus {
  text-decoration: underline;
}

.hcpj-footer__top-container .aaaem-text p b {
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  font-weight: var(--emu-common-font-weight-bold);
}

.cmp-experiencefragment--footer footer {
  border-top-width: var(--emu-common-border-width-thin);
  border-top-style: solid;
  border-top-color: var(--emu-semantic-colors-primary-pink-100);
  background-color: var(--emu-semantic-colors-secondary-grey-200);
  z-index: var(--emu-common-other-z-index-isi);
  max-width: 1440px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  position: relative;
}

@media (min-width: 768px) {
  .cmp-experiencefragment--footer footer {
    margin-top: 40px;
    padding: 18px 50px;
  }
}

.header {
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  background-color: var(--emu-common-colors-white);
  position: fixed;
  top: 0;
  left: 0;
}

.header__eyebrow-container {
  background-color: var(--emu-semantic-colors-secondary-grey-200);
  display: none;
}

@media (min-width: 992px) {
  .header__eyebrow-container {
    z-index: var(--emu-common-other-z-index-layer);
    display: block;
    position: relative;
  }
}

.header__eyebrow-container-inner > div:first-child .aaaem-button {
  color: var(--emu-semantic-colors-primary-pink-100);
}

.header__eyebrow-container-inner.aaaem-container {
  max-width: 1470px;
  background-color: inherit;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: var(--emu-common-spacing-xs);
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.header__eyebrow-container-inner.aaaem-container .aaaem-button, .header__eyebrow-container-inner.aaaem-container .aaaem-text > p > a, .header__eyebrow-container-inner.aaaem-container .aaaem-text {
  background: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  padding: var(--emu-common-spacing-none);
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  letter-spacing: 1px;
  text-align: center;
  max-width: 165px;
  font-size: 11px;
  line-height: 12px;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .header__eyebrow-container-inner.aaaem-container .aaaem-button, .header__eyebrow-container-inner.aaaem-container .aaaem-text > p > a, .header__eyebrow-container-inner.aaaem-container .aaaem-text {
    max-width: 100%;
    line-height: var(--emu-semantic-line-heights-wide-medium);
  }
}

.header__eyebrow-container-inner.aaaem-container .aaaem-text p {
  margin: var(--emu-common-spacing-none);
}

.header__drop-menu {
  width: 100%;
  background-color: var(--emu-semantic-colors-secondary-grey-100);
  z-index: var(--emu-common-other-z-index-modal);
  height: 100%;
  transition: all .5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(100%);
}

@media (min-width: 992px) {
  .header__drop-menu {
    height: auto;
    display: block;
    transform: translateY(-100%);
  }
}

.header__drop-menu.js-toggle-on {
  transform: translate(0);
}

@media (min-width: 992px) {
  .header__drop-menu:before, .header__drop-menu:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--emu-semantic-colors-secondary-grey-400);
    display: block;
    position: absolute;
    top: 33.33%;
    left: 0;
  }

  .header__drop-menu:after {
    top: 66.66%;
  }
}

.header__drop-menu-close.aaaem-button {
  font-size: 22px;
  font-family: var(--emu-semantic-font-families-poppins);
  z-index: var(--emu-common-other-z-index-layer);
  text-shadow: 0 0 #888b8c;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: 0;
  right: 2px;
}

.header__drop-menu-close.aaaem-button, .header__drop-menu-close.aaaem-button:hover, .header__drop-menu-close.aaaem-button:focus, .header__drop-menu-close.aaaem-button:active {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  border: none;
}

.header__drop-menu .header__drop-menu-inner {
  max-width: 1100px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 992px) {
  .header__drop-menu .header__drop-menu-inner {
    height: auto;
  }
}

.header__drop-menu .header__drop-menu-inner > .button {
  position: static;
}

.header__drop-menu ul {
  margin: var(--emu-common-spacing-none);
  flex-wrap: wrap;
  padding-top: 5px;
  padding-left: 15px;
  list-style: none;
  display: flex;
}

@media (min-width: 992px) {
  .header__drop-menu ul {
    padding-left: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
  }
}

.header__drop-menu li {
  padding-top: 7px;
  padding-bottom: var(--emu-common-spacing-small);
  width: 100%;
}

@media (min-width: 992px) {
  .header__drop-menu li {
    text-align: center;
    width: 20.5%;
    border-right: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-secondary-grey-400);
    padding-top: var(--emu-common-spacing-small);
    padding-right: 25px;
    padding-bottom: var(--emu-common-spacing-small);
    height: 58px;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    display: inline-flex;
  }
}

.header__drop-menu a {
  font-family: var(--emu-semantic-font-families-poppins);
  text-align: center;
  color: var(--emu-semantic-colors-secondary-dark-grey-700);
  color: var(--emu-common-colors-black);
  letter-spacing: 1px;
  font-size: 10px;
  line-height: 19px;
  text-decoration: none;
}

.header__drop-menu a b {
  font-family: var(--emu-semantic-font-families-poppins-bold);
}

.header__drop-menu a .emphasis {
  display: inline-block;
}

.header__drop-menu a sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -2px;
  left: 1px;
}

@media (min-width: 992px) {
  .header__drop-menu a sup {
    left: 0;
  }
}

.header__drop-menu--directions:after {
  display: none;
}

.header__drop-menu--directions:before {
  top: 46px;
}

.header__drop-menu--directions .header__drop-menu-inner {
  min-height: 100px;
}

.header__drop-menu--directions li {
  padding-right: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  padding-top: 9px;
  padding-bottom: 10px;
}

@media (min-width: 992px) {
  .header__drop-menu--directions li {
    height: 42px;
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
  }
}

.header__drop-menu--directions a {
  margin-top: var(--emu-common-spacing-xs);
  line-height: 1;
}

.header__drop-menu--brands {
  display: none;
}

@media (min-width: 992px) {
  .header__drop-menu--brands {
    display: block;
  }
}

.header__drop-menu--brands:before, .header__drop-menu--brands:after {
  display: none;
}

.header__drop-menu--brands .header__drop-menu-inner {
  max-width: none;
  padding-bottom: 60px;
}

.header__drop-menu--brands .header__drop-menu-inner > .button {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.header__drop-menu--brands ul {
  flex-wrap: nowrap;
  justify-content: center;
}

.header__drop-menu--brands li {
  min-height: 78px;
  height: auto;
  width: auto;
  border-right-color: var(--emu-semantic-colors-secondary-grey-500);
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.header__drop-menu--brands li:last-child {
  border-right: none;
}

.header__drop-menu--brands br {
  padding-top: 10px;
  display: block;
}

.header__drop-menu--brands a {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  line-height: 18px;
}

.header__drop-menu--brands a .emphasis {
  margin-top: 6px;
  line-height: 1;
  display: block;
}

.header__drop-menu--brands a .emphasis i {
  white-space: nowrap;
  font-style: normal;
}

.header__nav-desktop-links {
  display: none;
}

.header-main-menu-container {
  border-bottom: var(--emu-common-border-width-thin) solid #f1f1f1;
  background-color: var(--emu-common-colors-white);
  position: relative;
  box-shadow: 0 2px 6px #0000004d;
}

.header__main-menu-wrapper {
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 20px 17px;
  display: flex;
  position: relative;
}

@media (min-width: 992px) {
  .header__main-menu-wrapper {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: 12px;
  }

  .header__main-menu-wrapper > div:nth-child(1) {
    max-width: 33.33%;
    padding-right: 15px;
  }

  .header__main-menu-wrapper > div:last-child {
    max-width: 66.66%;
  }
}

.header__main-menu-wrapper > div:nth-child(4) {
  display: none;
}

.header__main-menu-wrapper > div:nth-child(3) {
  width: 100%;
  position: absolute;
  top: 63px;
  left: 0;
  box-shadow: 0 1px 1px #00000040;
}

@media (min-width: 992px) {
  .header__main-menu-wrapper > div:nth-child(3) {
    box-shadow: none;
    position: static;
  }

  .header__main-menu-wrapper > div:nth-child(3) .header__nav-desktop-links {
    flex-direction: row;
    justify-content: flex-end;
    padding-left: 15px;
    padding-right: 10px;
    display: flex;
  }

  .header__main-menu-wrapper > div:nth-child(3) .header__nav-desktop-links > div.button {
    margin-left: 20px;
  }

  .header__main-menu-wrapper > div:nth-child(3) .header__nav-desktop-links > div.button .aaaem-button {
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    line-height: var(--emu-semantic-line-heights-narrow-xl);
    font-family: var(--emu-semantic-font-families-poppins-semi-bold);
    width: 100%;
    white-space: pre;
  }
}

.header__main-menu-wrapper.aaaem-container {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-xxs);
}

@media (min-width: 768px) {
  .header__main-menu-wrapper.aaaem-container {
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  .header__main-menu-wrapper.aaaem-container {
    margin-bottom: var(--emu-common-spacing-none);
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .header__mobile-nav-trigger.aaaem-button {
    display: none;
  }
}

.header__mobile-nav-trigger.js-toggle-on.aaaem-button .cmp-button__icon-text {
  display: block;
}

.header__mobile-nav-trigger.js-toggle-on.aaaem-button .cmp-button__text {
  display: none;
}

.header__mobile-nav-trigger.js-toggle-on.aaaem-button > span:last-child {
  background-color: var(--emu-common-colors-transparent);
}

.header__mobile-nav-trigger.js-toggle-on.aaaem-button > span:last-child:before {
  top: 3px;
  transform: rotate(45deg);
}

.header__mobile-nav-trigger.js-toggle-on.aaaem-button > span:last-child:after {
  bottom: -3px;
  transform: rotate(-45deg);
}

.header__mobile-nav-trigger .cmp-button__text, .header__mobile-nav-trigger .cmp-button__icon-text {
  color: var(--emu-semantic-colors-primary-pink-100);
  font-size: 10px;
  font-weight: var(--emu-common-font-weight-bold);
  font-family: var(--emu-semantic-font-families-poppins-light);
  position: absolute;
  top: -25px;
  left: 0;
}

.header__mobile-nav-trigger .cmp-button__icon-text {
  display: none;
}

.header__mobile-nav-trigger.aaaem-button {
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  min-height: 30px;
  justify-content: center;
  align-items: center;
  transform: translateY(6px);
}

.header__mobile-nav-trigger.aaaem-button:hover, .header__mobile-nav-trigger.aaaem-button:active, .header__mobile-nav-trigger.aaaem-button:focus {
  background-color: var(--emu-common-colors-transparent);
}

.header__mobile-nav-trigger.aaaem-button > span:last-child {
  width: 30px;
  height: 4px;
  background-color: var(--emu-semantic-colors-primary-pink-100);
  transition: all ease-out var(--emu-common-other-time-delay-long);
  position: relative;
}

.header__mobile-nav-trigger.aaaem-button > span:last-child:before, .header__mobile-nav-trigger.aaaem-button > span:last-child:after {
  content: "";
  width: inherit;
  height: inherit;
  background-color: var(--emu-semantic-colors-primary-pink-100);
  transform-origin: center;
  transition: all ease-out var(--emu-common-other-time-delay-long);
  position: absolute;
  left: 0;
}

.header__mobile-nav-trigger.aaaem-button > span:last-child:before {
  top: -7px;
}

.header__mobile-nav-trigger.aaaem-button > span:last-child:after {
  bottom: -7px;
}

.header__logo a {
  max-width: 210px;
  width: 100%;
  height: 40px;
  display: flex;
}

@media (min-width: 992px) {
  .header__logo a {
    max-width: 100%;
    height: auto;
  }
}

.header__nav-wrapper {
  height: var(--emu-common-sizing-none);
  transition: all .3s ease-in-out;
  overflow: hidden;
}

@media (min-width: 992px) {
  .header__nav-wrapper {
    overflow: visible;
  }

  .header__nav-wrapper .header__resources-nav-mobile, .header__nav-wrapper .header__brand-nav-mobile {
    display: none;
  }

  .header__nav-wrapper .header__main-menu {
    max-width: 170px;
    z-index: var(--emu-common-other-z-index-behind);
    margin-top: 12px;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateX(-7px)translateY(-15px);
    box-shadow: 0 2px 6px #0000004d;
  }
}

.header__nav-wrapper.js-toggle-on {
  height: 600px;
  overflow-y: auto;
}

@media (min-width: 480px) {
  .header__nav-wrapper.js-toggle-on {
    height: 310px;
  }
}

@media (min-width: 768px) {
  .header__nav-wrapper.js-toggle-on {
    height: 1000px;
  }
}

@media (min-width: 992px) {
  .header__nav-wrapper.js-toggle-on {
    height: auto;
  }
}

body:not(.js-modal-opened) header.experiencefragment + div {
  overflow-x: hidden;
}

header.experiencefragment + div {
  margin-top: 70px;
}

@media (min-width: 992px) {
  header.experiencefragment + div {
    margin-top: 125px;
  }
}

@media (min-width: 1192px) {
  header.experiencefragment + div {
    margin-top: 117px;
  }
}

.cmp-experiencefragment--isi, .page-section {
  scroll-margin-top: 70px;
}

@media (min-width: 992px) {
  .cmp-experiencefragment--isi, .page-section {
    scroll-margin-top: 125px;
  }
}

@media (min-width: 1192px) {
  .cmp-experiencefragment--isi, .page-section {
    scroll-margin-top: 120px;
  }
}

.js-modal-opened header.experiencefragment + div {
  z-index: var(--emu-common-other-z-index-modal);
}

@media (min-width: 768px) {
  .header__nav-wrapper-mobile {
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  .header__nav-wrapper-mobile {
    overflow: visible;
  }
}

.header__nav-wrapper-mobile .emu-navigation__content-wrapper {
  position: relative;
}

@media (min-width: 768px) {
  .header__nav-wrapper-mobile .emu-navigation__content-wrapper ul {
    flex-direction: column;
    display: flex;
  }
}

.header__nav-wrapper-mobile .emu-navigation__item {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-purple-500);
  text-align: center;
}

@media (min-width: 992px) {
  .header__nav-wrapper-mobile .emu-navigation__item {
    border-bottom: none;
  }
}

.header__nav-wrapper-mobile .emu-navigation__item a {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: 15px;
  line-height: 10px;
}

.header__nav-wrapper-mobile .emu-navigation__item a sup {
  font-size: 8px;
  position: relative;
  top: -1px;
}

.header__nav-wrapper-mobile .emu-navigation__item span {
  font-family: var(--emu-semantic-font-families-mono);
  color: var(--emu-semantic-colors-primary-pink-100);
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
}

.header__nav-wrapper-mobile .emu-navigation__item span:hover {
  color: var(--emu-semantic-colors-primary-pink-100);
}

@media (min-width: 992px) {
  .header__main-menu ul {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header__main-menu ul > li:first-child {
    display: none;
  }
}

.header__main-menu .active {
  position: relative;
}

.header__main-menu .active, .header__main-menu .active:hover, .header__main-menu .active:active, .header__main-menu .active:focus {
  color: var(--emu-semantic-colors-primary-pink-100);
  text-decoration: none;
}

.header__main-menu .active:before {
  content: "";
  height: 100%;
  width: 15px;
  background-color: var(--emu-semantic-colors-primary-pink-100);
  position: absolute;
  top: 0;
  left: 1px;
}

@media (min-width: 992px) {
  .header__main-menu .active:before {
    left: -12px;
  }
}

.header__main-menu .emu-navigation__item a {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
}

@media (min-width: 992px) {
  .header__main-menu .emu-navigation__item a {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-large);
    word-break: break-word;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.header__main-menu .emu-navigation__item a sup {
  font-size: 70%;
  top: 3px;
}

.header__resources-nav-mobile-links .emu-navigation__item--active {
  text-decoration: underline;
}

.header__patient-safety-mobile-trigger.aaaem-button, .header__directions-mobile-trigger.aaaem-button {
  font-family: var(--emu-semantic-font-families-mono);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-purple-500);
  width: 100%;
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-purple-500);
  justify-content: center;
  padding-top: 19px;
  padding-bottom: 19px;
  font-size: 15px;
  display: flex;
}

.header__patient-safety-mobile-trigger.aaaem-button:hover, .header__patient-safety-mobile-trigger.aaaem-button:focus, .header__patient-safety-mobile-trigger.aaaem-button:active, .header__directions-mobile-trigger.aaaem-button:hover, .header__directions-mobile-trigger.aaaem-button:focus, .header__directions-mobile-trigger.aaaem-button:active {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-purple-500);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-purple-500);
}

.header__nav-brand-menu-title {
  text-align: center;
  position: relative;
}

.header__nav-brand-menu-title:before {
  content: "";
  height: 100%;
  width: 15px;
  background-color: var(--emu-semantic-colors-primary-pink-100);
  position: absolute;
  top: 0;
  left: 0;
}

.header__nav-brand-menu-title h6 {
  color: var(--emu-semantic-colors-primary-pink-100);
  margin: var(--emu-common-spacing-none);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-purple-500);
  font-size: 20px;
  line-height: 22px;
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-common-font-weight-light);
  padding-top: 30px;
  padding-bottom: 30px;
}

.header__brand-menu-mobile ul > li:last-child {
  border: var(--emu-semantic-border-width-none);
}

.emu-image-map, .emu-image-map img {
  width: 100%;
}

.emu-image-map .emu-mapping-marker {
  border: var(--emu-common-border-width-thin) solid var(--emu-component-ingredients-image-map-buttons-color-icon-light);
}

.emu-image-map .emu-mapping-marker span:before {
  width: var(--emu-common-border-width-medium);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emu-image-map .emu-mapping-marker span:after {
  height: var(--emu-common-border-width-medium);
  left: 50%;
  transform: translateX(-50%);
}

.emu-image-map .emu-mapping-marker.active {
  background-color: var(--emu-component-ingredients-image-map-buttons-color-icon-light);
}

.emu-image-map .emu-mapping-marker.active:before {
  content: "";
  height: var(--emu-common-border-width-medium);
  background-color: var(--emu-component-ingredients-image-map-buttons-color-icon-light);
  width: 50vw;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (min-width: 480px) {
  .emu-image-map .emu-mapping-marker.active:before {
    width: 65vw;
  }
}

@media (min-width: 992px) {
  .emu-image-map .emu-mapping-marker.active:before {
    width: min(40vw, 420px);
  }
}

.emu-image-map .emu-mapping-marker.active span:before, .emu-image-map .emu-mapping-marker.active span:after {
  background-color: var(--emu-common-colors-black);
}

[data-component="modal"] [data-component="text"] sup {
  top: var(--emu-common-spacing-none);
  font-size: 92%;
}

[data-component="modal"] .aaaem-container, [data-component="modal"] .modal-content {
  max-width: 100%;
  background-color: var(--emu-common-colors-transparent);
}

.page-load-modal .modal__section {
  background-image: -webkit-linear-gradient(130deg, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
  margin-top: 15px;
  box-shadow: 0 3px 9px #00000080;
}

@media (min-width: 768px) {
  .page-load-modal .modal__section {
    width: 78%;
    height: 350px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    transform: skew(-14deg);
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__section {
    width: 85%;
    height: 475px;
  }
}

@media (min-width: 1440px) {
  .page-load-modal .modal__section {
    max-width: 100%;
  }
}

.page-load-modal .modal__inner-section {
  padding-top: 35px;
}

@media (min-width: 768px) {
  .page-load-modal .modal__inner-section {
    padding-top: var(--emu-common-spacing-none);
    flex-direction: row;
    display: flex;
    transform: skew(15deg);
  }
}

@media (min-width: 1440px) {
  .page-load-modal .modal__inner-section {
    max-width: 100%;
  }
}

.page-load-modal .modal__inner-section > .container:first-child {
  flex: 0 0 50%;
}

@media (min-width: 811px) {
  .page-load-modal .modal__inner-section > .container:first-child {
    flex: 0 0 55%;
  }
}

@media (min-width: 1025px) {
  .page-load-modal .modal__inner-section > .container:first-child {
    flex: 0 0 60%;
  }
}

@media (min-width: 768px) {
  .page-load-modal .modal__inner-section .modal__image-desktop img {
    top: var(--emu-common-spacing-none);
    width: 64%;
    position: absolute;
    right: -25px;
  }
}

@media (min-width: 811px) {
  .page-load-modal .modal__inner-section .modal__image-desktop img {
    width: 61%;
    right: -31px;
  }
}

@media (min-width: 840px) {
  .page-load-modal .modal__inner-section .modal__image-desktop img {
    width: 58%;
    right: -16px;
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__inner-section .modal__image-desktop img {
    width: 520px;
    right: -60px;
  }
}

@media (min-width: 1024px) {
  .page-load-modal .modal__inner-section .modal__image-desktop img {
    right: -46px;
  }
}

@media (min-width: 1200px) {
  .page-load-modal .modal__inner-section .modal__image-desktop img {
    width: 535px;
    right: 3px;
  }
}

.page-load-modal .modal-content {
  margin-top: var(--emu-common-spacing-none);
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-none);
  margin-left: auto;
}

@media (min-width: 768px) {
  .page-load-modal .modal-content {
    max-width: 100%;
    width: 100%;
  }

  .page-load-modal .modal__content-section {
    padding-top: 40px;
    padding-bottom: var(--emu-common-spacing-xxs);
    position: relative;
    left: -5px;
  }
}

@media (min-width: 820px) {
  .page-load-modal .modal__content-section {
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__content-section {
    left: var(--emu-common-spacing-none);
    padding-bottom: 51px;
  }
}

@media (min-width: 1024px) {
  .page-load-modal .modal__content-section {
    padding-bottom: 55px;
  }
}

@media (min-width: 1200px) {
  .page-load-modal .modal__content-section {
    padding-top: 38px;
    padding-bottom: 30px;
  }
}

@media (min-width: 1340px) {
  .page-load-modal .modal__content-section {
    padding-top: 40px;
    padding-bottom: 48px;
  }
}

.page-load-modal .modal__content-section h2 {
  margin-top: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-green-200);
  margin-bottom: 10px;
  font-size: 42px;
  line-height: 46px;
}

@media (min-width: 992px) {
  .page-load-modal .modal__content-section h2 {
    font-size: 90px;
    line-height: 99px;
  }
}

@media (min-width: 1200px) {
  .page-load-modal .modal__content-section h2 {
    font-size: 98px;
    line-height: 108px;
  }
}

@media (min-width: 1340px) {
  .page-load-modal .modal__content-section h2 {
    font-size: 100px;
    line-height: 110px;
  }
}

.page-load-modal .modal__content-section h2 br {
  display: none;
}

@media (min-width: 768px) {
  .page-load-modal .modal__content-section h2 br {
    display: block;
  }
}

.page-load-modal .modal__content-section .aaaem-text {
  width: 73%;
  margin: 12px auto;
}

@media (min-width: 768px) {
  .page-load-modal .modal__content-section .aaaem-text {
    max-width: 73%;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .page-load-modal .modal__content-section .aaaem-text br {
    display: none;
  }
}

@media (min-width: 810px) {
  .page-load-modal .modal__content-section .aaaem-text {
    max-width: 75%;
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__content-section .aaaem-text {
    max-width: 85%;
    margin-left: 5px;
  }
}

@media (min-width: 1340px) {
  .page-load-modal .modal__content-section .aaaem-text {
    margin: 15px auto 21px;
  }
}

@media (min-width: 1440px) {
  .page-load-modal .modal__content-section .aaaem-text {
    max-width: 86%;
    text-align: center;
    margin-top: 12px;
  }
}

.page-load-modal .modal__content-section .aaaem-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-weight: var(--emu-semantic-font-weight-500);
  color: var(--emu-common-colors-black);
  letter-spacing: -.7px;
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 20px;
}

@media (min-width: 768px) {
  .page-load-modal .modal__content-section .aaaem-text p {
    letter-spacing: normal;
    font-size: 12px;
    line-height: 17px;
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__content-section .aaaem-text p {
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: 22px;
  }
}

@media (min-width: 1440px) {
  .page-load-modal .modal__content-section .aaaem-text p {
    text-align: center;
  }
}

.page-load-modal .modal__content-section .aaaem-text p .emphasis sup {
  font-size: 65%;
  top: -2px;
}

@media (min-width: 768px) {
  .page-load-modal .modal__content-section .aaaem-text p .emphasis {
    font-size: var(--emu-common-font-sizes-wide-medium);
    font-style: italic;
    line-height: 22px;
  }
}

@media (min-width: 1340px) {
  .page-load-modal .modal__content-section .aaaem-text p .emphasis {
    font-style: normal;
  }
}

@media (min-width: 1440px) {
  .page-load-modal .modal__content-section .aaaem-text p .emphasis {
    display: block;
  }
}

.page-load-modal .modal__content-section .aaaem-image {
  max-width: 75%;
  margin: auto;
}

@media (min-width: 490px) {
  .page-load-modal .modal__content-section .aaaem-image {
    max-width: 338px;
  }
}

@media (min-width: 768px) {
  .page-load-modal .modal__content-section .aaaem-image {
    max-width: 75%;
    padding-top: 5px;
    padding-bottom: 3px;
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__content-section .aaaem-image {
    max-width: 338px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

@media (min-width: 1024px) {
  .page-load-modal .modal__content-section .aaaem-image {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

.page-load-modal .modal__content-section .aaaem-image img {
  width: 100%;
}

.page-load-modal .modal__content-section .aaaem-button {
  padding-top: var(--emu-common-spacing-small);
  padding-right: 20px;
  padding-bottom: var(--emu-common-spacing-small);
  max-width: 220px;
  width: 100%;
  border-width: 2px;
  justify-content: center;
  margin: 6px auto;
  padding-left: 20px;
  display: flex;
}

@media (min-width: 768px) {
  .page-load-modal .modal__content-section .aaaem-button {
    margin-left: 15px;
  }
}

@media (min-width: 811px) {
  .page-load-modal .modal__content-section .aaaem-button {
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__content-section .aaaem-button {
    max-width: 295px;
    margin-left: 100px;
  }
}

@media (min-width: 1024px) {
  .page-load-modal .modal__content-section .aaaem-button {
    margin-left: 105px;
  }
}

@media (min-width: 1200px) {
  .page-load-modal .modal__content-section .aaaem-button {
    margin-left: 95px;
  }
}

@media (min-width: 1340px) {
  .page-load-modal .modal__content-section .aaaem-button {
    margin-left: auto;
  }
}

.page-load-modal .modal__content-section .aaaem-button .cmp-button__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
}

.page-load-modal .modal__image-section {
  padding-bottom: 5px;
}

@media (min-width: 768px) {
  .page-load-modal .modal__image-section {
    padding: var(--emu-common-spacing-none);
  }
}

.page-load-modal .modal__image-mobile {
  max-width: 342px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.page-load-modal .modal__image-caption {
  bottom: var(--emu-common-spacing-small);
  color: var(--emu-common-colors-black);
  font-size: 10px;
  line-height: 14px;
  position: absolute;
  left: 48%;
}

@media (min-width: 768px) {
  .page-load-modal .modal__image-caption {
    color: var(--emu-common-colors-white);
    bottom: 7px;
    left: 67%;
  }
}

@media (min-width: 840px) {
  .page-load-modal .modal__image-caption {
    bottom: 15px;
    left: 69%;
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__image-caption {
    bottom: 12px;
    left: auto;
    left: initial;
    right: 75px;
  }
}

@media (min-width: 1200px) {
  .page-load-modal .modal__image-caption {
    bottom: 7px;
    right: 90px;
  }
}

.page-load-modal .modal__image-caption p {
  margin: var(--emu-common-spacing-none);
}

.page-load-modal .modal__image-caption p:first-child {
  letter-spacing: -1px;
  text-shadow: 0 1px 2px #f0dddd;
  transform: skew(10deg);
}

@media (min-width: 768px) {
  .page-load-modal .modal__image-caption p:first-child {
    letter-spacing: normal;
    text-shadow: 2px 2px 8px #f5f3f3;
  }
}

.page-load-modal .modal__image-caption p sup {
  top: var(--emu-common-spacing-none);
}

.page-load-modal .modal__small-text {
  font-size: 8px;
  line-height: 11px;
  position: absolute;
  left: -3px;
}

@media (min-width: 600px) {
  .page-load-modal .modal__small-text {
    left: -7px;
  }
}

@media (min-width: 768px) {
  .page-load-modal .modal__small-text {
    left: auto;
    left: initial;
    max-width: 94%;
    margin: auto;
    padding-top: 10px;
    position: relative;
  }
}

@media (min-width: 810px) {
  .page-load-modal .modal__small-text {
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .page-load-modal .modal__small-text {
    font-size: 12px;
    line-height: 16px;
  }
}

.page-load-modal .modal__small-text p {
  font-family: var(--emu-semantic-font-families-poppins-light);
  color: var(--emu-common-colors-white);
  margin-top: 10px;
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  .page-load-modal .modal__small-text p {
    margin-top: var(--emu-common-spacing-none);
  }
}

.page-load-modal .modal__small-text p sup {
  top: -1px;
}

.page-load-modal .modal__small-text p .emphasis sup {
  font-size: 60%;
  top: -2px;
}

.page-load-modal .modal-wrapper {
  visibility: visible;
  opacity: 1;
  transition: visibility .3s ease-out .1s, opacity .3s ease-out .1s;
  display: block;
}

.page-load-modal .modal-wrapper:not(.is-open) {
  visibility: hidden;
  opacity: 0;
}

.page-load-modal .modal-wrapper:not(.is-open) .modal-content {
  transition-duration: .3s;
  transform: translate(0%, -25%);
}

.page-load-modal .close {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  font-family: var(--emu-semantic-font-families-poppins-light);
  color: #383838e0;
  width: 27px;
  height: 27px;
  border: 2px solid #383838e0;
  border-radius: 50%;
  font-weight: 100;
  position: absolute;
  top: 14px;
  right: 14px;
}

@media (min-width: 768px) {
  .page-load-modal .close {
    z-index: 100;
    top: 16px;
    right: auto;
    right: initial;
    width: 38px;
    height: 38px;
    font-size: 47px;
    left: 88%;
  }
}

@media (min-width: 992px) {
  .page-load-modal .close {
    left: 93%;
  }
}

@media (min-width: 1200px) {
  .page-load-modal .close {
    left: 92%;
  }
}

.page-load-modal .close:hover, .page-load-modal .close:focus {
  opacity: 1;
}

.page-load-modal .close span {
  line-height: 23px;
  display: block;
}

@media (min-width: 768px) {
  .page-load-modal .close span {
    line-height: 36px;
  }
}

.temple-popup .modal__container {
  background-image: -webkit-linear-gradient(130deg, var(--emu-semantic-colors-secondary-grey-300), var(--emu-semantic-colors-secondary-grey-200));
  margin-top: 30px;
  box-shadow: 0 3px 9px #00000080;
}

@media (min-width: 768px) {
  .temple-popup .modal__container {
    width: 78%;
    height: 350px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
}

@media (min-width: 992px) {
  .temple-popup .modal__container {
    width: 760px;
    height: 468px;
  }
}

.temple-popup .modal__inner-container {
  padding-top: 17px;
}

@media (min-width: 768px) {
  .temple-popup .modal__inner-container {
    padding-top: var(--emu-common-spacing-none);
    flex-direction: row;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .temple-popup .modal__inner-container {
    max-width: 100%;
  }
}

.temple-popup .modal__inner-container > .container:first-child {
  flex: 0 0 50%;
}

@media (min-width: 768px) {
  .temple-popup .modal__inner-container .modal__image-desktop img {
    width: 270px;
    position: absolute;
    top: 35px;
    right: 90px;
  }
}

@media (min-width: 992px) {
  .temple-popup .modal__inner-container .modal__image-desktop img {
    width: 350px;
    margin-top: 55px;
    position: static;
  }
}

@media (min-width: 1024px) {
  .temple-popup .modal__inner-container .modal__image-desktop img {
    width: 368px;
  }
}

.temple-popup .modal-content {
  margin-top: var(--emu-common-spacing-none);
  margin-right: 22px;
  margin-bottom: var(--emu-common-spacing-none);
  width: auto;
  margin-left: 22px;
}

@media (min-width: 768px) {
  .temple-popup .modal-content {
    max-width: 100%;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .temple-popup .modal-content {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .temple-popup .modal__content-container {
    padding-top: 22px;
    padding-left: 55px;
    padding-bottom: var(--emu-common-spacing-xxs);
    position: relative;
  }
}

@media (min-width: 1024px) {
  .temple-popup .modal__content-container {
    padding-bottom: var(--emu-common-spacing-none);
    width: 392px;
  }
}

.temple-popup .modal__content-container h2 {
  font-family: Oswald-ExtraLight;
  font-size: 40px;
  font-style: italic;
  font-weight: var(--emu-common-font-weight-light);
  text-align: center;
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  color: #ff5653;
  line-height: 51.88px;
}

.temple-popup .modal__content-container h2 b {
  font-family: Oswald-Regular;
}

@media (min-width: 1024px) {
  .temple-popup .modal__content-container h2 {
    font-size: 54px;
    line-height: 70px;
  }
}

.temple-popup .modal__content-container h2 br {
  display: none;
}

@media (min-width: 768px) {
  .temple-popup .modal__content-container h2 br {
    display: block;
  }
}

.temple-popup .modal__content-container .aaaem-text {
  margin-bottom: 12px;
  margin-left: 30px;
  margin-right: 30px;
}

@media (min-width: 768px) {
  .temple-popup .modal__content-container .aaaem-text {
    width: 100%;
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 20px;
    margin-right: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-none);
  }

  .temple-popup .modal__content-container .aaaem-text br {
    display: none;
  }
}

@media (min-width: 1024px) {
  .temple-popup .modal__content-container .aaaem-text {
    margin-right: auto;
    margin-top: var(--emu-common-spacing-none);
    width: 300px;
    margin-left: auto;
  }
}

.temple-popup .modal__content-container .aaaem-text p {
  font-family: Oswald-ExtraLight;
  font-size: 18px;
  font-weight: var(--emu-common-font-weight-light);
  text-align: center;
  color: #201b1b;
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 22px;
}

@media (min-width: 1024px) {
  .temple-popup .modal__content-container .aaaem-text p {
    font-size: 26px;
    line-height: 32px;
  }
}

.temple-popup .modal__content-container .aaaem-text p .emphasis {
  font-family: Oswald-Regular;
}

.temple-popup .modal__content-container .aaaem-text p .emphasis sup {
  font-size: 65%;
  top: -2px;
}

@media (min-width: 1024px) {
  .temple-popup .modal__content-container .aaaem-text p .emphasis {
    font-size: 26px;
    line-height: 32px;
  }
}

.temple-popup .modal__content-container .aaaem-image {
  max-width: 338px;
  margin-left: 85px;
  margin-right: 85px;
}

@media (min-width: 768px) {
  .temple-popup .modal__content-container .aaaem-image {
    max-width: 100%;
    margin-left: 65px;
    margin-right: 65px;
  }
}

@media (min-width: 1024px) {
  .temple-popup .modal__content-container .aaaem-image {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

.temple-popup .modal__content-container .aaaem-image img {
  width: 100%;
}

.temple-popup .modal__content-container .aaaem-button {
  padding-top: var(--emu-common-spacing-small);
  padding-right: 30px;
  padding-bottom: var(--emu-common-spacing-small);
  max-width: 162px;
  width: 100%;
  margin-top: var(--emu-common-spacing-none);
  background-color: #ff5653;
  border-width: 2px;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  display: flex;
}

@media (min-width: 768px) {
  .temple-popup .modal__content-container .aaaem-button {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    margin-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .temple-popup .modal__content-container .aaaem-button {
    max-width: 193px;
  }
}

.temple-popup .modal__content-container .aaaem-button .cmp-button__text {
  font-family: var(--emu-semantic-font-families-mono), sans-sarif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .temple-popup .modal__content-container .aaaem-button .cmp-button__text {
    font-size: 26px;
    line-height: 32px;
  }
}

.temple-popup .modal__image-section {
  padding-bottom: 5px;
}

@media (min-width: 768px) {
  .temple-popup .modal__image-section {
    padding: var(--emu-common-spacing-none);
  }
}

.temple-popup .modal__image-mobile {
  max-width: 342px;
  width: 100%;
  margin-left: 20px;
  margin-right: auto;
  padding-bottom: 15px;
}

.temple-popup .modal__image-caption {
  bottom: var(--emu-common-spacing-small);
  color: var(--emu-common-colors-black);
  font-size: 10px;
  line-height: 14px;
  position: absolute;
  left: 48%;
}

@media (min-width: 768px) {
  .temple-popup .modal__image-caption {
    color: var(--emu-common-colors-white);
    bottom: 7px;
    left: 67%;
  }
}

@media (min-width: 840px) {
  .temple-popup .modal__image-caption {
    bottom: 15px;
    left: 69%;
  }
}

@media (min-width: 992px) {
  .temple-popup .modal__image-caption {
    bottom: 12px;
    left: auto;
    left: initial;
    right: 75px;
  }
}

@media (min-width: 1200px) {
  .temple-popup .modal__image-caption {
    bottom: 7px;
    right: 90px;
  }
}

.temple-popup .modal__image-caption p {
  margin: var(--emu-common-spacing-none);
}

.temple-popup .modal__image-caption p:first-child {
  letter-spacing: -1px;
  text-shadow: 0 1px 2px #f0dddd;
}

@media (min-width: 768px) {
  .temple-popup .modal__image-caption p:first-child {
    letter-spacing: normal;
    text-shadow: 2px 2px 8px #f5f3f3;
  }
}

.temple-popup .modal__image-caption p sup {
  top: var(--emu-common-spacing-none);
}

.temple-popup .modal-wrapper {
  visibility: visible;
  opacity: 1;
  background-color: #0006;
  transition: visibility .3s ease-out .1s, opacity .3s ease-out .1s;
  display: block;
}

.temple-popup .modal-wrapper:not(.is-open) {
  visibility: hidden;
  opacity: 0;
}

.temple-popup .modal-wrapper:not(.is-open) .modal-content {
  transition-duration: .3s;
  transform: translate(0%, -25%);
}

.temple-popup .modal-wrapper .modal-content .close {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  font-family: var(--emu-semantic-font-families-poppins-light);
  color: #383838e0;
  width: 24px;
  height: 24px;
  border: 2px solid #383838e0;
  border-radius: 50%;
  align-items: center;
  font-weight: 100;
  display: grid;
  position: absolute;
  top: 8px;
  right: 8px;
}

@media (min-width: 768px) {
  .temple-popup .modal-wrapper .modal-content .close {
    z-index: 100;
    top: 8px;
    right: auto;
    right: initial;
    font-size: 28px;
    left: 85%;
  }
}

@media (min-width: 1024px) {
  .temple-popup .modal-wrapper .modal-content .close {
    top: 16px;
    left: auto;
    right: 16px;
  }
}

.temple-popup .modal-wrapper .modal-content .close:hover, .temple-popup .modal-wrapper .modal-content .close:focus {
  opacity: 1;
}

.temple-popup .modal-wrapper .modal-content .close span {
  line-height: 22px;
  display: block;
}

.temple-popup .separator {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .temple-popup .separator {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    padding-top: 27px;
    padding-bottom: 18px;
  }
}

.temple-popup .image {
  justify-content: center;
  display: grid;
}

@media (min-width: 768px) {
  .temple-popup .button {
    justify-content: center;
    display: grid;
  }
}

body.js-modal-opened {
  height: 100vh;
  overflow: hidden;
}

.cq-Editable-dom--container .modal-wrapper {
  opacity: 1 !important;
  visibility: visible !important;
}

.cq-Editable-dom--container .modal-wrapper img, .cq-Editable-dom--container .modal-wrapper .modal__image-caption {
  position: static !important;
}

.cq-Editable-dom--container .modal-wrapper .modal__section, .cq-Editable-dom--container .modal-wrapper .modal__container {
  height: auto !important;
}

.cq-Editable-dom--container .modal-wrapper .modal-content {
  transform: none !important;
}

@media (min-width: 768px) {
  .aaaem-tabs {
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .aaaem-tabs {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.aaaem-tabs__tablist {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .aaaem-tabs__tablist {
    grid-gap: var(--emu-semantic-spacing-none);
    gap: var(--emu-semantic-spacing-none);
    background-color: var(--emu-component-containers-tabs-navigation-item-color-background-default-light);
    flex-wrap: nowrap;
  }
}

.aaaem-tabs__tablist li {
  flex-basis: 48.5%;
}

@media (min-width: 768px) {
  .aaaem-tabs__tablist li {
    flex: 1;
  }

  .aaaem-tabs__tablist li:first-child a, .aaaem-tabs__tablist li:last-child a, .aaaem-tabs__tablist li:first-child a span, .aaaem-tabs__tablist li:last-child a span {
    transform: skew(0deg);
  }

  .aaaem-tabs__tablist li:first-child a {
    clip-path: polygon(0 0, 100% 0%, calc(100% - 32px) 100%, 0% 100%);
  }

  .aaaem-tabs__tablist li:last-child a {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  }

  .aaaem-tabs__tablist li:last-child a span {
    padding-left: 10px;
  }
}

.aaaem-tabs__tablist li:nth-child(2n) {
  margin-bottom: 10px;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .aaaem-tabs__tablist li:nth-child(2n) {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.aaaem-tabs__tablist a {
  text-align: center;
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 1.4;
  font-family: var(--emu-semantic-font-families-poppins-semi-bold);
  display: block;
}

@media (min-width: 768px) {
  .aaaem-tabs__tablist a {
    font-size: var(--emu-common-font-sizes-wide-medium);
    padding-top: var(--emu-component-containers-tabs-navigation-item-padding-top-wide);
    padding-right: var(--emu-component-containers-tabs-navigation-item-padding-right-wide);
    padding-bottom: var(--emu-component-containers-tabs-navigation-item-padding-bottom-wide);
    padding-left: var(--emu-component-containers-tabs-navigation-item-padding-left-wide);
    background-color: var(--emu-common-colors-transparent);
    transform: skew(-30deg);
  }

  .aaaem-tabs__tablist a span {
    display: block;
    transform: skew(30deg);
  }
}



[data-component="text"], [data-component="title"] {
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  font: var(--emu-semantic-typography-narrow-body-regular);
}

[data-component="text"] p, [data-component="text"] li, [data-component="title"] p, [data-component="title"] li {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

[data-component="text"] sup, [data-component="title"] sup {
  vertical-align: baseline;
  font-size: 48%;
  line-height: 0;
  position: relative;
  top: -1px;
}

[data-component="text"] b, [data-component="title"] b {
  font-weight: var(--emu-common-font-weight-regular);
}

/*# sourceMappingURL=main.css.map */
