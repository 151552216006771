.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  background-color: var(--emu-common-colors-white);

  &__eyebrow-container {
    display: none;
    background-color: var(--emu-semantic-colors-secondary-grey-200);

    @include mq('tablet') {
      position: relative;
      display: block;
      z-index: var(--emu-common-other-z-index-layer);
    }

    &-inner {
      > div:first-child {
        .aaaem-button {
          color: var(--emu-semantic-colors-primary-pink-100);
        }
      }
    }

    &-inner.aaaem-container {
      max-width: 1470px; // value from live site
      margin-left: auto;
      margin-right: auto;
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;
      justify-content: space-evenly;
      align-items: center;
      background-color: inherit;
      padding-right: var(--emu-common-spacing-xs);

      .aaaem-button,
      .aaaem-text > p > a,
      .aaaem-text {
        background: var(--emu-common-colors-transparent);
        color: var(--emu-semantic-colors-secondary-dark-grey-400);
        padding: var(--emu-common-spacing-none);
        font-family: var(--emu-semantic-font-families-poppins-semi-bold);
        font-size: 11px;
        letter-spacing: 1px;
        text-decoration: none;
        line-height: 12px;
        text-align: center;
        max-width: 165px;

        @include mq('large') {
          max-width: 100%;
          line-height: var(--emu-semantic-line-heights-wide-medium);
        }
      }

      .aaaem-text p {
        margin: var(--emu-common-spacing-none);
      }
    }
  }

  &__drop-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.5s ease-in-out;
    background-color: var(--emu-semantic-colors-secondary-grey-100);
    z-index: var(--emu-common-other-z-index-modal);
    transform: translateX(100%);
    height: 100%;

    @include mq('tablet') {
      display: block;
      transform: translateY(-100%);
      height: auto;
    }

    &.js-toggle-on {
      transform: translate(0, 0);
    }

    &::before,
    &::after {
      @include mq('tablet') {
        content: '';
        position: absolute;
        top: 33.33%;
        left: 0;
        width: 100%;
        display: block;
        height: 1px;
        background-color: var(--emu-semantic-colors-secondary-grey-400);
      }
    }

    &::after {
      @include mq('tablet') {
        top: 66.66%;
      }
    }

    &-close.aaaem-button {
      position: absolute;
      top: 0;
      right: 2px;
      font-size: 22px;
      padding-left: 20px;
      padding-right: 20px;
      font-family: var(--emu-semantic-font-families-poppins);
      z-index: var(--emu-common-other-z-index-layer);
      text-shadow: 0 0 0 #888b8c; // used only once

      &,
      &:hover,
      &:focus,
      &:active {
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-semantic-colors-secondary-dark-grey-400);
        border: none;
      }
    }

    .header__drop-menu-inner {
      max-width: 1100px;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      height: 100vh;

      @include mq('tablet') {
        height: auto;
      }

      > .button {
        position: static;
      }
    }

    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: var(--emu-common-spacing-none);
      padding-left: 15px;
      padding-top: 5px;

      @include mq('tablet') {
        padding-left: var(--emu-common-spacing-none);
        padding-top: var(--emu-common-spacing-none);
      }
    }

    li {
      padding-top: 7px;
      padding-bottom: var(--emu-common-spacing-small);
      width: 100%;

      @include mq('tablet') {
        text-align: center;
        width: 20.5%;
        border-right: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-secondary-grey-400);
        padding-top: var(--emu-common-spacing-small);
        padding-right: 25px;
        padding-bottom: var(--emu-common-spacing-small);
        padding-left: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 58px; // needed to match design
      }
    }

    a {
      font-family: var(--emu-semantic-font-families-poppins);
      font-size: 10px;
      line-height: 19px;
      text-align: center;
      text-decoration: none;
      color: var(--emu-semantic-colors-secondary-dark-grey-700);
      color: var(--emu-common-colors-black);
      letter-spacing: 1px;

      b {
        font-family: var(--emu-semantic-font-families-poppins-bold);
      }

      .emphasis {
        display: inline-block;
      }

      sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
        top: -2px;
        left: 1px;

        @include mq('tablet') {
          left: 0;
        }
      }
    }

    &--directions {
      &::after {
        display: none;
      }

      &::before {
        top: 46px;
      }

      .header__drop-menu-inner {
        min-height: 100px;
      }

      li {
        padding-right: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);
        padding-top: 9px;
        padding-bottom: 10px;

        @include mq('tablet') {
          height: 42px;
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
        }
      }

      a {
        line-height: 1;
        margin-top: var(--emu-common-spacing-xs);
      }
    }

    &--brands {
      display: none;

      @include mq('tablet') {
        display: block;
      }

      &::before,
      &::after {
        display: none;
      }

      .header__drop-menu-inner {
        padding-bottom: 60px;
        max-width: none;

        > .button {
          position: relative;
          max-width: 1440px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }
      }

      ul {
        flex-wrap: nowrap;
        justify-content: center;
      }

      li {
        min-height: 78px;
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        width: auto;
        border-right-color: var(--emu-semantic-colors-secondary-grey-500);

        &:last-child {
          border-right: none;
        }
      }

      br {
        display: block;
        padding-top: 10px;
      }

      a {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 18px;
        color: var(--emu-semantic-colors-secondary-dark-grey-400);

        .emphasis {
          display: block;
          margin-top: 6px;
          line-height: 1;

          i {
            font-style: normal;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__nav {
    &-desktop-links {
      display: none;
    }
  }

  &-main-menu-container {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
    border-bottom: var(--emu-common-border-width-thin) solid #f1f1f1; //no var. used only once
    position: relative;
    background-color: var(--emu-common-colors-white);
  }

  &__main-menu {
    &-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 17px;
      padding-left: 20px;

      @include mq('tablet') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: 12px;

        > div:nth-child(1) {
          max-width: 33.33%;
          padding-right: 15px;
        }

        > div:last-child {
          max-width: 66.66%;
        }
      }

      > div:nth-child(4) {
        display: none;
      }

      > div:nth-child(3) {
        position: absolute;
        top: 63px;
        left: 0;
        width: 100%;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25); //used once

        @include mq('tablet') {
          position: static;
          box-shadow: none;

          .header__nav-desktop-links {
            display: flex;
            flex-direction: row;
            padding-left: 15px;
            padding-right: 10px;
            justify-content: flex-end;

            > div.button {
              margin-left: 20px;

              .aaaem-button {
                font-size: var(--emu-semantic-font-sizes-narrow-xl);
                line-height: var(--emu-semantic-line-heights-narrow-xl);
                font-family: var(
                  --emu-semantic-font-families-poppins-semi-bold
                );
                width: 100%;
                white-space: pre;
              }
            }
          }
        }
      }

      &.aaaem-container {
        max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: var(--emu-common-spacing-xxs);

        @include mq('medium') {
          padding-right: 15px;
        }

        @include mq('large') {
          padding-right: 30px;
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }

  &__mobile-nav-trigger {
    @include mq('tablet') {
      &.aaaem-button {
        display: none;
      }
    }

    &.js-toggle-on.aaaem-button {
      .cmp-button__icon-text {
        display: block;
      }

      .cmp-button__text {
        display: none;
      }

      > span:last-child {
        background-color: var(--emu-common-colors-transparent);

        &::before {
          transform: rotate(45deg);
          top: 3px;
        }

        &::after {
          transform: rotate(-45deg);
          bottom: -3px;
        }
      }
    }

    .cmp-button__text,
    .cmp-button__icon-text {
      color: var(--emu-semantic-colors-primary-pink-100);
      font-size: 10px;
      position: absolute;
      top: -25px;
      left: 0;
      font-weight: var(--emu-common-font-weight-bold);
      font-family: var(--emu-semantic-font-families-poppins-light);
    }

    .cmp-button__icon-text {
      display: none;
    }

    &.aaaem-button {
      padding: var(--emu-common-spacing-none);
      background-color: var(--emu-common-colors-transparent);
      min-height: 30px;
      align-items: center;
      justify-content: center;
      transform: translateY(6px);

      &:hover,
      &:active,
      &:focus {
        background-color: var(--emu-common-colors-transparent);
      }

      > span:last-child {
        width: 30px; // match what we have in live
        height: 4px;
        position: relative;
        background-color: var(--emu-semantic-colors-primary-pink-100);
        transition: all ease-out var(--emu-common-other-time-delay-long);

        &::before,
        &::after {
          content: '';
          width: inherit;
          height: inherit;
          position: absolute;
          left: 0;
          background-color: var(--emu-semantic-colors-primary-pink-100);
          transform-origin: center;
          transition: all ease-out var(--emu-common-other-time-delay-long);
        }

        &::before {
          top: -7px;
        }

        &::after {
          bottom: -7px;
        }
      }
    }
  }

  &__logo {
    a {
      display: flex;
      max-width: 210px; // value from live site
      width: 100%;
      height: 40px; // height needed to match design

      @include mq('tablet') {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__nav {
    &-wrapper {
      height: var(--emu-common-sizing-none); // to match live
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      @include mq('tablet') {
        overflow: visible;
        .header__resources-nav-mobile,
        .header__brand-nav-mobile {
          display: none;
        }

        .header__main-menu {
          top: 100%;
          left: 0;
          max-width: 170px;
          margin-top: 12px;
          position: absolute;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
          z-index: var(--emu-common-other-z-index-behind);
          transform: translateX(-7px) translateY(-15px);
        }
      }

      &.js-toggle-on {
        height: 600px; // value from live site
        overflow-y: auto;

        @include mq('small') {
          height: 310px; // value from live site
        }

        @include mq('medium') {
          height: 1000px; // value from live site
        }

        @include mq('tablet') {
          height: auto;
        }
      }
    }
  }
}

body:not(.js-modal-opened) {
  header.experiencefragment {
    & + div { 
      overflow-x: hidden; // This rule is breaking the modal on iOS devices causing the modal to appear behind the header and disclaimer. overflow-x will be added when the modal is closed.
    }
  }
}

header.experiencefragment {
  & + div {
    margin-top: 70px; // height of the header. So that the content next to the header does not get hidden behind the fixed header

    @include mq('tablet') {
      margin-top: 125px;
    }

    // media query needed to match the design
    @include mq('1192px') {
      margin-top: 117px;
    }
  }
}

.cmp-experiencefragment--isi,
.page-section {
  scroll-margin-top: 70px;

  @include mq('tablet') {
    scroll-margin-top: 125px;
  }

  // needed to match design
  @include mq('1192px') {
    scroll-margin-top: 120px;
  }
}

.js-modal-opened {
  header.experiencefragment + div {
    z-index: var(--emu-common-other-z-index-modal);
  }
}
