.header {
  &__nav-wrapper-mobile {
    @include mq('medium') {
      overflow-y: auto;
    }

    @include mq('tablet') {
      overflow: visible;
    }

    .emu-navigation {
      &__content-wrapper {
        position: relative;

        @include mq('medium') {
          ul {
            display: flex;
            flex-direction: column;
          }
        }
      }

      &__item {
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-purple-500);
        text-align: center;

        @include mq('tablet') {
          border-bottom: none;
        }

        a {
          font-family: var(--emu-semantic-font-families-mono);
          font-size: 15px;
          line-height: 10px;

          sup {
            position: relative;
            font-size: 8px;
            top: -1px;
          }
        }

        span {
          font-family: var(--emu-semantic-font-families-mono);
          color: var(--emu-semantic-colors-primary-pink-100);
          padding-top: 30px;
          padding-bottom: 30px;
          font-size: 20px;

          &:hover {
            color: var(--emu-semantic-colors-primary-pink-100);
          }
        }
      }
    }
  }

  &__main-menu {
    @include mq('tablet') {
      ul {
        padding-left: 20px;
        padding-right: 20px;

        > li:first-child {
          display: none;
        }
      }
    }

    .active {
      position: relative;

      &,
      &:hover,
      &:active,
      &:focus {
        color: var(--emu-semantic-colors-primary-pink-100);
        text-decoration: none;
      }

      &::before {
        position: absolute;
        content: '';
        left: 1px;
        top: 0;
        height: 100%;
        width: 15px;
        background-color: var(--emu-semantic-colors-primary-pink-100);

        @include mq('tablet') {
          left: -12px;
        }
      }
    }

    .emu-navigation__item {
      a {
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 20px;

        @include mq('tablet') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-large);
          padding-top: 20px;
          padding-bottom: 20px;
          word-break: break-word;
        }

        sup {
          font-size: 70%;
          top: 3px;
        }
      }
    }
  }

  &__resources-nav-mobile-links {
    .emu-navigation__item--active {
      text-decoration: underline;
    }
  }

  &__patient-safety-mobile-trigger,
  &__directions-mobile-trigger {
    &.aaaem-button {
      display: flex;
      justify-content: center;
      font-family: var(--emu-semantic-font-families-mono);
      font-size: 15px;
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-purple-500);
      width: 100%;
      background-color: var(--emu-common-colors-transparent);
      color: var(--emu-semantic-colors-primary-purple-500);
      padding-top: 19px;
      padding-bottom: 19px;

      &:hover,
      &:focus,
      &:active {
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-semantic-colors-primary-purple-500);
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-purple-500);
      }
    }
  }

  &__nav-brand-menu-title {
    position: relative;
    text-align: center;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 15px; // to match live.
      background-color: var(--emu-semantic-colors-primary-pink-100);
    }

    h6 {
      color: var(--emu-semantic-colors-primary-pink-100);
      margin: var(--emu-common-spacing-none);
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 20px;
      line-height: 22px;
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-purple-500);
      font-family: var(--emu-semantic-font-families-mono);
      font-weight: var(--emu-common-font-weight-light);
    }
  }

  &__brand-menu-mobile {
    ul > li:last-child {
      border: var(--emu-semantic-border-width-none);
    }
  }
}
